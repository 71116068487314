import StyledField from './Field.pc';

import { InputMode, TextInput } from './TextInput';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Checkbox } from './Checkbox';
import { nanoid } from 'nanoid';
import { SelectInput, SelectOption } from './Select';

type ValueChangeListener<TValue> = (value: TValue) => void;

type BaseFieldProps<TValue> = {
  value?: TValue;
  error?: string;
  'data-testid'?: string;
  className?: string;
  placeholder?: string;
  spaceBottom?: boolean;
  name?: string;
  label?: any;
  infoText?: any;
  hidden?: boolean;
  disabled?: boolean;
  onBlur?: () => any;
  onValueChange?: ValueChangeListener<TValue>;
};

type FieldProps<TValue> = {
  testId?: string;
  secondary?: boolean;
  v3?: boolean;
  subtext?: boolean;
  noSpace?: boolean;
  toggle?: boolean;
  left?: boolean;
  onBlur?: () => any;
  onValueChange?: ValueChangeListener<TValue>;
  children: (params: {
    onValueChange?: ValueChangeListener<TValue>;
    value: any;
    id?: string;
    name?: string;
    disabled: boolean;
    onBlur: () => void;
  }) => ReactNode | React.ReactElement;
} & BaseFieldProps<TValue>;

export const Field = ({
  className,
  children,
  error,
  v3,
  'data-testid': testId,
  toggle = false,
  secondary,
  spaceBottom,
  noSpace,
  disabled = false,
  value,
  label,
  onBlur = () => undefined,
  infoText,
  left,
  onValueChange,
  name,
  hidden = false,
}: FieldProps<any>) => {
  let subtext = infoText;

  const [internalValue, setValue] = useState(value);

  // just need a random ID so that we can assign htmlFor to the label (specific to checkbox toggling)
  const [inputId] = useState(nanoid);

  useEffect(() => {
    setValue(value);
  }, [value]);

  if (error) {
    subtext = <span>{error}</span>;
  }

  const onValueChange2 = (value: any) => {
    setValue(value);
    onValueChange && onValueChange(value);
  };

  const [isActive, setActive] = useState(false);

  const onFocusIn = useCallback(() => {
    setActive(true);
  }, [setActive]);

  const onFocusOut = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <StyledField
      secondary={secondary}
      className={className}
      noSpace={noSpace}
      spaceBottom={spaceBottom}
      negative={Boolean(error)}
      for={inputId}
      toggle={toggle}
      label={label}
      v3={v3}
      testId={testId}
      left={left}
      subtext={subtext}
      active={isActive || internalValue || internalValue === 0}
      onFocus={onFocusIn}
      onBlur={onFocusOut}
      hidden={hidden}
    >
      {children({
        value: internalValue,
        id: inputId,
        name,
        disabled,
        onBlur,
        onValueChange: onValueChange2,
      })}
    </StyledField>
  );
};

type CheckboxFieldProps = {
  // TODO: this is for legacy, test id should be defined on field component. Selector for CY test should be
  // `cy.get("[data-testid='field-test-id'] input")`
  inputTestId?: string;
} & BaseFieldProps<boolean>;

export const CheckboxField = ({ inputTestId, ...rest }: CheckboxFieldProps) => {
  return (
    <Field v3 {...rest} toggle>
      {(inputProps) => <Checkbox {...inputProps} testId={inputTestId} />}
    </Field>
  );
};

type TextInputFieldProps = {
  inputMode?: InputMode;
  inputTestId?: string;
  autoFocus?: boolean;
} & BaseFieldProps<string | number>;

export const TextInputField = ({
  inputMode,
  inputTestId,
  autoFocus,
  placeholder,
  disabled,
  ...rest
}: TextInputFieldProps) => {
  return (
    <Field v3 {...rest}>
      {(inputProps) => (
        <TextInput
          {...inputProps}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
          data-testid={inputTestId}
          inputMode={inputMode}
        />
      )}
    </Field>
  );
};

type SelectInputFieldProps = {
  inputTestId?: string;
  inputAriaLabel?: string;
  options: SelectOption[];
  isMulti?: boolean;
  isMultiClearable?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  placeholder?: string;
  menuPlacement?: 'top' | 'bottom';
} & BaseFieldProps<string | number>;

export const SelectInputField = ({
  inputTestId,
  inputAriaLabel,
  options,
  isMulti,
  isMultiClearable,
  isClearable,
  isCreatable,
  placeholder = '',
  menuPlacement,
  ...rest
}: SelectInputFieldProps) => {
  return (
    <Field v3 {...rest}>
      {(inputProps) => (
        <SelectInput
          {...inputProps}
          options={options}
          placeholder={placeholder}
          isMulti={isMulti}
          isClearable={isClearable}
          isCreatable={isCreatable}
          isMultiClearable={isMultiClearable}
          aria-label={inputAriaLabel}
          data-testid={inputTestId}
          menuPlacement={menuPlacement}
        />
      )}
    </Field>
  );
};
