import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { FlagValue } from '@hum/types';

/* mockFlags
 * provide mock feature flags to a test
 */
export const mockFlags = (
  flags: Record<string, { enabled: boolean; value?: FlagValue }>
) => {
  (useFlags as jest.Mock).mockImplementation(() => {
    const get = jest.fn(() => flags);
    const enabled = jest.fn((flagNames: string | string[]) => {
      if (typeof flagNames === 'string') {
        return get()[flagNames]?.enabled;
      } else {
        const mockedFlags = get();
        return flagNames.map((flag) => mockedFlags[flag].enabled);
      }
    });
    return { get, enabled, ready: true };
  });
};
