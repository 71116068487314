import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Accordion.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_e582408d _9a961ec6 _pub-9a961ec6 _pub-a97d53f3" + " " + "_9a961ec6_text-body-medium _pub-9a961ec6_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["largeBorderRadius"] ? " " + "_9a961ec6_large-border-radius _pub-9a961ec6_large-border-radius _pub-a97d53f3_large-border-radius large-border-radius" : "") + (props["disableToggle"] ? " " + "_9a961ec6_disable-toggle _pub-9a961ec6_disable-toggle _pub-a97d53f3_disable-toggle disable-toggle" : ""),
    "ref": ref,
    "data-testid": props["testId"],
  }, 
    React.createElement("div", {
      "className": "_51d55b84 _9a961ec6 _pub-9a961ec6 _pub-a97d53f3",
      "onClick": props["onHeaderClick"],
      "data-testid": props["headerTestId"],
    }, 
      props["label"],
      !props["disableToggle"]&&React.createElement("button", {
        "className": "_4db779b5 _9a961ec6 _pub-9a961ec6 _pub-a97d53f3" + (props["hover"] ? " " + "_9a961ec6_hover _pub-9a961ec6_hover _pub-a97d53f3_hover hover" : "") + (props["expanded"] ? " " + "_9a961ec6_expanded _pub-9a961ec6_expanded _pub-a97d53f3_expanded expanded" : ""),
        "onClick": props["onExpandClick"],
        "data-testid": "toggle-accordion:button",
      }, null)
    ),
    React.createElement("div", {
      "className": "_b8b6feb1 _9a961ec6 _pub-9a961ec6 _pub-a97d53f3" + (props["expanded"] ? " " + "_9a961ec6_expanded _pub-9a961ec6_expanded _pub-a97d53f3_expanded expanded" : ""),
    }, 
      props["children"]
    )
  )
}));
export default $$Default;

var AccordionContent = React.memo(React.forwardRef(function AccordionContent(props, ref) {
  return React.createElement("div", {
    "className": "_b8c21a1 _9a961ec6 _pub-9a961ec6 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export { AccordionContent };

var AccordionFooter = React.memo(React.forwardRef(function AccordionFooter(props, ref) {
  return React.createElement("div", {
    "className": "_ec340ca6 _9a961ec6 _pub-9a961ec6 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export { AccordionFooter };


