import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub2Dbb4493 from "./Button.pc";
import _pub7Abf1313, {ProgressPill as _pub7Abf1313_ProgressPill} from "./ProgressPills.pc";
import _pub5448A231 from "./Detail.pc";
import "./CoachMark.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_99858497 _6ad54177 _pub-6ad54177 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["centered"] ? " " + "_6ad54177_centered _pub-6ad54177_centered _pub-a97d53f3_centered centered" : ""),
    "ref": ref,
  }, 
    props["steps"],
    React.createElement(_pub5448A231, {
      "class": "_7df1dbf1 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
      "title": props["title"],
      "description": props["description"],
    }, null),
    props["actions"]
  )
}));
export default $$Default;

var CoachMarkActions = React.memo(React.forwardRef(function CoachMarkActions(props, ref) {
  return React.createElement("div", {
    "className": "_7e3da990 _6ad54177 _pub-6ad54177 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["end"] ? " " + "_6ad54177_end _pub-6ad54177_end _pub-a97d53f3_end end" : ""),
    "ref": ref,
    "actions": props["actions"],
  }, 
    props["actions"]
  )
}));
export { CoachMarkActions };

var CoachMarkAnchor = React.memo(React.forwardRef(function CoachMarkAnchor(props, ref) {
  return React.createElement("div", {
    "className": "_bb74cb52 _6ad54177 _pub-6ad54177 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["right"] ? " " + "_6ad54177_right _pub-6ad54177_right _pub-a97d53f3_right right" : ""),
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    React.createElement("div", {
      "className": "_2d1f860a _6ad54177 _pub-6ad54177 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_ce19abaa _6ad54177 _pub-6ad54177 _pub-a97d53f3",
      }, null)
    ),
    React.createElement("div", {
      "className": "_5a18b69c _6ad54177 _pub-6ad54177 _pub-a97d53f3",
    }, 
      props["card"]
    )
  )
}));
export { CoachMarkAnchor };

var CoachMarkPreview = React.memo(React.forwardRef(function CoachMarkPreview(props, ref) {
  return React.createElement($$Default, {
    "class": "_557aaa7e",
    "ref": ref,
    "centered": props["centered"],
    "actions": React.createElement(CoachMarkActions, {
      "class": "_445e3da9",
      "actions": React.createElement(React.Fragment, {
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_ed4b0427 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
        }, 
          "Skip tour"
          
        ),
        React.createElement(_pub2Dbb4493, {
          "class": "_9a4c34b1 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
          "primary": true,
        }, 
          "Next"
          
        )
      ),
    }, null),
    "steps": React.createElement(_pub7Abf1313, {
      "class": "_33590d3f _6ad54177 _pub-6ad54177 _pub-a97d53f3",
    }, 
      React.createElement("ProgressPill", {
        "className": "_61451d64 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
      }, null),
      React.createElement("ProgressPill", {
        "className": "_16422df2 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
        "active": true,
      }, null),
      React.createElement("ProgressPill", {
        "className": "_8f4b7c48 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
      }, null)
    ),
    "title": props["title"]||"Keep titles concise",
    "description": props["description"]||"Be considerate of the viewers time and patience. Use short bursts of information. Don't just point out the feature. Why is it important to the user?",
  }, null)
}));
export { CoachMarkPreview };

var CoachMarkPreviewLastStep = React.memo(React.forwardRef(function CoachMarkPreviewLastStep(props, ref) {
  return React.createElement($$Default, {
    "class": "_bc190f4b",
    "ref": ref,
    "actions": React.createElement(CoachMarkActions, {
      "class": "_fb673f1a",
      "end": true,
      "actions": React.createElement(_pub2Dbb4493, {
        "class": "_7e4571f2 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
        "primary": true,
      }, 
        "Get started"
        
      ),
    }, null),
    "steps": React.createElement(_pub7Abf1313, {
      "class": "_8c600f8c _6ad54177 _pub-6ad54177 _pub-a97d53f3",
    }, 
      React.createElement(_pub7Abf1313_ProgressPill, {
        "class": "_40a33e0a _6ad54177 _pub-6ad54177 _pub-a97d53f3",
      }, null),
      React.createElement(_pub7Abf1313_ProgressPill, {
        "class": "_37a40e9c _6ad54177 _pub-6ad54177 _pub-a97d53f3",
      }, null),
      React.createElement(_pub7Abf1313_ProgressPill, {
        "class": "_aead5f26 _6ad54177 _pub-6ad54177 _pub-a97d53f3",
        "active": true,
      }, null)
    ),
    "title": props["title"]||"Keep titles concise",
    "description": props["description"]||"Be considerate of the viewers time and patience. Use short bursts of information. Don't just point out the feature. Why is it important to the user?",
  }, null)
}));

var CoachMarkAnchorPreview = React.memo(React.forwardRef(function CoachMarkAnchorPreview(props, ref) {
  return React.createElement(React.Fragment, {
    "ref": ref,
  }, 
    props["children"],
    React.createElement(CoachMarkAnchor, {
      "class": "_b09bab02",
      "style": castStyle(props["style"]),
      "card": React.createElement(CoachMarkPreviewLastStep, {
        "class": "_6d621530",
        "title": props["title"],
        "description": props["description"],
      }, null),
    }, null)
  )
}));
export { CoachMarkAnchorPreview };


