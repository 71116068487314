import { Action as BaseReduxAction } from 'redux';

export type BaseAction<TType, TPayload = null> = {
  payload: TPayload;
} & BaseReduxAction<TType>;

type ActionCreator<
  TAction extends BaseAction<any, any>
> = TAction['payload'] extends null
  ? () => TAction
  : (payload: TAction['payload']) => TAction;

export const actionCreator = <TAction extends BaseAction<any, any>>(
  type: TAction['type']
): ActionCreator<TAction> =>
  ((payload: TAction['payload']) => ({
    type,
    payload,
  })) as any;
