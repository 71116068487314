import React, { useState, useCallback, memo } from 'react';
import * as styles from './Accordion.pc';
import * as tabNavigationStyles from './TabNavigation.pc';

export type AccordionProps = {
  label: any;
  testId?: string;
  open?: boolean;
  active?: boolean;
  onClick?: any;
  children: React.ReactNode;
};

export const Accordion = memo(
  ({ label, testId, open = false, children }: AccordionProps) => {
    const { isOpen, toggleOpen } = useAccordion(open);

    return (
      <styles.default
        label={label}
        key={testId}
        testId={testId}
        headerTestId="accordion-header"
        onHeaderClick={toggleOpen}
        onExpandClick={toggleOpen}
        expanded={isOpen}
      >
        {isOpen ? children : null}
      </styles.default>
    );
  }
);

export const AccordionTabNavigationItem = memo(
  ({
    label,
    testId,
    children,
    onClick,
    active = false,
    open = false,
  }: AccordionProps) => {
    const { isOpen, toggleOpen } = useAccordion(open);

    if (!active && isOpen) {
      toggleOpen();
    }

    return (
      <tabNavigationStyles.AccordionTabNavigationItem
        active={active}
        label={label}
        key={testId}
        testId={testId}
        onExpandClick={toggleOpen}
        onClick={() => {
          onClick();
          toggleOpen();
        }}
        expanded={isOpen}
      >
        {isOpen ? children : null}
      </tabNavigationStyles.AccordionTabNavigationItem>
    );
  }
);

export const AccordionContent = styles.AccordionContent;
export const AccordionFooter = styles.AccordionFooter;

export const useAccordion = (open: boolean, disabled?: boolean) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = useCallback(() => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  }, [isOpen, setIsOpen]);

  return { isOpen, toggleOpen };
};
