import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pub2Dbb4493 from "./Button.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub824B8933 from "./layout.pc";
import _pub2F51C399 from "./breakpoints.pc";
import "./Modal.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement(props.tagName || "div", {
    "className": "_b0dbcba1 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3" + " " + "_c530b6d5_v3 _pub-c530b6d5_v3 _pub-a97d53f3_v3 v3 _c530b6d5_hum _pub-c530b6d5_hum _pub-a97d53f3_hum hum _c530b6d5_dark _pub-c530b6d5_dark _pub-a97d53f3_dark dark _c530b6d5_text-body-medium _pub-c530b6d5_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["side"] ? " " + "_c530b6d5_side _pub-c530b6d5_side _pub-a97d53f3_side side _c530b6d5_fill-mobile _pub-c530b6d5_fill-mobile _pub-a97d53f3_fill-mobile fill-mobile" : "") + (props["visible"] ? " " + "_c530b6d5_visible _pub-c530b6d5_visible _pub-a97d53f3_visible visible" : "") + (props["v5"] ? " " + "_c530b6d5_modal-v5 _pub-c530b6d5_modal-v5 _pub-a97d53f3_modal-v5 modal-v5" : "") + (props["v5connected"] ? " " + "_c530b6d5_modal-v5-connected _pub-c530b6d5_modal-v5-connected _pub-a97d53f3_modal-v5-connected modal-v5-connected" : "") + (props["v5files"] ? " " + "_c530b6d5_modal-v5-files _pub-c530b6d5_modal-v5-files _pub-a97d53f3_modal-v5-files modal-v5-files" : "") + (props["isError"] ? " " + "_c530b6d5_is-error _pub-c530b6d5_is-error _pub-a97d53f3_is-error is-error" : "") + (props["fillMobile"] ? " " + "_c530b6d5_fill-mobile _pub-c530b6d5_fill-mobile _pub-a97d53f3_fill-mobile fill-mobile" : ""),
    "ref": ref,
    "onSubmit": props["onSubmit"],
    "data-testid": props["testId"],
  }, 
    props["children"],
    props["visible"]&&React.createElement("div", {
      "className": "_e5c85a42 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3",
      "onClick": props["onBackgroundClick"],
    }, null)
  )
}));
export default $$Default;

var _ModalContentChildren = React.memo(React.forwardRef(function _ModalContentChildren(props, ref) {
  return React.createElement("div", {
    "className": "_5763e6a6 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3" + (props["header"] ? " " + "_c530b6d5_header _pub-c530b6d5_header _pub-a97d53f3_header header" : "") + (props["padded"] ? " " + "_c530b6d5_padded _pub-c530b6d5_padded _pub-a97d53f3_padded padded" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { _ModalContentChildren };

var _ModalContent = React.memo(React.forwardRef(function _ModalContent(props, ref) {
  return React.createElement("div", {
    "className": "_9ae961a8 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["narrow"] ? " " + "_c530b6d5_narrow _pub-c530b6d5_narrow _pub-a97d53f3_narrow narrow" : "") + (props["wide"] ? " " + "_c530b6d5_wide _pub-c530b6d5_wide _pub-a97d53f3_wide wide" : "") + (props["veryWide"] ? " " + "_c530b6d5_very-wide _pub-c530b6d5_very-wide _pub-a97d53f3_very-wide very-wide" : "") + (props["ultraWide"] ? " " + "_c530b6d5_ultra-wide _pub-c530b6d5_ultra-wide _pub-a97d53f3_ultra-wide ultra-wide" : "") + (props["sixtyfivePercentScreenWide"] ? " " + "_c530b6d5_sixtyfive-percent-screen-wide _pub-c530b6d5_sixtyfive-percent-screen-wide _pub-a97d53f3_sixtyfive-percent-screen-wide sixtyfive-percent-screen-wide" : "") + (props["fullHeight"] ? " " + "_c530b6d5_full-height _pub-c530b6d5_full-height _pub-a97d53f3_full-height full-height" : "") + (props["halfHeight"] ? " " + "_c530b6d5_half-height _pub-c530b6d5_half-height _pub-a97d53f3_half-height half-height" : "") + (props["serviceLogoLeft"] ? " " + "_c530b6d5_service-logo-left _pub-c530b6d5_service-logo-left _pub-a97d53f3_service-logo-left service-logo-left" : "") + (props["v5"] ? " " + "_c530b6d5_modal-v5 _pub-c530b6d5_modal-v5 _pub-a97d53f3_modal-v5 modal-v5" : "") + (props["v5connected"] ? " " + "_c530b6d5_modal-v5-connected _pub-c530b6d5_modal-v5-connected _pub-a97d53f3_modal-v5-connected modal-v5-connected" : "") + (props["v5files"] ? " " + "_c530b6d5_modal-v5-files _pub-c530b6d5_modal-v5-files _pub-a97d53f3_modal-v5-files modal-v5-files" : "") + (props["isError"] ? " " + "_c530b6d5_is-error _pub-c530b6d5_is-error _pub-a97d53f3_is-error is-error" : ""),
    "ref": ref,
  }, 
    props["serviceLogoNode"]&&React.createElement("div", {
      "className": "_5d0b37c7 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3" + " " + "_c530b6d5_service-logo _pub-c530b6d5_service-logo _pub-a97d53f3_service-logo service-logo",
    }, 
      props["serviceLogoNode"]
    ),
    props["serviceLogo"]&&React.createElement("div", {
      "className": "_76266404 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3" + " " + "_c530b6d5_service-logo _pub-c530b6d5_service-logo _pub-a97d53f3_service-logo service-logo",
    }, 
      React.createElement("img", {
        "className": "_1e9e5e49 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3",
        "src": props["serviceLogo"],
      }, null)
    ),
    props["header"]&&React.createElement("div", {
      "className": "_6f3d5545 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3" + (props["padded"] ? " " + "_c530b6d5_padded _pub-c530b6d5_padded _pub-a97d53f3_padded padded" : ""),
    }, 
      props["header"]
    ),
    props["children"],
    props["footer"]&&React.createElement("div", {
      "className": "_b519041 _c530b6d5 _pub-c530b6d5 _pub-a97d53f3",
    }, 
      props["footer"]
    )
  )
}));
export { _ModalContent };

var ModalPreview = React.memo(React.forwardRef(function ModalPreview(props, ref) {
  return React.createElement($$Default, {
    "class": "_738ac49d" + (props["class"] ? " " + props["class"] : ""),
    "ref": ref,
    "side": props["side"],
    "visible": props["visible"],
    "fillMobile": props["fillMobile"],
    "padded": props["padded"],
    "v5": props["v5"],
    "v5connected": props["v5connected"],
    "v5files": props["v5files"],
  }, 
    React.createElement(_ModalContent, {
      "class": "_1fccf9c8",
      "fullHeight": props["fullHeight"],
      "header": props["header"],
      "narrow": props["narrow"],
      "padded": props["padded"],
      "wide": props["wide"],
      "veryWide": props["veryWide"],
      "ultraWide": props["ultraWide"],
      "footer": props["footer"],
      "v5": props["v5"],
      "v5connected": props["v5connected"],
      "v5files": props["v5files"],
    }, 
      React.createElement(_ModalContentChildren, {
        "class": "_8068708e",
        "padded": props["padded"],
        "header": props["header"],
      }, 
        props["children"]
      )
    )
  )
}));
export { ModalPreview };


