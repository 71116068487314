import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./FileDropZone.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_f0318906 _b415f4b5 _pub-b415f4b5 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-testid": "file-drop-zone",
    "style": castStyle(props["style"]),
  }, 
    React.createElement("div", {
      "className": "_f2132419 _b415f4b5 _pub-b415f4b5 _pub-a97d53f3" + (props["fileHover"] ? " " + "_b415f4b5_file-hover _pub-b415f4b5_file-hover _pub-a97d53f3_file-hover file-hover" : "") + (props["invalid"] ? " " + "_b415f4b5_invalid _pub-b415f4b5_invalid _pub-a97d53f3_invalid invalid" : "") + (props["hover"] ? " " + "_b415f4b5_hover _pub-b415f4b5_hover _pub-a97d53f3_hover hover" : ""),
      "onDragEnter": props["onDragEnter"],
      "onDragExit": props["onDragExit"],
      "onDrop": props["onDrop"],
    }, 
      React.createElement("div", {
        "className": "_972ef7f0 _b415f4b5 _pub-b415f4b5 _pub-a97d53f3",
      }, 
        !props["invalid"]&&React.createElement("span", {
          "className": "_1dbf324d _b415f4b5 _pub-b415f4b5 _pub-a97d53f3",
        }, 
          "\n        Drop files or click\n\n        "
          ,
          React.createElement("a", {
            "className": "_bd2d355d _b415f4b5 _pub-b415f4b5 _pub-a97d53f3",
            "href": "#",
            "onClick": props["onUploadClick"],
          }, 
            "here"
            
          ),
          "\n\n        to upload\n      "
          
        ),
        props["invalid"]&&React.createElement("strong", {
          "className": "_3692618e _b415f4b5 _pub-b415f4b5 _pub-a97d53f3" + " " + "_b415f4b5_text-body-small _pub-b415f4b5_text-body-small _pub-a97d53f3_text-body-small text-body-small",
        }, 
          props["invalidFileMimeTypeErrorText"]||"File type invalid"
        ),
        props["acceptedFileTypesLabel"]&&React.createElement("div", {
          "className": "_2f8950cf _b415f4b5 _pub-b415f4b5 _pub-a97d53f3",
        }, 
          props["acceptedFileTypesLabel"]
        )
      ),
      React.createElement("input", {
        "className": "_e27a64a _b415f4b5 _pub-b415f4b5 _pub-a97d53f3",
        "type": "file",
        "onChange": props["onFileUploadChange"],
        "onClick": props["onFileUploadClick"],
        "accept": props["accept"],
      }, null)
    )
  )
}));
export default $$Default;


