import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';
import { HistoryState } from '../state';

export enum HistoryActionType {
  ROUTER_URL_PUSHED = 'ROUTER_URL_PUSHED',
  HISTORY_CHANGED = 'HISTORY_CHANGED',
  HISTORY_INITIALIZED = 'HISTORY_INITIALIZED',
}

export type RouterUrlPushed = BaseAction<
  HistoryActionType.ROUTER_URL_PUSHED,
  Partial<HistoryState>
>;
export type HistoryInitialized = BaseAction<
  HistoryActionType.HISTORY_INITIALIZED,
  Partial<HistoryState>
>;

export type HistoryChanged = BaseAction<
  HistoryActionType.HISTORY_CHANGED,
  Partial<HistoryState>
>;

export const routerUrlPushed = actionCreator<RouterUrlPushed>(
  HistoryActionType.ROUTER_URL_PUSHED
);
export const historyChanged = actionCreator<HistoryChanged>(
  HistoryActionType.HISTORY_CHANGED
);
export const historyInitialized = actionCreator<HistoryInitialized>(
  HistoryActionType.HISTORY_INITIALIZED
);

export type HistoryAction =
  | RouterUrlPushed
  | HistoryInitialized
  | HistoryChanged;
