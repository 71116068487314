import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pub9F4190D6 from "./icons.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Hint.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_b8ca09cc _ff6900e1 _pub-ff6900e1 _pub-a97d53f3" + " " + "_ff6900e1_text-body-medium _pub-ff6900e1_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["wide"] ? " " + "_ff6900e1_wide _pub-ff6900e1_wide _pub-a97d53f3_wide wide" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_2ebed006 _ff6900e1 _pub-ff6900e1 _pub-a97d53f3",
    }, null),
    props["children"]
  )
}));
export default $$Default;

var HintPreview = React.memo(React.forwardRef(function HintPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_56c468e0 _ff6900e1 _pub-ff6900e1 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement($$Default, {
      "class": "_2d3a0468",
    }, 
      "\n    We have over 250 investors in the Intelligent Capital Market and hundreds of\n    different types of debt offerings\n  "
      
    ),
    React.createElement($$Default, {
      "class": "_b43355d2",
    }, 
      "\n    Branded hint\n  "
      
    ),
    React.createElement($$Default, {
      "class": "_c3346544",
      "wide": true,
    }, 
      "\n    We have over 250 investors in the Intelligent Capital Market and hundreds of\n    different types of debt offerings\n  "
      
    )
  )
}));
export { HintPreview };


