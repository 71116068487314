/* eslint-disable @typescript-eslint/no-explicit-any */
import { env } from '@hum/common';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { httpClient } from './http';
import { handleErrors as onError, handleRespondedErrors } from './errors';

// Adapts the backend axios instance for the intercepted responses from below.
// prettier-ignore
export interface Backend extends AxiosInstance {
  request<T = any, R = T>(config: AxiosRequestConfig): Promise<R>
  get<T = any, R = T>(url: string, config?: AxiosRequestConfig): Promise<R>
  delete<T = any, R = T>(url: string, config?: AxiosRequestConfig): Promise<R>
  head<T = any, R = T>(url: string, config?: AxiosRequestConfig): Promise<R>
  post<T = any, R = T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>
  put<T = any, R = T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>
  patch<T = any, R = T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>
}

export type Client = Backend;

const LEGACY_API_BASE_URL = 'http://localhost:4000/api';

export const createClient = (
  baseURL: string = env.NEXT_STATIC_BACKEND_API_HOST || LEGACY_API_BASE_URL
): Backend => {
  return httpClient.create({
    baseURL,
    withCredentials: true,
  });
};

export const client: Backend = createClient();

// handle errors responded with 2xx responses.
client.interceptors.response.use(handleRespondedErrors);

// extract data on success (only important part).
client.interceptors.response.use(({ data }) => data);

// handle network errors (4xx & 5xx responses).
client.interceptors.response.use(undefined, onError);
