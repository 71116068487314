import React, { useState, KeyboardEvent, useEffect, useCallback } from 'react';
import StyledTextInput, { Eye, EyeOff } from './TextInput.pc';
import NumberInput from 'react-number-format';
import { noop } from 'lodash';

export type InnerProps = {
  testid?: any;
  disabled?: boolean;
  autoFocus?: boolean;
  ref?: string;
  onChange?: Function;
  defaultValue?: any;
  tabIndex?: number;
};

export enum InputMode {
  DOLLAR = 'dollar',
  PHONE_NUMBER = 'phone_number',
  MONTH_YEAR_DATE = 'month_year_date',
  TEXT = 'text',
  PASSWORD = 'password',
  TEXT_AREA = 'text_area',
}

export type Props = {
  'data-testid'?: any;
  style?: any;
  placeholder?: string;
  className?: string;
  autoComplete?: React.ReactNode;
  autoFocus?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  type?: string;
} & TextInputHookProps;

const isNumericMode = (mode: InputMode) => {
  return (
    mode === InputMode.DOLLAR ||
    mode === InputMode.PHONE_NUMBER ||
    mode === InputMode.MONTH_YEAR_DATE
  );
};

const castValue = (value: number, mode: InputMode) => {
  if (isNumericMode(mode)) {
    return Number(value);
  }
  return value;
};

/**
 * Enhances text-input-like components such as input & textarea (Only ones I'm aware of AFAIK) (CC)
 */

export function enhanceTextInput<TProps extends InnerProps>(
  TextInputComponent: React.FC<
    Pick<
      TProps,
      Exclude<
        keyof TProps,
        'data-testid' | 'value' | 'onValueChange' | 'inputMode'
      >
    >
  >
): React.FC<Props & TProps> {
  return ({
    'data-testid': testid,
    value,
    onValueChange,
    disabled,
    inputMode,
    autoFocus,
    tabIndex,
    type = 'text',
    ...rest
  }: TProps & Props) => {
    const { onChange } = useTextInput({
      onValueChange,
      inputMode,
      value,
    });
    return (
      <TextInputComponent
        {...(rest as any)}
        tabIndex={tabIndex}
        disabled={disabled}
        autoFocus={autoFocus}
        testid={testid}
        onChange={onChange}
        defaultValue={value}
        type={type}
      />
    );
  };
}

export type TextInputHookProps = {
  value?: string;
  inputMode?: InputMode;
  onValueChange?: (value: any) => void;
  format?: string;
};

export const useTextInput = ({
  value,
  onValueChange,
  format,
  inputMode = InputMode.TEXT,
}: TextInputHookProps) => {
  const [internalValue, setInternalValue] = useState<any>(value);

  // sync switch for syncing input value with value passed in
  const [shouldSync, setShouldSync] = useState(false);
  const [type, setType] = useState(
    inputMode === InputMode.PASSWORD ? 'password' : 'text'
  );

  // check here to ensure that props.value is the same as internalValue. If not, then
  // we update.
  useEffect(() => {
    // if internal value mismatch then sync value with input & set internal value
    if (internalValue !== value) {
      setInternalValue(value);
      setShouldSync(true);
    }
  }, [value]);

  // async turn it off so that value isn't returned in props next time this hook
  // is called
  useEffect(() => {
    setShouldSync(false);
  }, [shouldSync]);

  const onChange = (event: KeyboardEvent<any>) => {
    onValueChange2((event.target as any).value);
  };

  const onValueChange2 = (value: any) => {
    const actualValue = castValue(value, inputMode);
    setInternalValue(actualValue);
    if (onValueChange) {
      onValueChange(actualValue);
    }
  };

  const onIconClick = () => {
    if (inputMode === InputMode.PASSWORD) {
      if (type === 'text') {
        setType('password');
      } else {
        setType('text');
      }
    }
  };

  const NumberInputComponent = useCallback(
    (props) => {
      const onChange = ({ floatValue }: any) => {
        onValueChange2(floatValue);
      };

      if (inputMode === InputMode.PHONE_NUMBER) {
        return (
          <NumberInput
            {...props}
            format="###-###-####"
            mask="_"
            onChange={noop}
            onValueChange={onChange}
          />
        );
      } else if (inputMode === InputMode.MONTH_YEAR_DATE) {
        return (
          <NumberInput
            {...props}
            format="##/####"
            mask="_"
            onChange={noop}
            onValueChange={onChange}
          />
        );
      } else if (inputMode === InputMode.DOLLAR) {
        return (
          <NumberInput
            {...props}
            prefix="$"
            thousandSeparator
            onChange={noop}
            onValueChange={onChange}
            decimalSeparator={false}
            decimalScale={0}
            allowLeadingZeros={false}
          />
        );
      }
    },
    [format, setInternalValue]
  );

  let tagName: string | typeof NumberInputComponent = 'input';

  if (inputMode === InputMode.TEXT_AREA) {
    tagName = 'textarea';
  } else if (isNumericMode(inputMode)) {
    tagName = NumberInputComponent;
  }

  let props: any = {
    onChange,
    defaultValue: value,
    onIconClick,
    type,
    tagName,
  };

  // if sync is set then upstream value has changed, so set
  // value here. Note that value should _NOT_ be persistent since it'll
  // lock the input value in its place
  if (shouldSync) {
    props.value = internalValue;
  }

  return props;
};

export type TextInputProps = {
  'data-testid'?: string;
  format?: string;
  subtext?: string;
  xsmall?: boolean;
  small?: boolean;
  hasError?: boolean;
  prefix?: string;
  placeholder?: string;
  icon?: any;
  className?: string;
  thousandSeparator?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  minimal?: boolean;
} & TextInputHookProps;

export const TextInput = ({
  inputMode,
  onValueChange,
  value,
  subtext,
  'data-testid': testId,
  format,
  prefix,
  disabled,
  icon,
  minimal,
  ...rest
}: TextInputProps) => {
  const { type, onIconClick, ...restHookProps } = useTextInput({
    onValueChange,
    inputMode,
    value,
    format,
  });

  if (inputMode === InputMode.PASSWORD && !disabled) {
    icon =
      type === 'password' ? (
        <Eye onClick={onIconClick} />
      ) : (
        <EyeOff onClick={onIconClick} />
      );
  }

  return (
    <StyledTextInput
      type={type}
      testid={testId}
      v3
      disabled={disabled}
      {...rest}
      {...restHookProps}
      icon={icon}
      minimal={minimal}
    />
  );
};
