import { put, call } from 'redux-saga/effects';
import { BaseRequestStateChanged } from '../actions';
import {
  createDataResult,
  createErrorResult,
  createLoadingResult,
  Result,
} from '../state';

export function* request<TData extends any>(
  actionCreator: (payload: {
    result: Result<TData>;
  }) => BaseRequestStateChanged<unknown, TData>,
  load: () => Generator<any, TData, any> | Promise<TData>
): Generator<any, any, any> {
  yield put(actionCreator({ result: createLoadingResult() }));
  try {
    const data = (yield call(load)) as any;
    const result = createDataResult(data);
    yield put(actionCreator({ result }));
    return result;
  } catch (error: any) {
    const result = createErrorResult(error);
    yield put(actionCreator({ result }));
    return result;
  }
}
