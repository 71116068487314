//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Do NOT extend the functionality of this component beyond a UI element that displays
// a table of information with sorting capabilities. if you need to do anything extra (like add an extra element [like filtering data]), then
// create a new component for that and use this component.
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import StyledTable, {
  TableCell,
  TableDataRows,
  TableRow,
  TableSubtext,
} from './Table.pc';

import { MoreMenu, MoreMenuItem } from './MoreMenu';
import React, { memo, useState } from 'react';

import { Scrollable } from './Scrollable';
import Scrollbars from 'react-scrollbars-custom';

export { TableSubtext };
export type TableCellConfig = {
  label: string;
  style?: Record<string, any>;
  getSortValue?: (item: any, key: string) => any;
  renderer?: React.FC<{ item: any; propertyName: string }>;
};

export type TableMenuItem = {
  label: string;
  value: any;
};

export interface TableRowsProps {
  sortedData: any;
  itemKey: string;
  cellProps: any;
  selectedRow?: any;
  selectedRowRef: any;
  onRowClick?: (item: any) => void;
  labelKeys: any;
  cellConfig: Record<string, TableCellConfig>;
  onMenuItemClick?: (menuValue: any, item: any) => void;
  getMenuItems?: (item: any) => TableMenuItem[];
  wideCells?: boolean;
  nowrap?: boolean;
  fixedCellWidth: any;
  secondaryButton?: boolean;
}

const TableRows = ({
  sortedData,
  itemKey,
  selectedRow,
  selectedRowRef,
  onRowClick = () => {},
  labelKeys,
  cellConfig,
  onMenuItemClick,
  secondaryButton,
  getMenuItems,
  cellProps,
  wideCells,
  fixedCellWidth = false,
  nowrap,
}: TableRowsProps) => {
  return sortedData.map((item: any, i: number) => {
    const value = item[itemKey]!;
    const selected = value === selectedRow;
    return (
      <TableRow
        ref={selected ? selectedRowRef : undefined}
        key={value}
        selected={selected}
        onClick={() => onRowClick(value)}
        testId={`table-row-${i}`}
      >
        {labelKeys.map((key: string, i: number) => {
          const config = cellConfig[key];
          const cellStyle = fixedCellWidth
            ? {
                maxWidth: `${100 / labelKeys.length}%`,
              }
            : {};

          if (config.style) {
            Object.assign(cellStyle, cellStyle);
          }
          return (
            <Cell
              last={i === labelKeys.length - 1}
              extraProps={cellProps}
              key={key}
              propertyName={key}
              value={item[key]}
              item={item}
              config={config}
              onMenuItemClick={onMenuItemClick}
              getMenuItems={getMenuItems}
              secondaryButton={secondaryButton}
              wideCells={wideCells}
              nowrap={nowrap}
              style={cellStyle}
            />
          );
        })}
      </TableRow>
    );
  });
};

export type TableProps = {
  cellConfig: Record<string, TableCellConfig>;
  data: any[];
  itemKey: string;
  style?: any;
  onRowClick?: (item: any) => void;
  onMenuItemClick?: (menuValue: any, item: any) => void;
  getMenuItems?: (item: any) => TableMenuItem[];
  defaultSortKey?: string;
  dontSortByDefault?: boolean;
  allowSearchBy?: string[];
  wideCells?: boolean;
  selectedRow?: any;
  companyAnalyses?: boolean;
  cellProps?: any;
  scrollToSelected?: boolean;
  secondary?: boolean;
  withChevron?: boolean;
  fixedCellWidth?: boolean;
  nowrap?: boolean;
  secondaryButton?: boolean;
};

export const Table = memo(
  ({
    data,
    itemKey,
    style,
    onRowClick = () => {},
    onMenuItemClick,
    getMenuItems,
    cellProps = {},
    defaultSortKey,
    dontSortByDefault = false,
    cellConfig,
    wideCells = false,
    selectedRow,
    companyAnalyses = false,
    scrollToSelected = false,
    secondary = false,
    withChevron = false,
    fixedCellWidth = false,
    nowrap,
    secondaryButton,
  }: TableProps) => {
    const labelKeys = Object.keys(cellConfig);
    const [sortKey, setSortKey] = useState(defaultSortKey || labelKeys[0]);
    const [ascending, setAscending] = useState(true);

    const getSortValue =
      cellConfig[sortKey]?.getSortValue || getDefaultSortValue;

    // don't want to sort directly since that will mutate the original array -- might
    // point to a frozen object which would throw an exception.
    let sortedData = [...data];
    if (!dontSortByDefault) {
      sortedData = sortedData.sort((a, b) => {
        const order = ascending ? -1 : 1;
        return getSortValue(a, sortKey) > getSortValue(b, sortKey)
          ? -order
          : order;
      });
    }

    const includeScrollable = style?.hasOwnProperty('--scrollbar-height');

    const selectedRowRef = React.createRef<Element>();
    const scrollableRef = React.createRef<Scrollbars>();

    React.useLayoutEffect(() => {
      if (scrollToSelected) {
        autoScroll(selectedRowRef.current, scrollableRef.current);
      }
    }, [selectedRowRef.current, scrollableRef.current]);

    const tableRowProps = {
      sortedData,
      itemKey,
      selectedRow,
      selectedRowRef,
      onRowClick,
      labelKeys,
      cellProps,
      cellConfig,
      onMenuItemClick,
      getMenuItems,
      wideCells,
      fixedCellWidth,
      nowrap,
      secondaryButton,
    };

    const rows = <TableRows {...tableRowProps} />;

    return (
      <>
        <StyledTable
          companyAnalyses={companyAnalyses}
          secondary={secondary}
          withChevron={withChevron}
        >
          <TableRow header testId={`table-header`}>
            {labelKeys.map((key) => {
              const config = cellConfig[key];
              const isSortable = typeof config.getSortValue !== 'undefined';
              return (
                <TableCell
                  onClick={() => {
                    if (!isSortable) {
                      return;
                    }
                    if (sortKey === key) {
                      setAscending(!ascending);
                    } else {
                      setSortKey(key);
                      setAscending(true);
                    }
                  }}
                  sortable={isSortable}
                  selected={sortKey === key}
                  ascending={ascending}
                  descending={!ascending}
                  key={key}
                  wideCells={wideCells}
                >
                  {config.label}
                </TableCell>
              );
            })}
          </TableRow>
          <TableDataRows style={style}>
            {includeScrollable ? (
              <Scrollable ref={scrollableRef} children={rows} />
            ) : (
              rows
            )}
          </TableDataRows>
        </StyledTable>
      </>
    );
  }
);
Table.displayName = 'Table';
type CellProps = {
  propertyName: string;
  value: string;
  config: TableCellConfig;
  extraProps: any;
  item: any;
  last: boolean;
  onMenuItemClick: any;
  getMenuItems?: (item: any) => TableMenuItem[];
  wideCells?: boolean;
  style: any;
  nowrap?: boolean;
  secondaryButton?: boolean;
};

const Cell = ({
  onMenuItemClick,
  propertyName,
  value,
  item,
  extraProps,
  last,
  config,
  getMenuItems,
  wideCells,
  style,
  nowrap,
  secondaryButton,
}: CellProps) => {
  return (
    <TableCell wideCells={wideCells} style={style} nowrap={nowrap}>
      {config.renderer
        ? React.createElement(config.renderer, {
            ...extraProps,
            item,
            propertyName,
          })
        : value}
      {/* only show menu option in last cell */}
      {last &&
        getMenuItems &&
        (getMenuItems(item).length > 0 ? (
          <MoreMenu secondaryButton={secondaryButton}>
            {getMenuItems(item).map((menuItem) => {
              return (
                <MoreMenuItem
                  key={menuItem.value}
                  onClick={() => {
                    onMenuItemClick && onMenuItemClick(menuItem.value, item);
                  }}
                >
                  {menuItem.label}
                </MoreMenuItem>
              );
            })}
          </MoreMenu>
        ) : null)}
    </TableCell>
  );
};

const getDefaultSortValue = (item: any, key: any) => item[key];

function autoScroll(
  currentSelected: Element | null,
  currentScrollable: Scrollbars | null
) {
  if (
    typeof window === 'undefined' ||
    !currentSelected ||
    !currentScrollable?.contentElement
  )
    return;

  // prevent crash if ref is not there
  if (!currentSelected) return;

  // https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
  const currentSelecteats = currentSelected.getBoundingClientRect();
  const currentScrollableStats = currentScrollable.contentElement.getBoundingClientRect();
  window.setTimeout(() => {
    const y =
      currentSelecteats.bottom -
      (currentScrollableStats.top + currentScrollableStats.height);
    currentScrollable.scrollTo(undefined, y);
  });
}
