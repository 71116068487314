import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./TabNavigation.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var TabNavigationContainer = React.memo(React.forwardRef(function TabNavigationContainer(props, ref) {
  return React.createElement("div", {
    "className": "_d5b733ad _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3" + (props["slim"] ? " " + "_fc2ceb2_slim _pub-fc2ceb2_slim _pub-a97d53f3_slim slim" : "") + (props["horizontal"] ? " " + "_fc2ceb2_horizontal _pub-fc2ceb2_horizontal _pub-a97d53f3_horizontal horizontal" : "") + (props["paddingBottom"] ? " " + "_fc2ceb2_paddingBottom _pub-fc2ceb2_paddingBottom _pub-a97d53f3_paddingBottom paddingBottom" : "") + (props["noPadding"] ? " " + "_fc2ceb2_no-padding _pub-fc2ceb2_no-padding _pub-a97d53f3_no-padding no-padding" : ""),
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    props["children"]
  )
}));
export { TabNavigationContainer };

var TabNavigationItems = React.memo(React.forwardRef(function TabNavigationItems(props, ref) {
  return React.createElement("div", {
    "className": "_3bb95281 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-testid": props["testId"],
  }, 
    React.createElement("div", {
      "className": "_e0d7dad7 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_9852c0ef _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_72affdeb _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
        }, 
          props["children"]
        ),
        React.createElement("div", {
          "className": "_eba6ac51 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
        }, 
          props["controls"]
        )
      ),
      React.createElement("div", {
        "className": "_765ca1c3 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
      }, null)
    )
  )
}));
export { TabNavigationItems };

var TabNavigationItem = React.memo(React.forwardRef(function TabNavigationItem(props, ref) {
  return React.createElement(props.tagName || "button", {
    "className": "_dc017f86 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3" + " " + "_fc2ceb2_text-body-medium _pub-fc2ceb2_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["p2"] ? " " + "_fc2ceb2_p2 _pub-fc2ceb2_p2 _pub-a97d53f3_p2 p2" : "") + (props["active"] ? " " + "_fc2ceb2_active _pub-fc2ceb2_active _pub-a97d53f3_active active" : "") + (props["hover"] ? " " + "_fc2ceb2_hover _pub-fc2ceb2_hover _pub-a97d53f3_hover hover" : "") + (props["inactive"] ? " " + "_fc2ceb2_inactive _pub-fc2ceb2_inactive _pub-a97d53f3_inactive inactive" : ""),
    "ref": ref,
    "data-testid": props["testId"],
    "onClick": props["onClick"],
    "to": props["to"],
  }, 
    React.createElement("div", {
      "className": "_44796f1 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
    }, null),
    props["children"]
  )
}));
export { TabNavigationItem };

var AccordionTabNavigationItem = React.memo(React.forwardRef(function AccordionTabNavigationItem(props, ref) {
  return React.createElement("div", {
    "className": "_211e4302 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("button", {
      "className": "_9ac92b0a _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3" + " " + "_fc2ceb2_text-body-medium _pub-fc2ceb2_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium" + (props["active"] ? " " + "_fc2ceb2_active _pub-fc2ceb2_active _pub-a97d53f3_active active" : ""),
      "data-testid": props["testId"],
      "onClick": props["onClick"],
    }, 
      React.createElement("div", {
        "className": "_667ec329 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3",
      }, 
        props["label"],
        React.createElement("button", {
          "className": "_ef0f730f _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3" + (props["hover"] ? " " + "_fc2ceb2_hover _pub-fc2ceb2_hover _pub-a97d53f3_hover hover" : "") + (props["expanded"] ? " " + "_fc2ceb2_expanded _pub-fc2ceb2_expanded _pub-a97d53f3_expanded expanded" : ""),
          "onClick": props["onExpandClick"],
        }, null)
      )
    ),
    React.createElement("div", {
      "className": "_74c74a26 _fc2ceb2 _pub-fc2ceb2 _pub-a97d53f3" + (props["expanded"] ? " " + "_fc2ceb2_expanded _pub-fc2ceb2_expanded _pub-a97d53f3_expanded expanded" : ""),
    }, 
      props["children"]
    )
  )
}));
export { AccordionTabNavigationItem };


