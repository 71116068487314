import { createMockClient, MockClient } from './mock-client';
import { createMemoryHistory } from 'history';
import { Action, Middleware, Store } from 'redux';
import { isEqual } from 'lodash';
import { History } from 'history';

type CreateTestStoreOptions<TState> = {
  location?: string;
  initialState?: Partial<TState>;
};

type AppStoreCreatorOptions<TState> = {
  client: any;
  history: History;
  initialState: Partial<TState>;
  middleware: Middleware<any>[];
};

type AppStoreCreator<TState, TAction extends Action<any>> = (
  options: AppStoreCreatorOptions<TState>
) => Store<TState, TAction>;

export type TestStore<TState, TAction extends Action<any>> = {
  history: History;
  trackActions(): {
    expectDispatched(action: Action): any;
  };
  apiClient: MockClient;
  expectPartialState(state: Partial<TState>): void;
} & Store<TState, TAction>;

export type TestStoreCreator<TState, TAction extends Action<any>> = (
  options?: CreateTestStoreOptions<TState>
) => TestStore<TState, TAction>;

/**
 * Generalized mocker for application stores
 */

export const testStoreCreator = <TState, TAction extends Action<any>>(
  createAppStore: AppStoreCreator<TState, TAction>
): TestStoreCreator<TState, TAction> => ({
  location,
  initialState = {},
}: CreateTestStoreOptions<TState> = {}) => {
  const client = createMockClient();
  const history = createMemoryHistory();
  if (location) {
    history.push(location);
  }
  const actionEmitters: Array<(action: Action) => void> = [];

  const trackAction: Middleware = () => (next) => (action) => {
    for (const emit of actionEmitters) {
      emit(action);
    }
    next(action);
  };

  const middleware = [trackAction];

  const store = createAppStore({
    client,
    history,
    initialState,
    middleware,
  });

  return {
    ...store,
    history,
    trackActions() {
      const { emit, ...rest } = trackActions();
      actionEmitters.push(emit);
      return rest;
    },
    apiClient: client,
    expectPartialState(state: Partial<TState>) {
      expect(store.getState()).toMatchObject(state);
    },
  };
};

const trackActions = () => {
  const actions: Action[] = [];
  return {
    emit(action: Action) {
      actions.push(action);
    },
    expectDispatched(action: Action) {
      const matchingAction = actions.shift();

      if (!isEqual(matchingAction, action)) {
        throw new Error(
          `Expected dispatched action ${JSON.stringify(
            action,
            null,
            2
          )}, got ${JSON.stringify(matchingAction, null, 2)}`
        );
      }
    },
  };
};
