import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pub9F4190D6 from "./icons.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub2Dbb4493 from "./Button.pc";
import _pubD91D8869 from "./CheckCircleIcon.pc";
import _pubD3A4Dbf5, {DownloadIcon as _pubD3A4Dbf5_DownloadIcon} from "./DownloadIcon.pc";
import _pubFe0274B0, {TooltipContainer as _pubFe0274B0_TooltipContainer} from "./Tooltip.pc";
import "./SetupCard.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_1a2485c2 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3" + " " + "_3d3dc4cd_v3 _pub-3d3dc4cd_v3 _pub-a97d53f3_v3 v3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export default $$Default;

var SetupCardHeader = React.memo(React.forwardRef(function SetupCardHeader(props, ref) {
  return React.createElement("div", {
    "className": "_3e75e2a6 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3" + " " + "_3d3dc4cd_font-main _pub-3d3dc4cd_font-main _pub-a97d53f3_font-main font-main" + (props["graphicNoMargin"] ? " " + "_3d3dc4cd_graphic-no-margin _pub-3d3dc4cd_graphic-no-margin _pub-a97d53f3_graphic-no-margin graphic-no-margin" : "") + (props["active"] ? " " + "_3d3dc4cd_active _pub-3d3dc4cd_active _pub-a97d53f3_active active" : "") + (props["v2"] ? " " + "_3d3dc4cd_v2 _pub-3d3dc4cd_v2 _pub-a97d53f3_v2 v2" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_8674ee64 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
    }, 
      React.createElement("h2", {
        "className": "_1099f14 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3" + " " + "_3d3dc4cd_text-heading-X _pub-3d3dc4cd_text-heading-X _pub-a97d53f3_text-heading-X text-heading-X",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_760eaf82 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      }, 
        props["description"],
        props["action"]&&React.createElement("div", {
          "className": "_28eb2ac5 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
        }, 
          props["action"]
        )
      )
    ),
    React.createElement("div", {
      "className": "_1f7dbfde _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
    }, 
      props["graphic"]
    )
  )
}));
export { SetupCardHeader };

var SetupCardItem = React.memo(React.forwardRef(function SetupCardItem(props, ref) {
  return React.createElement("div", {
    "className": "_d07b838a _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3" + " " + "_3d3dc4cd_font-body _pub-3d3dc4cd_font-body _pub-a97d53f3_font-body font-body" + (props["complete"] ? " " + "_3d3dc4cd_complete _pub-3d3dc4cd_complete _pub-a97d53f3_complete complete" : "") + (props["dismissable"] ? " " + "_3d3dc4cd_dismissable _pub-3d3dc4cd_dismissable _pub-a97d53f3_dismissable dismissable" : "") + (props["hidden"] ? " " + "_3d3dc4cd_hidden _pub-3d3dc4cd_hidden _pub-a97d53f3_hidden hidden" : "") + (props["show"] ? " " + "_3d3dc4cd_show _pub-3d3dc4cd_show _pub-a97d53f3_show show" : "") + (props["lockedReason"] ? " " + "_3d3dc4cd_lockedReason _pub-3d3dc4cd_lockedReason _pub-a97d53f3_lockedReason lockedReason" : "") + (props["children"] ? " " + "_3d3dc4cd_has-children _pub-3d3dc4cd_has-children _pub-a97d53f3_has-children has-children" : "") + (props["iconButton"] ? " " + "_3d3dc4cd_has-icon _pub-3d3dc4cd_has-icon _pub-a97d53f3_has-icon has-icon" : "") + (props["v2"] ? " " + "_3d3dc4cd_v2 _pub-3d3dc4cd_v2 _pub-a97d53f3_v2 v2" : "") + (props["active"] ? " " + "_3d3dc4cd_active _pub-3d3dc4cd_active _pub-a97d53f3_active active" : ""),
    "ref": ref,
    "data-testid": props["testId"],
    "onClick": props["onClick"],
    "transparent": props["transparent"],
  }, 
    React.createElement("div", {
      "className": "_85f03a0a _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
    }, 
      props["complete"]&&React.createElement(_pubD91D8869, {
        "class": "_bad39289 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
        "transparent": props["transparent"],
        "onClick": props["onDismissableClick"],
      }, null),
      !props["complete"]&&React.createElement(_pubFe0274B0_TooltipContainer, {
        "class": "_91fec14a _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      }, 
        props["lockedReason"]&&React.createElement(_pubFe0274B0, {
          "class": "_dab90a2c _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
          "center": true,
        }, 
          props["lockedReason"]
        ),
        props["iconText"]&&React.createElement("span", {
          "className": "_f19459ef _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
        }, 
          props["iconText"]
        ),
        props["iconButton"]||React.createElement("i", {
          "className": "_e88f68ae _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
        }, null)
      ),
      React.createElement("span", {
        "className": "_e2c09dce _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      }, 
        props["title"]
      ),
      props["optional"]&&React.createElement("span", {
        "className": "_c7a466cc _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      }, 
        React.createElement("Pill", {
          "className": "_f3b3d3a1 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
        }, 
          "Optional"
          
        )
      )
    ),
    React.createElement("div", {
      "className": "_1cf96bb0 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
    }, 
      props["subtitle"]&&React.createElement("div", {
        "className": "_a8663d67 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      }, 
        props["subtitle"]
      ),
      props["active"]&&React.createElement("div", {
        "className": "_834b6ea4 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      }, 
        props["children"]
      )
    )
  )
}));
export { SetupCardItem };

var ButtonCardItem = React.memo(React.forwardRef(function ButtonCardItem(props, ref) {
  return React.createElement("div", {
    "className": "_391826bf _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_817d46b8 _3d3dc4cd _pub-3d3dc4cd _pub-a97d53f3",
      "testId": props["testId"],
      "v3": true,
      "small": true,
      "wfull": true,
      "transparent": true,
      "outline": true,
      "onClick": props["onBtnClick"],
    }, 
      props["text"]
    )
  )
}));
export { ButtonCardItem };


