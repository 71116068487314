import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import "./ErrorText.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("span", {
    "className": "_6bbcf075 _c414d24 _pub-c414d24 _pub-a97d53f3" + " " + "_c414d24_ErrorText _pub-c414d24_ErrorText _pub-a97d53f3_ErrorText ErrorText",
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export default $$Default;


