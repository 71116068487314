import React, { forwardRef } from 'react';
import StyledButton from './Button.pc';
import Spinner from './Spinner.pc';

export enum ButtonType {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

type ButtonProps = {
  small?: boolean;
  loading?: boolean;
  primary?: boolean;
  large?: boolean;
  large2?: boolean;
  xsmall?: boolean;
  wide?: boolean;
  outline?: boolean;
  grow?: boolean;
  transparent?: boolean;
  secondary?: boolean;
  style?: any;
  title?: string;
  href?: any;
  negative?: boolean;
  negative2?: boolean;
  resetCase?: boolean;
  noMargin?: boolean;
  className?: string;
  disabled?: boolean;
  wfull?: boolean;
  onClick?: any;
  children: any;
  testId?: string;
  active?: boolean;
  'data-testid'?: string;
  v3?: boolean;
  tagName?: string;
  type?: string;
  tooltipText?: string;
};

export const Button = forwardRef(
  (
    {
      tagName,
      type,
      children,
      title,
      disabled,
      wfull,
      noMargin,
      xsmall,
      className,
      onClick,
      active,
      transparent,
      style,
      primary,
      small,
      href,
      large,
      large2,
      wide,
      grow,
      outline,
      loading,
      resetCase,
      negative,
      negative2,
      testId,
      'data-testid': testId2,
      v3,
      secondary,
      tooltipText,
    }: ButtonProps,
    ref
  ) => {
    return (
      <StyledButton
        tagName={tagName}
        loading={loading}
        type={type}
        small={small}
        title={title}
        large={large}
        style={style}
        href={href}
        active={active}
        disabled={disabled || loading}
        className={className}
        noMargin={noMargin}
        xsmall={xsmall}
        onClick={onClick}
        large2={large2}
        wide={wide}
        outline={outline}
        grow={grow}
        negative={negative}
        negative2={negative2}
        transparent={transparent}
        primary={primary}
        secondary={secondary}
        resetCase={resetCase}
        wfull={wfull}
        testId={testId || testId2}
        v3={v3}
        ref={ref}
        tooltipText={tooltipText}
      >
        {children} {loading && <Spinner />}
      </StyledButton>
    );
  }
);
