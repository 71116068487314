import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pubDf2A042 from "./Field.pc";
import "./Checkbox.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_2fec440d _14733275 _pub-14733275" + " " + "_14733275_checkbox _pub-14733275_checkbox checkbox " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["checked"] ? " " + "_14733275_checked _pub-14733275_checked checked" : "") + (props["hover"] ? " " + "_14733275_hover _pub-14733275_hover hover" : "") + (props["error"] ? " " + "_14733275_error _pub-14733275_error error" : "") + (props["boxOnTop"] ? " " + "_14733275_boxOnTop _pub-14733275_boxOnTop boxOnTop" : "") + (props["disabled"] ? " " + "_14733275_disabled _pub-14733275_disabled disabled" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": "checkbox",
    "type": "checkbox",
  }, 
    React.createElement("input", {
      "className": "_f4329c02 _14733275 _pub-14733275",
      "type": "checkbox",
      "checked": props["checked"],
      "disabled": props["disabled"],
      "onChange": props["onChange"],
      "id": props["id"],
      "data-testid": props["testId"],
    }, null),
    React.createElement("div", {
      "className": "_1a3cfd2e _14733275 _pub-14733275",
    }, 
      React.createElement("div", {
        "className": "_f826d59b _14733275 _pub-14733275",
      }, null)
    )
  )
}));
export default $$Default;

var CheckboxPreview = React.memo(React.forwardRef(function CheckboxPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_c1e22521 _14733275 _pub-14733275",
    "ref": ref,
    "spaced": true,
  }, 
    React.createElement($$Default, {
      "class": "_80b178fa",
    }, null),
    React.createElement($$Default, {
      "class": "_f7b6486c",
      "hover": true,
    }, null),
    React.createElement($$Default, {
      "class": "_6ebf19d6",
      "checked": true,
    }, null),
    React.createElement($$Default, {
      "class": "_19b82940",
      "checked": true,
      "hover": true,
    }, null),
    React.createElement("br", {
      "className": "_87dcbce3 _14733275 _pub-14733275",
    }, null),
    React.createElement($$Default, {
      "class": "_f0db8c75",
      "error": true,
    }, null),
    React.createElement($$Default, {
      "class": "_69d2ddcf",
      "error": true,
      "hover": true,
    }, null),
    React.createElement($$Default, {
      "class": "_1ed5ed59",
      "error": true,
      "checked": true,
    }, null),
    React.createElement($$Default, {
      "class": "_8e6af0c8",
      "error": true,
      "checked": true,
      "hover": true,
    }, null),
    React.createElement("br", {
      "className": "_f96dc05e _14733275 _pub-14733275",
    }, null),
    React.createElement($$Default, {
      "class": "_b028441a",
      "disabled": true,
    }, null),
    React.createElement($$Default, {
      "class": "_c72f748c",
      "disabled": true,
      "checked": true,
    }, null),
    React.createElement("br", {
      "className": "_5e262536 _14733275 _pub-14733275",
    }, null),
    React.createElement(_pubDf2A042, {
      "class": "_292115a0 _14733275 _pub-14733275",
      "toggle": true,
      "label": "Checkbox field",
    }, 
      React.createElement($$Default, {
        "class": "_84f0099f",
      }, null)
    )
  )
}));
export { CheckboxPreview };


