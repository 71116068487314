import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub63C09B0F from "./__DEPRECATED__typography.pc";
import _pubDa25D269 from "./__DEPRECATED__tailwind.pc";
import _pub824B8933, {Box as _pub824B8933_Box} from "./layout.pc";
import _pub9F4190D6 from "./icons.pc";
import _pub2Dbb4493 from "./Button.pc";
import _pubFe0274B0 from "./Tooltip.pc";
import _pub8A05Fd1A from "./BlankSlate.pc";
import _pubDf2A042 from "./Field.pc";
import _pub14733275 from "./Checkbox.pc";
import _pubA2Be02Ae from "./Page.pc";
import _pub8D610C4 from "./PageContent.pc";
import _pub7D0904Ad from "./Help.pc";
import _pubC530B6D5 from "./Modal.pc";
import _pub4435Fe58 from "./Pill.pc";
import _pubFc2Ceb2, {TabNavigationContainer as _pubFc2Ceb2_TabNavigationContainer,TabNavigationItems as _pubFc2Ceb2_TabNavigationItems,TabNavigationItem as _pubFc2Ceb2_TabNavigationItem} from "./TabNavigation.pc";
import _pub9Fdd11Dd from "./Progress.pc";
import _pubFf6900E1 from "./Hint.pc";
import _pub61E4905A from "./Separator.pc";
import _pubA8875Cb4 from "./Breadcrumbs.pc";
import _pubE434Fb6A from "./Card.pc";
import _pub9A961Ec6, {AccordionContent as _pub9A961Ec6_AccordionContent} from "./Accordion.pc";
import _pub2F5Be189 from "./LinkAccordion.pc";
import _pubB415F4B5 from "./FileDropZone.pc";
import _pub3D3Dc4Cd, {SetupCardHeader as _pub3D3Dc4Cd_SetupCardHeader,SetupCardItem as _pub3D3Dc4Cd_SetupCardItem} from "./SetupCard.pc";
import _pub5448A231 from "./Detail.pc";
import _pub74092D51, {ChecklistItem as _pub74092D51_ChecklistItem} from "./Checklist.pc";
import "./components.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var ComboPreview = React.memo(React.forwardRef(function ComboPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_c13bd699 _f29676e3 _pub-f29676e3 _pub-a97d53f3" + " " + "_f29676e3_text-body-medium _pub-f29676e3_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    "ref": ref,
    "flex": true,
  }, 
    React.createElement(_pubFc2Ceb2_TabNavigationContainer, {
      "class": "_ff1ecfa3 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
      "slim": true,
    }, 
      React.createElement(_pubFc2Ceb2_TabNavigationItems, {
        "class": "_c9dbb7a9 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
      }, 
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_6dea90e8 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "\n        Account Type\n      "
          
        ),
        React.createElement("a", {
          "className": "_1aeda07e _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          "href": "#",
        }, 
          React.createElement(_pubFc2Ceb2_TabNavigationItem, {
            "class": "_204095c1 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "tagName": "span",
            "active": true,
          }, 
            "\n          Company Details\n        "
            
          )
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_83e4f1c4 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "\n        Insight Requests\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_f4e3c152 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "Privacy"
          
        )
      ),
      React.createElement(_pub8D610C4, {
        "class": "_bedc873f _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        "spaceLeft": true,
      }, 
        React.createElement("div", {
          "className": "_1b2fca49 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          React.createElement(_pub5448A231, {
            "class": "_f32edd5d _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "header": true,
            "title": "Page Header",
            "description": "brief description",
          }, null),
          React.createElement(_pubE434Fb6A, {
            "class": "_8429edcb _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          }, 
            React.createElement(_pub5448A231, {
              "class": "_b19f6cd5 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "title": "Secure non-dilutive funding",
              "description": "I want to explore the Intelligent Capital Market to see both analytics and how Hum can facilitate the fundraising process for my company",
            }, null),
            React.createElement(_pub5448A231, {
              "class": "_c6985c43 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "description": "Extra description",
            }, null)
          ),
          React.createElement(_pubE434Fb6A, {
            "class": "_1a4d7868 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          }, 
            React.createElement(_pub5448A231, {
              "class": "_b4d07a50 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "title": "Company analytics",
              "description": "I am not in need of funding at this time, but would like to utilize the analytics tools of the Intelligent Capital Market to improve my company's performance",
            }, null)
          )
        )
      ),
      React.createElement(_pub8D610C4, {
        "class": "_27d5d685 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        "spaceLeft": true,
      }, 
        React.createElement(_pubFf6900E1, {
          "class": "_19697410 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "\n        We have over 250 investors in the Intelligent Capital Market and\n        hundreds of different types of debt offerings\n      "
          
        )
      )
    )
  )
}));
export { ComboPreview };

var ComboPreview3 = React.memo(React.forwardRef(function ComboPreview3(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_38f50ec6 _f29676e3 _pub-f29676e3 _pub-a97d53f3" + " " + "_f29676e3_text-body-medium _pub-f29676e3_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium" + (props["humDark"] ? " " + "_f29676e3_hum _pub-f29676e3_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_f29676e3_dark _pub-f29676e3_dark _pub-a97d53f3_dark dark" + " " + " " + " " + "_f29676e3_theme _pub-f29676e3_theme _pub-a97d53f3_theme theme" : "") + (props["full"] ? " " + "_f29676e3_full _pub-f29676e3_full _pub-a97d53f3_full full" : "") + (props["humLight"] ? " " + "_f29676e3_hum _pub-f29676e3_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_f29676e3_light _pub-f29676e3_light _pub-a97d53f3_light light" + " " + " " + " " + "_f29676e3_theme _pub-f29676e3_theme _pub-a97d53f3_theme theme" : "") + (props["branded"] ? " " + "_f29676e3_branded _pub-f29676e3_branded _pub-a97d53f3_branded branded" : ""),
    "ref": ref,
    "flex": true,
  }, 
    React.createElement(_pubFc2Ceb2_TabNavigationContainer, {
      "class": "_4a352d10 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
    }, 
      React.createElement(_pubFc2Ceb2_TabNavigationItems, {
        "class": "_48377cad _f29676e3 _pub-f29676e3 _pub-a97d53f3",
      }, 
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_530cf38 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "\n        Account Type\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_7237ffae _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          "active": true,
        }, 
          "\n        Company Details\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_eb3eae14 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "\n        Insight Requests\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_9c399e82 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "Privacy"
          
        ),
        React.createElement(AccordionTabNavigation, {
          "class": "_25d0b21 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          "label": "Beep Boop",
          "active": true,
        }, null)
      ),
      React.createElement(_pub8D610C4, {
        "class": "_3f304c3b _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        "spaceLeft": true,
      }, 
        React.createElement("div", {
          "className": "_73f59599 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          React.createElement(_pubA8875Cb4, {
            "class": "_10cb6d47 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          }, 
            React.createElement("BreadcrumbItem", {
              "className": "_cb11ac50 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, 
              "some"
              
            ),
            React.createElement("BreadcrumbItem", {
              "className": "_bc169cc6 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, 
              "nav"
              
            ),
            React.createElement("BreadcrumbItem", {
              "className": "_251fcd7c _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, 
              "item"
              
            )
          ),
          React.createElement(_pub5448A231, {
            "class": "_fec50c6b _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "header": true,
            "title": "Page Header",
          }, null),
          React.createElement("div", {
            "className": "_60a199c8 _f29676e3 _pub-f29676e3 _pub-a97d53f3" + " " + "_f29676e3_space-between-vertical _pub-f29676e3_space-between-vertical _pub-a97d53f3_space-between-vertical space-between-vertical _f29676e3_space-between-medium _pub-f29676e3_space-between-medium _pub-a97d53f3_space-between-medium space-between-medium",
          }, 
            React.createElement(_pub3D3Dc4Cd, {
              "class": "_cdda6ebb _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, 
              React.createElement(_pub3D3Dc4Cd_SetupCardHeader, {
                "class": "_ea118e4a _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "title": "Configure your data room",
                "description": "Be sure to get this stuff done",
              }, null),
              React.createElement(_pub3D3Dc4Cd_SetupCardItem, {
                "class": "_9d16bedc _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "active": true,
                "title": "Configure your data room to activate profile",
              }, 
                React.createElement(_pub74092D51, {
                  "class": "_467ef132 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "description": React.createElement("a", {
                    "className": "_3c4af050 _f29676e3 _pub-f29676e3 _pub-a97d53f3" + " " + "_f29676e3_text-link _pub-f29676e3_text-link _pub-a97d53f3_text-link text-link",
                    "href": "#",
                  }, 
                    "\n                  Why is this required?\n                "
                    
                  ),
                }, 
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_60e0a839 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                    "complete": true,
                  }, 
                    "\n                  Business overview deck\n                "
                    
                  ),
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_17e798af _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Financial models\n                "
                    
                  ),
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_8eeec915 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "Cap table"
                    
                  )
                )
              ),
              React.createElement(_pub3D3Dc4Cd_SetupCardItem, {
                "class": "_41fef66 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "title": "Increase the strength of yor profile (optional)",
              }, null),
              React.createElement(_pub3D3Dc4Cd_SetupCardItem, {
                "class": "_7318dff0 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "active": true,
                "title": "Something else with a smaller checklist",
              }, 
                React.createElement(_pub74092D51, {
                  "class": "_45fa255c _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "slim": true,
                  "subdued": true,
                  "description": "Please add any additional documents that you think will help paint a bigger picture of your company",
                }, 
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_1a20fb59 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Company projections\n                "
                    
                  ),
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_6d27cbcf _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Market analysis\n                "
                    
                  ),
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_f42e9a75 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Audited financials\n                "
                    
                  )
                )
              )
            ),
            React.createElement(_pubE434Fb6A, {
              "class": "_badd5e2d _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "padded": true,
            }, 
              React.createElement("div", {
                "className": "_ebd3e47d _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                "Non-Disclosure Agreement with Hum Capital"
                
              ),
              React.createElement("div", {
                "className": "_9cd4d4eb _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                React.createElement(_pub4435Fe58, {
                  "class": "_7b1ed882 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                }, 
                  "Signed"
                  
                )
              ),
              React.createElement("div", {
                "className": "_5dd8551 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                React.createElement(_pubDf2A042, {
                  "class": "_e5f564d _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "toggle": true,
                  "label": "Some checkbox",
                }, 
                  React.createElement(_pub14733275, {
                    "class": "_80f5b36a _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                    "checked": true,
                  }, null)
                ),
                React.createElement(_pub9Fdd11Dd, {
                  "class": "_975607f7 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "value": ".5",
                }, null)
              )
            ),
            React.createElement(_pubE434Fb6A, {
              "class": "_23d40f97 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "interactive": true,
              "hover": true,
            }, 
              React.createElement(_pub5448A231, {
                "class": "_9e926ab2 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "title": "Something",
                "header": true,
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id facilisi amet mattis cras egestas. Euismod odio fermentum.",
              }, null),
              React.createElement(_pub61E4905A, {
                "class": "_79b3b08 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "light": true,
                "vertical": true,
              }, null),
              React.createElement("div", {
                "className": "_709c0b9e _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                React.createElement("div", {
                  "className": "_483d46aa _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                }, 
                  React.createElement(_pub5448A231, {
                    "class": "_fb7ab0d1 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                    "helper": true,
                    "title": "Step 1",
                    "description": "State",
                  }, null),
                  React.createElement(_pub5448A231, {
                    "class": "_6273e16b _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                    "helper": true,
                    "title": "Step 2",
                    "description": "State",
                  }, null),
                  React.createElement(_pub5448A231, {
                    "class": "_1574d1fd _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                    "helper": true,
                    "title": "Step 3",
                    "description": "State",
                  }, null),
                  React.createElement(_pub5448A231, {
                    "class": "_8b10445e _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                    "helper": true,
                    "title": "Step 4",
                    "description": "State",
                  }, null)
                ),
                React.createElement(_pub9Fdd11Dd, {
                  "class": "_d1341710 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "value": ".5",
                }, null),
                React.createElement("div", {
                  "className": "_a6332786 _f29676e3 _pub-f29676e3 _pub-a97d53f3" + " " + "_f29676e3_text-helper _pub-f29676e3_text-helper _pub-a97d53f3_text-helper text-helper _f29676e3_color-text-subdued _pub-f29676e3_color-text-subdued _pub-a97d53f3_color-text-subdued color-text-subdued",
                }, 
                  "\n                Introduced on January 1, 2020\n              "
                  
                )
              )
            ),
            React.createElement(_pub9A961Ec6, {
              "class": "_54d33f01 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "label": "An accordion",
            }, null),
            React.createElement(_pub9A961Ec6, {
              "class": "_cab7aaa2 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "label": "I'm some content",
              "expanded": true,
            }, 
              React.createElement(_pub9A961Ec6_AccordionContent, {
                "class": "_ed182696 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                React.createElement(_pubB415F4B5, {
                  "class": "_c53b0d53 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "acceptedFileTypesLabel": "All formats accepted",
                }, null),
                React.createElement(_pub74092D51, {
                  "class": "_5c325ce9 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "slim": true,
                  "description": "Follow these instructions",
                }, 
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_aa4b71f4 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Models include balance sheet, profit and loss statement and\n                  cashflow\n                "
                    
                  ),
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_dd4c4162 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Segment data by month\n                "
                    
                  ),
                  React.createElement(_pub74092D51_ChecklistItem, {
                    "class": "_444510d8 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  }, 
                    "\n                  Include at least 12 months of data (if applicable)\n                "
                    
                  )
                )
              )
            ),
            React.createElement("Menu", {
              "className": "_bdb09a34 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, 
              React.createElement("MenuItem", {
                "className": "_ecda4ca1 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                "I'm menu item"
                
              ),
              React.createElement("MenuItem", {
                "className": "_9bdd7c37 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                "I'm menu item"
                
              ),
              React.createElement("MenuItem", {
                "className": "_2d42d8d _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "selected": true,
              }, 
                "I'm menu item"
                
              )
            )
          ),
          React.createElement(_pubFc2Ceb2_TabNavigationContainer, {
            "class": "_17a6a95e _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "horizontal": true,
          }, 
            React.createElement(_pubFc2Ceb2_TabNavigationItems, {
              "class": "_cc18048c _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "controls": React.createElement("span", {
                "className": "_1019082e _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                "\n              I'm some controls\n            "
                
              ),
            }, 
              React.createElement("div", {
                "className": "_d771a7fa _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              }, 
                React.createElement(_pubFc2Ceb2_TabNavigationItem, {
                  "class": "_e1cb90b1 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                }, 
                  "\n                I'm a tab\n              "
                  
                ),
                React.createElement(_pubFc2Ceb2_TabNavigationItem, {
                  "class": "_96cca027 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "active": true,
                }, 
                  "\n                I'm another tab\n              "
                  
                ),
                React.createElement(_pubFc2Ceb2_TabNavigationItem, {
                  "class": "_fc5f19d _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "hover": true,
                }, 
                  "\n                I'm a hovered tab\n              "
                  
                )
              )
            ),
            React.createElement("div", {
              "className": "_bb1f341a _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, 
              React.createElement(_pubDf2A042, {
                "class": "_d6b3cdcd _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "v3": true,
                "label": "Inactive",
              }, 
                React.createElement("TextInput", {
                  "className": "_dcabb901 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                }, null)
              ),
              React.createElement(_pubDf2A042, {
                "class": "_a1b4fd5b _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "v3": true,
                "active": true,
                "label": "Active",
              }, 
                React.createElement("TextInput", {
                  "className": "_dd69d336 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "active": true,
                }, null)
              ),
              React.createElement(_pubDf2A042, {
                "class": "_38bdace1 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "v3": true,
                "label": "Hover",
              }, 
                React.createElement("TextInput", {
                  "className": "_df2f6d6f _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "mode": "password",
                  "password": true,
                  "hover": true,
                }, null)
              ),
              React.createElement(_pubDf2A042, {
                "class": "_4fba9c77 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                "v3": true,
                "label": "disabled",
              }, 
                React.createElement("TextInput", {
                  "className": "_deed0758 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
                  "mode": "password",
                  "password": true,
                  "hover": true,
                  "disabled": true,
                }, null)
              )
            )
          ),
          React.createElement(_pub824B8933_Box, {
            "class": "_8eaff8e4 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "vertical": true,
            "gap": "32px",
          }, 
            React.createElement("TablePreview", {
              "className": "_b9598a43 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            }, null),
            React.createElement("TablePreview", {
              "className": "_2050dbf9 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "secondary": true,
            }, null),
            React.createElement(_pub2F5Be189, {
              "class": "_5757eb6f _f29676e3 _pub-f29676e3 _pub-a97d53f3",
              "title": "Some link accordion",
              "active": true,
            }, 
              "\n            Some content\n          "
              
            )
          )
        )
      )
    ),
    React.createElement("ModalPreview", {
      "className": "_d33c7caa _f29676e3 _pub-f29676e3 _pub-a97d53f3",
      "visible": props["showModal"],
      "side": true,
      "padded": true,
    }, 
      "I'm a modal!"
      
    )
  )
}));

var FullSplashPagePreview = React.memo(React.forwardRef(function FullSplashPagePreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_99baf92d _f29676e3 _pub-f29676e3 _pub-a97d53f3" + (props["branded"] ? " " + "_f29676e3_branded _pub-f29676e3_branded _pub-a97d53f3_branded branded" : "") + (props["humDark"] ? " " + "_f29676e3_hum _pub-f29676e3_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_f29676e3_dark _pub-f29676e3_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
    "flex": true,
  }, 
    React.createElement(_pubFc2Ceb2_TabNavigationContainer, {
      "class": "_d466c1c7 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
    }, 
      React.createElement(_pubFc2Ceb2_TabNavigationItems, {
        "class": "_2ff21f75 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
      }, 
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_986ad708 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          "active": true,
        }, 
          "\n        item\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_ef6de79e _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        }, 
          "another"
          
        )
      ),
      React.createElement(_pub8D610C4, {
        "class": "_58f52fe3 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
        "spaceLeft": true,
      }, 
        React.createElement(_pubA8875Cb4, {
          "class": "_99a8bd3f _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          "padded": true,
        }, 
          React.createElement("BreadcrumbItem", {
            "className": "_aa240419 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          }, 
            "Crumb"
            
          ),
          React.createElement("BreadcrumbItem", {
            "className": "_dd23348f _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          }, 
            "Crumb"
            
          )
        ),
        React.createElement(_pub8A05Fd1A, {
          "class": "_eeaf8da9 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
          "illustration": React.createElement("img", {
            "className": "_5b3f6be0 _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "src": getDefault(require("@hum/assets/confetti.png")),
          }, null),
          "title": "Congratulations!",
          "description": "Continue to the Loan Documents section to upload a Term Sheet",
          "controls": React.createElement(_pub2Dbb4493, {
            "class": "_c2363a5a _f29676e3 _pub-f29676e3 _pub-a97d53f3",
            "primary": true,
          }, 
            "Continue to Loan Documents"
            
          ),
        }, null)
      )
    )
  )
}));
export { FullSplashPagePreview };


