import React from "react";
import "./Spinner.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Loading = React.memo(React.forwardRef(function Loading(props, ref) {
  return React.createElement("svg", {
    "className": "_a81d6328 _f3e3feb0 _pub-f3e3feb0",
    "ref": ref,
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "16",
    "height": "16",
    "viewBox": "0 0 16 16",
  }, 
    React.createElement("g", {
      "className": "_cecc4ee9 _f3e3feb0 _pub-f3e3feb0",
      "fill": "#FFF",
      "fillRule": "nonzero",
    }, 
      React.createElement("path", {
        "className": "_59f29242 _f3e3feb0 _pub-f3e3feb0",
        "fillOpacity": ".313",
        "d": "M8 15.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm0-1.667A5.833 5.833 0 1 0 8 2.167a5.833 5.833 0 0 0 0 11.666z",
      }, null),
      React.createElement("path", {
        "className": "_2ef5a2d4 _f3e3feb0 _pub-f3e3feb0",
        "fillOpacity": ".6",
        "d": "M8 15.5v-1.667a5.833 5.833 0 0 0 .723-11.622L8.927.557A7.501 7.501 0 0 1 8 15.5z",
      }, null)
    )
  )
}));

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_417ec61d _f3e3feb0 _pub-f3e3feb0" + " " + "_f3e3feb0_throbber _pub-f3e3feb0_throbber throbber" + (props["immediate"] ? " " + "_f3e3feb0_throbber--immediate _pub-f3e3feb0_throbber--immediate throbber--immediate" : ""),
    "ref": ref,
    "style": castStyle("--speed: " + props["speed"] + ";"),
  }, 
    React.createElement(Loading, {
      "class": "_ca41325b",
    }, null)
  )
}));
export default $$Default;


