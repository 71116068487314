import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pub9F4190D6, {XeroIcon as _pub9F4190D6_XeroIcon} from "./icons.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./XeroSignInButton.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("Button", {
    "className": "_9db493e1 _399dec7b _pub-399dec7b _pub-a97d53f3" + (props["hover"] ? " " + "_399dec7b_hover _pub-399dec7b_hover _pub-a97d53f3_hover hover" : ""),
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    React.createElement(_pub9F4190D6_XeroIcon, {
      "class": "_1e4ed5cd _399dec7b _pub-399dec7b _pub-a97d53f3",
    }, null),
    React.createElement("span", {
      "className": "_87478477 _399dec7b _pub-399dec7b _pub-a97d53f3" + " " + "_399dec7b_text-body-small _pub-399dec7b_text-body-small _pub-a97d53f3_text-body-small text-body-small",
    }, 
      props["children"]
    )
  )
}));
export default $$Default;

var AuthButtonsPreview = React.memo(React.forwardRef(function AuthButtonsPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_73baf2cd _399dec7b _pub-399dec7b _pub-a97d53f3",
    "ref": ref,
    "padded": true,
  }, 
    React.createElement($$Default, {
      "class": "_1dca01a3",
      "hover": true,
    }, 
      "Sign in with Xero"
      
    ),
    React.createElement("br", {
      "className": "_84c35019 _399dec7b _pub-399dec7b _pub-a97d53f3",
    }, null)
  )
}));
export { AuthButtonsPreview };


