import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pubDf2A042 from "./Field.pc";
import "./Toggle.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_15939b91 _5ccf593c _pub-5ccf593c _pub-a97d53f3" + " " + "_5ccf593c___checkbox-input _pub-5ccf593c___checkbox-input _pub-a97d53f3___checkbox-input __checkbox-input" + (props["on"] ? " " + "_5ccf593c_on _pub-5ccf593c_on _pub-a97d53f3_on on" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": props["testId"],
    "tabIndex": "0",
    "aria-roledescription": "button",
  }, 
    React.createElement("div", {
      "className": "_3faaac2e _5ccf593c _pub-5ccf593c _pub-a97d53f3",
    }, null),
    React.createElement("div", {
      "className": "_a6a3fd94 _5ccf593c _pub-5ccf593c _pub-a97d53f3",
    }, null),
    React.createElement("div", {
      "className": "_d1a4cd02 _5ccf593c _pub-5ccf593c _pub-a97d53f3",
    }, null)
  )
}));
export default $$Default;

var __DEPRECATED__ToggleField = React.memo(React.forwardRef(function __DEPRECATED__ToggleField(props, ref) {
  return React.createElement("div", {
    "className": "_fb9dfabd _5ccf593c _pub-5ccf593c _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": props["testId"],
  }, 
    props["children"],
    React.createElement("label", {
      "className": "_a52729fa _5ccf593c _pub-5ccf593c _pub-a97d53f3",
    }, 
      props["label"]
    )
  )
}));
export { __DEPRECATED__ToggleField };

var TogglePreview = React.memo(React.forwardRef(function TogglePreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_1c25d7ba _5ccf593c _pub-5ccf593c _pub-a97d53f3" + " " + "v3",
    "ref": ref,
    "padded": true,
  }, 
    React.createElement($$Default, {
      "class": "_41b765dc",
    }, null),
    React.createElement($$Default, {
      "class": "_36b0554a",
      "on": true,
    }, null),
    React.createElement("div", {
      "className": "_afb904f0 _5ccf593c _pub-5ccf593c _pub-a97d53f3",
    }, 
      React.createElement($$Default, {
        "class": "_54fbf2a1",
      }, null)
    ),
    React.createElement("div", {
      "className": "_d8be3466 _5ccf593c _pub-5ccf593c _pub-a97d53f3",
    }, 
      React.createElement(_pubDf2A042, {
        "class": "_55399896 _5ccf593c _pub-5ccf593c _pub-a97d53f3",
        "toggle": true,
        "label": "Toggle this on",
      }, 
        React.createElement($$Default, {
          "class": "_e9e22bf9",
          "on": true,
        }, null)
      )
    )
  )
}));
export { TogglePreview };


