/* eslint-disable no-underscore-dangle */
import React, { useState, StyleHTMLAttributes } from 'react';
import { Modal } from '@hum/design-system';
import { Button, ButtonType } from '@hum/ui-library';

type ConfirmationModalProps = StyleHTMLAttributes<HTMLDivElement> & {
  title?: string;
  message?: string;
  confirm?: string;
  cancel?: string | false;
  danger?: boolean;
  noScroll?: boolean;
  onCancel?: () => Promise<unknown> | void;
  onConfirm?: () => Promise<unknown> | void;
};

const wrappedInPromise = (
  setLoading: (loading: boolean) => void,
  handler: (...args: unknown[]) => Promise<unknown> | void
) => async (...args: unknown[]) => {
  const promise = handler(...args);

  if (promise && typeof promise.then !== 'undefined') {
    setLoading(true);

    try {
      await promise;
    } catch (err) {
      // ignore error
    }

    setLoading(false);
  }
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  // eslint-disable-next-line no-shadow
  confirm = 'Confirm',
  cancel = 'Cancel',
  danger,
  message,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const loading = loadingCancel || loadingConfirm;

  const _onCancel = wrappedInPromise(setLoadingCancel, onCancel);
  const _onConfirm = wrappedInPromise(setLoadingConfirm, onConfirm);

  return (
    <Modal
      header={typeof title !== 'undefined' ? title : 'Confirm'}
      onClose={loading ? undefined : _onCancel}
      testId="confirmation-modal"
      footer={
        <>
          {cancel === false ? null : (
            <Button
              type={ButtonType.SECONDARY}
              onClick={_onCancel}
              disabled={loading}
              loading={loadingCancel}
            >
              {cancel}
            </Button>
          )}

          <Button
            type={danger ? ButtonType.DESTRUCTIVE : ButtonType.PRIMARY}
            onClick={_onConfirm}
            disabled={loading}
            loading={loadingConfirm}
          >
            {confirm}
          </Button>
        </>
      }
    >
      {message}
    </Modal>
  );
};

export { ConfirmationModal };
