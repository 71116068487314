import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import "./utils.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement("div", {
    "className": "_60590672 _bab5a68f _pub-bab5a68f _pub-a97d53f3" + " " + "_bab5a68f_text-body-medium _pub-bab5a68f_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + " " + " " + " " + "_bab5a68f_v3 _pub-bab5a68f_v3 _pub-a97d53f3_v3 v3" + " " + " " + " " + "_bab5a68f_hum _pub-bab5a68f_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_bab5a68f_dark _pub-bab5a68f_dark _pub-a97d53f3_dark dark" + (props["padded"] ? " " + "_bab5a68f_padded _pub-bab5a68f_padded _pub-a97d53f3_padded padded" : "") + (props["flex"] ? " " + "_bab5a68f_flex _pub-bab5a68f_flex _pub-a97d53f3_flex flex" : "") + (props["spaced"] ? " " + "_bab5a68f_spaced _pub-bab5a68f_spaced _pub-a97d53f3_spaced spaced" : "") + (props["lighter"] ? " " + "_bab5a68f_lighter _pub-bab5a68f_lighter _pub-a97d53f3_lighter lighter" : ""),
    "ref": ref,
    "data-pc-label": "Preview",
  }, 
    props["children"]
  )
}));
export { Preview };

var Note = React.memo(React.forwardRef(function Note(props, ref) {
  return React.createElement("div", {
    "className": "_893aa347 _bab5a68f _pub-bab5a68f _pub-a97d53f3" + " " + "_bab5a68f_text-body-medium _pub-bab5a68f_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Note };


