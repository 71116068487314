import { isValidElement } from 'react';
import { FormikHelpers } from 'formik';

const FORM_ERROR = 'global-form-error-key';

type ErrorHandledSubmit<FormFields> = (
  values: FormFields,
  actions: FormikHelpers<FormFields>
) => Error | string | object | void;

/**
 * Normalizes submission errors into field errors or a global form error.
 */
const handleFormSubmitErrors = <FormFields extends {}>(
  fn: ErrorHandledSubmit<FormFields>
) => async (values: FormFields, actions: FormikHelpers<FormFields>) => {
  let error;

  const { setStatus, setErrors, setSubmitting } = actions;

  // Reset Formik state.
  setStatus(null);

  try {
    error = await fn(values, actions);
  } catch (thrown) {
    error = thrown;
  }

  setSubmitting(false);
  // no return nor error means success, in Formik language.
  if (!error) return;

  if (error instanceof Error) {
    setStatus(error.message);
  } else if (isValidElement(error)) {
    // react elements might be thrown to show as error.
    setStatus(error);
  } else if (typeof error === 'object') {
    // extract global vs field specific errors.
    const { [FORM_ERROR]: submissionError, ...errors } = error;
    setErrors(errors);
    setStatus(submissionError);
  } else {
    // always fallback to a default error handling.
    throw new Error('Could not handle form submission error');
  }
};

export { handleFormSubmitErrors, FORM_ERROR };
