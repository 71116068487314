import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./FileIcon.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Default = React.memo(React.forwardRef(function Default(props, ref) {
  return React.createElement("svg", {
    "className": "_ce3d3283 _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_9f532c89 _626b986 _pub-626b986",
      "d": "m13 9h5.5l-5.5-5.5v5.5m-7-7h8l6 6v12a2 2 0 0 1 -2 2h-12c-1.11 0-2-.9-2-2v-16c0-1.11.89-2 2-2m5 2h-5v16h5 7v-9h-7v-7z",
      "fill": "#42a5f5",
    }, null)
  )
}));
export { Default };

var Word = React.memo(React.forwardRef(function Word(props, ref) {
  return React.createElement("svg", {
    "className": "_275e97b6 _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_9bde503b _626b986 _pub-626b986",
      "d": "m6 2h8l6 6v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-16a2 2 0 0 1 2 -2m7 1.5v5.5h5.5l-5.5-5.5m-6 9.5l1.5 7h2l1.5-3 1.5 3h2l1.5-7h1v-2h-4v2h1l-.9 4.2-1.1-2.2h-2l-1.1 2.2-.9-4.2h1v-2h-4v2h1z",
      "fill": "#01579b",
    }, null)
  )
}));
export { Word };

var Excel = React.memo(React.forwardRef(function Excel(props, ref) {
  return React.createElement("svg", {
    "className": "_c950f69a _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_985a8455 _626b986 _pub-626b986",
      "d": "m6 2h8l6 6v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-16a2 2 0 0 1 2 -2m7 1.5v5.5h5.5l-5.5-5.5m4 7.5h-4v2h1l-2 1.67-2-1.67h1v-2h-4v2h1l3 2.5-3 2.5h-1v2h4v-2h-1l2-1.67 2 1.67h-1v2h4v-2h-1l-3-2.5 3-2.5h1v-2z",
      "fill": "#8bc34a",
    }, null)
  )
}));
export { Excel };

var PDF = React.memo(React.forwardRef(function PDF(props, ref) {
  return React.createElement("svg", {
    "className": "_2ee8db9d _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_92c4a95f _626b986 _pub-626b986",
      "d": "m13 9h5.5l-5.5-5.5v5.5m-7-7h8l6 6v12a2 2 0 0 1 -2 2h-12c-1.11 0-2-.9-2-2v-16a2 2 0 0 1 2 -2m4.93 10.44c.41.9.93 1.64 1.53 2.15l.41.32c-.87.16-2.07.44-3.34.93l-.11.04.5-1.04c.45-.87.78-1.66 1.01-2.4m6.48 3.81c.18-.18.27-.41.28-.66.03-.2-.02-.39-.12-.55-.29-.47-1.04-.69-2.28-.69l-1.29.07-.87-.58c-.63-.52-1.2-1.43-1.6-2.56l.04-.14c.33-1.33.64-2.94-.02-3.6-.16-.16-.38-.24-.61-.24h-.24c-.37 0-.7.39-.79.77-.37 1.33-.15 2.06.22 3.27v.01c-.25.88-.57 1.9-1.08 2.93l-.96 1.8-.89.49c-1.2.75-1.77 1.59-1.88 2.12-.04.19-.02.36.05.54l.03.05.48.31.44.11c.81 0 1.73-.95 2.97-3.07l.18-.07c1.03-.33 2.31-.56 4.03-.75 1.03.51 2.24.74 3 .74.44 0 .74-.11.91-.3m-.41-.71l.09.11c-.01.1-.04.11-.09.13h-.04l-.19.02c-.46 0-1.17-.19-1.9-.51.09-.1.13-.1.23-.1 1.4 0 1.8.25 1.9.35m-9.17 1.46c-.65 1.19-1.24 1.85-1.69 2 .05-.38.5-1.04 1.21-1.69l.48-.31m3.02-6.91c-.23-.9-.24-1.63-.07-2.05l.07-.12.15.05c.17.24.19.56.09 1.1l-.03.16-.16.82z",
      "fill": "#f44336",
    }, null)
  )
}));
export { PDF };

var Powerpoint = React.memo(React.forwardRef(function Powerpoint(props, ref) {
  return React.createElement("svg", {
    "className": "_ab89634a _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_99074458 _626b986 _pub-626b986",
      "d": "m6 2h8l6 6v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-16a2 2 0 0 1 2 -2m7 1.5v5.5h5.5l-5.5-5.5m-5 7.5v2h1v6h-1v1h4v-1h-1v-2h2a3 3 0 0 0 3 -3 3 3 0 0 0 -3 -3h-5m5 2a1 1 0 0 1 1 1 1 1 0 0 1 -1 1h-2v-2h2z",
      "fill": "#d14524",
    }, null)
  )
}));
export { Powerpoint };

var Picture = React.memo(React.forwardRef(function Picture(props, ref) {
  return React.createElement("svg", {
    "className": "_45870266 _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_9a839036 _626b986 _pub-626b986",
      "d": "m12.976 9.0724h5.3677l-5.3677-5.3677v5.3677m-6.8316-6.8316h7.8075l5.8557 5.8557v11.711a1.9519 1.9519 0 0 1 -1.9519 1.9519h-11.711c-1.0833 0-1.9519-.87835-1.9519-1.9519v-15.615c0-1.0833.86859-1.9519 1.9519-1.9519m0 17.567h11.711v-7.8075l-3.9038 3.9038-1.9519-1.9519-5.8557 5.8557m1.9519-10.735a1.9519 1.9519 0 0 0 -1.9519 1.9519 1.9519 1.9519 0 0 0 1.9519 1.9519 1.9519 1.9519 0 0 0 1.9519 -1.9519 1.9519 1.9519 0 0 0 -1.9519 -1.9519z",
      "fill": "#26a69a",
      "strokeWidth": "0.97594",
    }, null)
  )
}));
export { Picture };

var FolderIcon = React.memo(React.forwardRef(function FolderIcon(props, ref) {
  return React.createElement("svg", {
    "className": "_ace4a753 _626b986 _pub-626b986" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "viewBox": "0 0 24 24",
    "size": props["size"],
    "width": props["size"],
    "height": props["size"],
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_9e0eec84 _626b986 _pub-626b986",
      "d": "M19 20H4c-1.11 0-2-.9-2-2V6c0-1.11.89-2 2-2h6l2 2h7a2 2 0 0 1 2 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5z",
      "fill": "#90a4ae",
    }, null)
  )
}));
export { FolderIcon };


