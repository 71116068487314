import { useEffect, useRef } from 'react';
import { useFormik, FormikConfig } from 'formik';
import { handleFormSubmitErrors } from '../etc/errors';

type UseFormConfig = {
  handleErrors?: boolean;
  updateOnInitialChange?: boolean;
};

/**
 * Optimized version of useFormik to attach `.field` helper.
 */
function useForm<Values extends {}>(
  options: FormikConfig<Values>,
  config: UseFormConfig = {}
): any {
  const { handleErrors = true, updateOnInitialChange = true } = config;

  const form = useFormik({
    ...options,
    // Default form submit error handler is added unless opted-out.
    onSubmit: handleErrors
      ? handleFormSubmitErrors<Values>(options.onSubmit)
      : options.onSubmit,
  });

  // Every time the initialValues change, it's because we got fresh data
  // from the backend. When that happens, we need to reset the form
  // values to reinitialize dirty/pristine states.

  // We cannot rely on the `form` variable equality, thought, because
  // Formik does not guarantee this to be immutable - meaning it'll
  // often unnecessarily be a fresh value/reference.

  // We also should avoid the false-positive of inequality check
  // upon the first tun.

  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;
    } else if (updateOnInitialChange) {
      form.resetForm({ values: options.initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.initialValues]);

  return form;
}

export { useForm };
