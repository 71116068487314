import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

type MenuItem = {
  title: string;
  testid?: string;
};

const StyledNavSidebarWrapper = styled.div`
  width: var(--cobranding_layout_left_column_width);
  padding: var(--spacing-5);
  box-sizing: border-box;

  &.narrow {
    width: 320px;
  }

  &.lighter-bg {
    background: var(--gray-gray-70);
  }

  h1.title {
    display: flex;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    padding: var(--spacing-5);
    cursor: default;
    align-items: center;
    @include typography.family-title;
    button {
      margin-right: 16px;
    }
  }
`;
export const StyledNavSidebar = styled.nav`
  --cobrand--menu-item-background-color: #1F2630;

  display: block;
  & > a {
    font-family: var(--font-family-body);
    display: block;
    padding: var(--spacing-5);
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: var(--color-text-primary);
    cursor: pointer;
    text-decoration: none;
    transition: background-color 120ms ease-in-out;
    user-select: none;
    &:hover {
      background-color: var(--cobrand--menu-item-background-color, var(--color-black-300));
    }

    &.active {
      background-color: var(--cobrand--menu-item-background-color, var(--background-color-elevation));
      color: var(--color-text-white);
    }
    & > :global(span) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
`;

export const NavSidebarWrapper = ({
  dataTestId,
  className,
  title,
  children,
}: {
  dataTestId?: string;
  className?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <StyledNavSidebarWrapper data-testid={dataTestId} className={className}>
      {title && <h1 className="title">{title} </h1>}
      {children}
    </StyledNavSidebarWrapper>
  );
};

export const NavSidebar = ({ items }: { items: [string, MenuItem][] }) => {
  return (
    <NavSidebarWrapper title={null}>
      <StyledNavSidebar>
        {items.map(([path, item]) => (
          <NavLink
            key={path}
            to={path + window.location.search}
            activeClassName="active"
            data-testid={item.testid}
          >
            {item.title}
          </NavLink>
        ))}
      </StyledNavSidebar>
    </NavSidebarWrapper>
  );
};
