import React, { useState } from 'react';
import * as styles from './SetupCard.pc';

type SetupCardProps = {
  children: any;
};

export const SetupCardHeader = styles.SetupCardHeader;

export const SetupCard = ({ children }: SetupCardProps) => {
  return <styles.default>{children}</styles.default>;
};

export const SetupCardButton = styles.ButtonCardItem;

type SetupCardItemProps = {
  children?: any;
  active?: boolean;
  ignoreClick?: boolean;
  lockReason?: any;
  title: React.ReactNode;
  subtitle: string;
  iconText?: React.ReactNode;
  iconButton?: any;
  complete?: any;
  v2?: any;
  onClick?: any;
  onDismissableClick?: any;
  transparent?: any;
  hidden?: boolean;
  dismissable?: boolean;
  show?: boolean;

  testId?: any;
};

export const SetupCardItem = ({
  title,
  subtitle,
  active: activeOverride,
  children,
  complete,
  iconText,
  iconButton,
  transparent,
  lockReason,
  hidden,
  v2,
  dismissable,
  ignoreClick,
  show,
  testId,
  onClick: onClickOverride,
  onDismissableClick,
}: SetupCardItemProps) => {
  const [active, setActive] = useState(false);
  const onClick = () => {
    if (!lockReason && ignoreClick !== true) {
      setActive(!active);
    }
    onClickOverride && onClickOverride();
  };

  return (
    <styles.SetupCardItem
      onClick={onClick}
      iconText={iconText}
      iconButton={iconButton}
      active={activeOverride || active}
      title={title}
      subtitle={subtitle}
      transparent={transparent}
      complete={complete}
      lockedReason={lockReason}
      testId={testId}
      hidden={hidden}
      dismissable={dismissable}
      show={show}
      v2={v2}
      onDismissableClick={onDismissableClick}
    >
      {children}
    </styles.SetupCardItem>
  );
};
