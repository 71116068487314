import React from "react";
import _pub8C547Ae7 from "./Select.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pubA97D53F3 from "./atoms.pc";
import _pub2Dbb4493 from "./Button.pc";
import "./ButtonGroup.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_4217ac63 _1170a9b6 _pub-1170a9b6",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_ac19cd4f _1170a9b6 _pub-1170a9b6" + " " + "_1170a9b6_v3 _pub-1170a9b6_v3 v3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Container };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Container, {
    "class": "_4ba1e048",
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_df801468 _1170a9b6 _pub-1170a9b6",
    }, 
      "2"
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_a88724fe _1170a9b6 _pub-1170a9b6",
      "active": true,
    }, 
      "3"
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_318e7544 _1170a9b6 _pub-1170a9b6",
    }, 
      "4"
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_468945d2 _1170a9b6 _pub-1170a9b6",
    }, 
      "5"
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_d8edd071 _1170a9b6 _pub-1170a9b6",
    }, 
      "6"
      
    )
  )
}));
export { Preview };

var PreviewOuter = React.memo(React.forwardRef(function PreviewOuter(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_33490dfd _1170a9b6 _pub-1170a9b6",
    "ref": ref,
    "padded": true,
    "humDark": props["humDark"],
  }, 
    React.createElement(Preview, {
      "class": "_9889a8b8",
    }, null)
  )
}));
export { PreviewOuter };


