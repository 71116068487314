import {
  TabNavigationContainer,
  TabNavigationItem,
  TabNavigationItems,
} from './TabNavigation.pc';

import React, { memo, useState } from 'react';

import kebabCase from 'lodash/kebabCase';
import { useQuery } from './hooks/useQuery';

export type TabNavigationProps = {
  // TODO
  queryName?: string;
  id?: string;

  defaultTabId?: string;
  slim?: boolean;
  horizontal?: boolean;
  noPadding?: boolean;
  paddingBottom?: boolean;

  controls?: any;
  'data-testid'?: string;

  tabHeader?: any;
  children: any;
};

export { TabNavigationItem, TabNavigationItems };

export const TabNavigator = memo((props: TabNavigationProps) => {
  const {
    tabHeader,
    slim = false,
    horizontal = false,
    paddingBottom = false,
    controls,
    noPadding,
    'data-testid': testId,
  } = props;
  const { activeChild, activeLabelIndex, tabs } = useTabNavigator(props);

  return (
    <TabNavigationContainer
      slim={slim && !horizontal}
      horizontal={horizontal}
      noPadding={noPadding}
      paddingBottom={paddingBottom}
    >
      <TabNavigationItems testId={testId} controls={controls}>
        {tabHeader}
        {tabs.map(({ label, testId, activateTab }, i) => {
          return (
            <TabNavigationItem
              key={i}
              active={i === activeLabelIndex}
              testId={testId || `${kebabCase(String(label))}-tab`}
              onClick={() => {
                activateTab();
              }}
            >
              {typeof label === 'function'
                ? label({
                    activateTab,
                  })
                : label}
            </TabNavigationItem>
          );
        })}
      </TabNavigationItems>
      {activeChild}
    </TabNavigationContainer>
  );
});

/**
 * Generalized hook that can be used to skin other tab components.
 *
 * TODO: possibly consider hooking this up to HTTP location
 */

const useTabNavigator = ({ children, id }: TabNavigationProps) => {
  const [internalLabelIndex, setInternalLabelIndex] = useState(0);
  const elementChildren = React.Children.map(children, (child) =>
    React.isValidElement(child) ? child : null
  ).filter(Boolean) as React.ReactElement[];

  const [query, updateQuery] = useQuery();

  const activeLabelIndex =
    id && query[id] != null ? Number(query[id]) : internalLabelIndex;

  const onTabActivate = (index: number, query = {}) => {
    if (id) {
      updateQuery({ ...query, [id]: index });
    } else {
      setInternalLabelIndex(index);
    }
  };

  const tabs = elementChildren.map(
    (child: React.ReactElement<TabProps>, index: number) => {
      return {
        label: child.props.label,
        testId: child.props['data-testid'],
        activateTab: (query: Record<string, string> = {}) => {
          onTabActivate(index, query);
        },
      };
    }
  );
  return {
    activeChild: elementChildren[activeLabelIndex],
    tabs,
    activeLabelIndex,
  };
};

export type TabLabelProps = {
  activateTab: (params?: {}) => void;
};

export type TabProps = {
  label: string | React.ReactNode | ((props: TabLabelProps) => React.ReactNode);
  children?: any;

  // TODO - bind to location
  path?: string;
};

export const Tab = ({ children }: TabProps) => {
  return <>{children}</>;
};
