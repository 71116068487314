import { useState, useEffect } from 'react';

export const TALL_MEDIA_QUERY = `screen and (max-width: 830px)`;

// https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/
export function useMediaQuery(query: any) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // won't work for tests
    const media = window.matchMedia && window.matchMedia(query);
    if (!media) {
      return;
    }
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}
