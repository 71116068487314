/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosResponse } from 'axios';
import { composeHandlers } from './utils';
import { API_SIGNUP_URL } from '@hum/icm-app/src/backend/api/auth';

type NetworkError = AxiosError & { response: AxiosResponse };

export type ErrorHandler = (error: NetworkError) => any;

/**
 * Composes multiple error handlers into a single, ordered handling mechanism.
 *
 * @param handlers A list of error handlers.
 */
const composeErrorHandlers = (...handlers: ErrorHandler[]) => {
  const handle = composeHandlers(...handlers);

  return async (error: Error) => {
    const resolved = await handle(error);

    // Return resolved value, if any.
    if (resolved !== undefined && resolved !== error) {
      return resolved;
    }

    // Rethrow unhandled error.
    sendError(error);
  };
};

/**
 * Handles failed HTTP requests.
 *
 * i.e.: backend not available.
 */
const handleRejectedNetworkError = (error: NetworkError) => {
  if (!error.response) {
    sendError(error);
  }
};

/**
 * Handles HTTP error responses with a { data: { message } } structure.
 *
 * i.e.: 400 :: { data: { message: 'Invalid credentials' } }
 */

const handleRejectedErrorMessage = (v: NetworkError) => {
  const message = v?.response?.data?.message;
  if (message) {
    sendError(new Error(message));
  }
};
/**
 * Handles HTTP 404 responses with as a safe empty response.
 *
 * i.e.: 404 :: { data: { ? } }
 */

const handleRejectedNotFound = (v: NetworkError) => {
  const statusCode = v?.response?.status;
  if (statusCode === 404) {
    return null;
  }
};

/**
 * Handles HTTP error responses with a { data: { error } } structure.
 *
 * i.e.: 400 :: { data: { error: 'Invalid credentials' } }
 */
const handleRejectedError = ({ response }: NetworkError) => {
  if (response && response.data.error) {
    sendError(new Error(response.data.error));
  }
};

/**
 * Handles HTTP error responses with a { data: { code, description, name } } structure.
 *
 * i.e.: 401 :: { code: 401, name: 'Unauthorized', description: 'The server could not verify...' }
 */

const handleRejectedDescription = (v: any) => {
  const data: {
    code: number;
    name: string;
    description: string | { message: string };
  } = v?.response?.data;

  if (data && data.code) {
    const messages = [
      // in case description is the error string itself
      data.description,
      // in case description is an object containing the message
      typeof data.description === 'object' ? data.description.message : null,
      // in case we have a name
      data.name,
      // fallback
      'Unexpected error',
    ];

    sendError(
      new Error(messages.find((option) => typeof option === 'string') as string)
    );
  }
};

/**
 * Default prioritize error handler.
 */
const handleErrors = composeErrorHandlers(
  handleRejectedNetworkError,
  handleRejectedNotFound,
  handleRejectedError,
  handleRejectedErrorMessage,
  handleRejectedDescription
);

/**
 * Handles HTTP success messages containing an error.
 *
 * i.e.: 200 :: { data: { error: 'Error validating against schema' } }
 */
const handleRespondedError = ({ data }: { data: any }) => {
  if (data?.error) {
    sendError(new Error(data.error));
  }
};

/**
 * Handles HTTP success messages containing and error in array structure.
 *
 * i.e.: 200 :: { data: [{ message: 'user taken' }, 400] }
 */
const handleRespondedErrorArray = ({ data }: { data: any }) => {
  if (data?.[0]?.message && data[1] && data[1] === 400) {
    sendError(new Error(data[0].message));
  }
};

/**
 * Default prioritize response with error as data (200) handler.
 */
const handleRespondedErrors = composeHandlers(
  handleRespondedError,
  handleRespondedErrorArray
);

export {
  composeErrorHandlers,
  handleErrors,
  handleRejectedNetworkError,
  handleRejectedNotFound,
  handleRejectedError,
  handleRejectedErrorMessage,
  handleRejectedDescription,
  handleRespondedErrors,
  handleRespondedError,
  handleRespondedErrorArray,
};

// user flow error message strings
const INCORRECT_CREDENTIALS_API_ERROR = 'Incorrect email or password';
const INCORRECT_CREDENTIALS_ALT_API_ERROR =
  'Error: Incorrect email or password';
const MISSING_BUSINESS_TYPE_FOR_SYNDICATION_API_ERROR =
  'Error: the company needs business type for syndication information';
const EXPIRED_CREDENTIALS_API_ERROR = `The server could not verify that you are authorized to access the URL requested. You either supplied the wrong credentials (e.g. a bad password), or your browser doesn't understand how to supply the credentials required.`;
const NOTICE_PREVIOUSLY_SHOWN_API_ERROR = 'Notice was previously shown';
const NO_CHARTS_PRESENT_API_ERROR = 'Error: No charts present';
const NO_CHARTS_PRESENT_API_ERROR_ALT = 'No charts present';
const REQUEST_FAILED = 'Request failed with status code 400';
const FAILED_TO_QUERY_ANALYTICS_SERVER_FOR_NON_EXISTING_COMPANY =
  'This company does not exist in our database';
const RESENT_INVITE = 'You must accept your invite.';

const toAxiosError = (error: Error) => {
  return error as AxiosError<Error> & AxiosResponse;
};

const sendError = (error: Error | AxiosError<Error>) => {
  () => {
    // Ignore user flow errors, these are taken care of by the UI or are expected to happen
    if (
      error.message === INCORRECT_CREDENTIALS_API_ERROR ||
      error.message === INCORRECT_CREDENTIALS_ALT_API_ERROR ||
      error.message === EXPIRED_CREDENTIALS_API_ERROR ||
      error.message === NOTICE_PREVIOUSLY_SHOWN_API_ERROR ||
      error.message === NO_CHARTS_PRESENT_API_ERROR ||
      error.message === NO_CHARTS_PRESENT_API_ERROR_ALT ||
      error.message === MISSING_BUSINESS_TYPE_FOR_SYNDICATION_API_ERROR ||
      error.message.includes(RESENT_INVITE) ||
      error.message.includes(
        FAILED_TO_QUERY_ANALYTICS_SERVER_FOR_NON_EXISTING_COMPANY
      )
    )
      return;

    if (toAxiosError(error).isAxiosError) {
      // Hide 400 errors if they happened when requesting to signup v5
      if (
        error.message === REQUEST_FAILED &&
        toAxiosError(error).config.url === API_SIGNUP_URL
      ) {
        return;
      }
    }
  };

  throw error;
};
