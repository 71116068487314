import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import "./LinkAccordion.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_ba437e89 _2f5be189 _pub-2f5be189 _pub-a97d53f3" + (props["active"] ? " " + "_2f5be189_active _pub-2f5be189_active _pub-a97d53f3_active active" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_1ad6bdc6 _2f5be189 _pub-2f5be189 _pub-a97d53f3" + (props["active"] ? " " + "_2f5be189_active _pub-2f5be189_active _pub-a97d53f3_active active" : ""),
    }, 
      React.createElement("div", {
        "className": "_185ded05 _2f5be189 _pub-2f5be189 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_93e13af7 _2f5be189 _pub-2f5be189 _pub-a97d53f3",
          "onClick": props["onClick"],
        }, 
          React.createElement("i", {
            "className": "_addc7503 _2f5be189 _pub-2f5be189 _pub-a97d53f3",
          }, null),
          props["title"]
        ),
        props["active"]&&React.createElement("div", {
          "className": "_fcbc0a67 _2f5be189 _pub-2f5be189 _pub-a97d53f3",
        }, 
          props["button"]
        )
      )
    ),
    React.createElement("div", {
      "className": "_6dd18d50 _2f5be189 _pub-2f5be189 _pub-a97d53f3",
    }, 
      props["active"]&&React.createElement("div", {
        "className": "_250e2067 _2f5be189 _pub-2f5be189 _pub-a97d53f3",
      }, 
        props["children"]
      )
    )
  )
}));
export default $$Default;


