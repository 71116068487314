import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Select.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_be09e672 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_wrapper _pub-8c547ae7_wrapper _pub-a97d53f3_wrapper wrapper " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["secondary"] ? " " + "_8c547ae7_secondary_field_input _pub-8c547ae7_secondary_field_input _pub-a97d53f3_secondary_field_input secondary_field_input" : ""),
    "ref": ref,
    "data-testid": props["testId"],
  }, 
    props["children"]
  )
}));
export { Wrapper };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Wrapper, {
    "class": "_59b1cb75" + (props["class"] ? " " + props["class"] : "") + (props["padded"] ? " " + "padded" : ""),
    "ref": ref,
    "secondary": props["secondary"],
  }, 
    React.createElement("div", {
      "className": "_f7dad6aa _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_StyledSelect-sc-1taf1wi-0 _pub-8c547ae7_StyledSelect-sc-1taf1wi-0 _pub-a97d53f3_StyledSelect-sc-1taf1wi-0 StyledSelect-sc-1taf1wi-0 _8c547ae7_hAUldd _pub-8c547ae7_hAUldd _pub-a97d53f3_hAUldd hAUldd _8c547ae7_css-2b097c-container _pub-8c547ae7_css-2b097c-container _pub-a97d53f3_css-2b097c-container css-2b097c-container",
    }, 
      React.createElement("div", {
        "className": "_7c72ace4 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__control _pub-8c547ae7_select__control _pub-a97d53f3_select__control select__control _8c547ae7_select__control--menu-is-open _pub-8c547ae7_select__control--menu-is-open _pub-a97d53f3_select__control--menu-is-open select__control--menu-is-open _8c547ae7_css-yk16xz-control _pub-8c547ae7_css-yk16xz-control _pub-a97d53f3_css-yk16xz-control css-yk16xz-control" + (props["active"] ? " " + "_8c547ae7_active _pub-8c547ae7_active _pub-a97d53f3_active active" : ""),
      }, 
        React.createElement("div", {
          "className": "_a258dde5 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__value-container _pub-8c547ae7_select__value-container _pub-a97d53f3_select__value-container select__value-container _8c547ae7_select__value-container--has-value _pub-8c547ae7_select__value-container--has-value _pub-a97d53f3_select__value-container--has-value select__value-container--has-value _8c547ae7_css-1hwfws3 _pub-8c547ae7_css-1hwfws3 _pub-a97d53f3_css-1hwfws3 css-1hwfws3" + (props["showMulti"] ? " " + "_8c547ae7_select__value-container--is-multi _pub-8c547ae7_select__value-container--is-multi _pub-a97d53f3_select__value-container--is-multi select__value-container--is-multi _8c547ae7_select__value-container--has-value _pub-8c547ae7_select__value-container--has-value _pub-a97d53f3_select__value-container--has-value select__value-container--has-value _8c547ae7_css-g1d714-ValueContainer _pub-8c547ae7_css-g1d714-ValueContainer _pub-a97d53f3_css-g1d714-ValueContainer css-g1d714-ValueContainer" : ""),
        }, 
          props["showMulti"]&&React.createElement(React.Fragment, {
          }, 
            React.createElement("div", {
              "className": "_e754e352 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-1rhbuit-multiValue _pub-8c547ae7_css-1rhbuit-multiValue _pub-a97d53f3_css-1rhbuit-multiValue css-1rhbuit-multiValue _8c547ae7_select__multi-value _pub-8c547ae7_select__multi-value _pub-a97d53f3_select__multi-value select__multi-value",
            }, 
              React.createElement("div", {
                "className": "_f6a413fc _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-12jo7m5 _pub-8c547ae7_css-12jo7m5 _pub-a97d53f3_css-12jo7m5 css-12jo7m5 _8c547ae7_select__multi-value__label _pub-8c547ae7_select__multi-value__label _pub-a97d53f3_select__multi-value__label select__multi-value__label",
              }, 
                "Braintree"
                
              ),
              React.createElement("div", {
                "className": "_81a3236a _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-xb97g8 _pub-8c547ae7_css-xb97g8 _pub-a97d53f3_css-xb97g8 css-xb97g8 _8c547ae7_select__multi-value__remove _pub-8c547ae7_select__multi-value__remove _pub-a97d53f3_select__multi-value__remove select__multi-value__remove",
              }, 
                React.createElement("svg", {
                  "className": "_7a5f0b40 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-6q0nyr-Svg _pub-8c547ae7_css-6q0nyr-Svg _pub-a97d53f3_css-6q0nyr-Svg css-6q0nyr-Svg",
                  "height": "14",
                  "width": "14",
                  "viewBox": "0 0 20 20",
                  "aria-hidden": "true",
                  "focusable": "false",
                }, 
                  React.createElement("path", {
                    "className": "_2081946e _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
                    "d": "M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z",
                  }, null)
                )
              )
            ),
            React.createElement("div", {
              "className": "_9053d3c4 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-b8ldur-Input _pub-8c547ae7_css-b8ldur-Input _pub-a97d53f3_css-b8ldur-Input css-b8ldur-Input",
            }, 
              React.createElement("div", {
                "className": "_f76679cb _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__input _pub-8c547ae7_select__input _pub-a97d53f3_select__input select__input",
                "style": castStyle("display: inline-block;"),
              }, 
                React.createElement("input", {
                  "className": "_46fd48c7 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
                  "autocapitalize": "none",
                  "autocomplete": "off",
                  "autocorrect": "off",
                  "id": "react-select-2-input",
                  "spellcheck": "false",
                  "tabindex": "0",
                  "type": "text",
                  "aria-autocomplete": "list",
                  "style": castStyle("box-sizing: content-box; width: 2px; background: rgba(0, 0, 0, 0) none repeat scroll 0px center; border: 0px none; font-size: inherit; opacity: 1; outline: currentcolor none 0px; padding: 0px; color: inherit;"),
                  "value": "",
                }, null),
                React.createElement("div", {
                  "className": "_31fa7851 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
                  "style": castStyle("position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-size: 16px; font-family: Inter, sans-serif; font-weight: 400; font-style: normal; letter-spacing: normal; text-transform: none;"),
                }, null)
              )
            )
          ),
          !props["showMulti"]&&React.createElement(React.Fragment, {
          }, 
            React.createElement("div", {
              "className": "_5fe88437 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__single-value _pub-8c547ae7_select__single-value _pub-a97d53f3_select__single-value select__single-value _8c547ae7_css-1uccc91-singleValue _pub-8c547ae7_css-1uccc91-singleValue _pub-a97d53f3_css-1uccc91-singleValue css-1uccc91-singleValue",
            }, 
              props["placeholder"]
            ),
            React.createElement("div", {
              "className": "_28efb4a1 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-w8afj7-Input _pub-8c547ae7_css-w8afj7-Input _pub-a97d53f3_css-w8afj7-Input css-w8afj7-Input",
            }, 
              React.createElement("div", {
                "className": "_3c3aaa6e _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__input _pub-8c547ae7_select__input _pub-a97d53f3_select__input select__input",
                "style": castStyle("display: inline-block;"),
              }, 
                React.createElement("input", {
                  "className": "_8a574859 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
                  "autocapitalize": "none",
                  "autocomplete": "off",
                  "autocorrect": "off",
                  "id": "Sort by",
                  "spellcheck": "false",
                  "tabindex": "0",
                  "type": "text",
                  "aria-autocomplete": "list",
                  "aria-label": "Sort by",
                  "style": castStyle("box-sizing: content-box; width: 2px; background: rgba(0, 0, 0, 0) none repeat scroll 0px center; border: 0px none; font-size: inherit; opacity: 1; outline: currentcolor none 0px; padding: 0px; color: inherit;"),
                  "value": "",
                }, null),
                React.createElement("div", {
                  "className": "_fd5078cf _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
                  "style": castStyle("position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-size: 16px; font-family: Eina, sans-serif; font-weight: 400; font-style: normal; letter-spacing: normal; text-transform: none;"),
                }, null)
              )
            )
          )
        ),
        React.createElement("div", {
          "className": "_d55fed73 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__indicators _pub-8c547ae7_select__indicators _pub-a97d53f3_select__indicators select__indicators _8c547ae7_css-1g48xl4-IndicatorsContainer _pub-8c547ae7_css-1g48xl4-IndicatorsContainer _pub-a97d53f3_css-1g48xl4-IndicatorsContainer css-1g48xl4-IndicatorsContainer",
        }, 
          React.createElement("span", {
            "className": "_9d831913 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__indicator-separator _pub-8c547ae7_select__indicator-separator _pub-a97d53f3_select__indicator-separator select__indicator-separator _8c547ae7_css-1okebmr-indicatorSeparator _pub-8c547ae7_css-1okebmr-indicatorSeparator _pub-a97d53f3_css-1okebmr-indicatorSeparator css-1okebmr-indicatorSeparator",
          }, null),
          React.createElement("div", {
            "className": "_ea842985 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__indicator _pub-8c547ae7_select__indicator _pub-a97d53f3_select__indicator select__indicator _8c547ae7_select__dropdown-indicator _pub-8c547ae7_select__dropdown-indicator _pub-a97d53f3_select__dropdown-indicator select__dropdown-indicator _8c547ae7_css-tlfecz-indicatorContainer _pub-8c547ae7_css-tlfecz-indicatorContainer _pub-a97d53f3_css-tlfecz-indicatorContainer css-tlfecz-indicatorContainer",
            "aria-hidden": "true",
          }, 
            React.createElement("svg", {
              "className": "_436c665a _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_css-tj5bde-Svg _pub-8c547ae7_css-tj5bde-Svg _pub-a97d53f3_css-tj5bde-Svg css-tj5bde-Svg",
              "height": "20",
              "width": "20",
              "viewBox": "0 0 20 20",
              "aria-hidden": "true",
              "focusable": "false",
            }, 
              React.createElement("path", {
                "className": "_a3af4a63 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
                "d": "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z",
              }, null)
            )
          )
        )
      ),
      React.createElement("div", {
        "className": "_b759c72 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__menu _pub-8c547ae7_select__menu _pub-a97d53f3_select__menu select__menu _8c547ae7_css-26l3qy-menu _pub-8c547ae7_css-26l3qy-menu _pub-a97d53f3_css-26l3qy-menu css-26l3qy-menu" + (props["showMenu"] ? " " + "_8c547ae7_show-menu _pub-8c547ae7_show-menu _pub-a97d53f3_show-menu show-menu" : ""),
      }, 
        React.createElement("div", {
          "className": "_a39ab7d2 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__menu-list _pub-8c547ae7_select__menu-list _pub-a97d53f3_select__menu-list select__menu-list _8c547ae7_css-1ml51p6-MenuList _pub-8c547ae7_css-1ml51p6-MenuList _pub-a97d53f3_css-1ml51p6-MenuList css-1ml51p6-MenuList",
        }, 
          React.createElement("div", {
            "className": "_a1215a94 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__option _pub-8c547ae7_select__option _pub-a97d53f3_select__option select__option _8c547ae7_select__option--is-focused _pub-8c547ae7_select__option--is-focused _pub-a97d53f3_select__option--is-focused select__option--is-focused _8c547ae7_css-yt9ioa-option _pub-8c547ae7_css-yt9ioa-option _pub-a97d53f3_css-yt9ioa-option css-yt9ioa-option",
            "id": "react-select-2-option-0",
            "tabindex": "-1",
          }, 
            "\n          Alphabetically\n        "
            
          ),
          React.createElement("div", {
            "className": "_d6266a02 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__option _pub-8c547ae7_select__option _pub-a97d53f3_select__option select__option _8c547ae7_css-yt9ioa-option _pub-8c547ae7_css-yt9ioa-option _pub-a97d53f3_css-yt9ioa-option css-yt9ioa-option",
            "id": "react-select-2-option-1",
            "tabindex": "-1",
          }, 
            "\n          Most recently added\n        "
            
          ),
          React.createElement("div", {
            "className": "_4f2f3bb8 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_select__option _pub-8c547ae7_select__option _pub-a97d53f3_select__option select__option _8c547ae7_select__option--is-selected _pub-8c547ae7_select__option--is-selected _pub-a97d53f3_select__option--is-selected select__option--is-selected _8c547ae7_css-yt9ioa-option _pub-8c547ae7_css-yt9ioa-option _pub-a97d53f3_css-yt9ioa-option css-yt9ioa-option",
            "id": "react-select-2-option-2",
            "tabindex": "-1",
          }, 
            "\n          Last modified\n        "
            
          )
        )
      )
    )
  )
}));
export { Preview };

var SelectPreview = React.memo(React.forwardRef(function SelectPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_6b3351c7 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_a8fa03f2 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
    }, 
      React.createElement(Preview, {
        "class": "_f8a902c3",
        "active": true,
        "placeholder": "active",
      }, null)
    ),
    React.createElement(Preview, {
      "class": "_31f35248",
      "secondary": true,
      "placeholder": "blah",
    }, null),
    React.createElement("div", {
      "className": "_46f462de _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_negative _pub-8c547ae7_negative _pub-a97d53f3_negative negative",
    }, 
      React.createElement(Preview, {
        "class": "_fb2dd6ad",
        "placeholder": "negative",
      }, null)
    ),
    React.createElement("div", {
      "className": "_d890f77d _8c547ae7 _pub-8c547ae7 _pub-a97d53f3" + " " + "_8c547ae7_positive _pub-8c547ae7_positive _pub-a97d53f3_positive positive",
    }, 
      React.createElement(Preview, {
        "class": "_fe62c028",
        "placeholder": "positive",
      }, null)
    ),
    React.createElement(Preview, {
      "class": "_af97c7eb",
      "padded": true,
      "showMulti": true,
      "placeholder": "OKOKOKO",
    }, null),
    React.createElement(Preview, {
      "class": "_369e9651",
      "padded": true,
      "showMenu": true,
      "placeholder": "Last Modified",
    }, null),
    React.createElement("div", {
      "className": "_4199a6c7 _8c547ae7 _pub-8c547ae7 _pub-a97d53f3",
    }, 
      React.createElement(Preview, {
        "class": "_8b234ee7",
        "showMenu": true,
        "placeholder": "positive",
      }, null)
    )
  )
}));


