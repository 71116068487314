import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub2Dbb4493 from "./Button.pc";
import "./Tooltip.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var TooltipContainer = React.memo(React.forwardRef(function TooltipContainer(props, ref) {
  return React.createElement("div", {
    "className": "_1e44a755 _fe0274b0 _pub-fe0274b0 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["inherit"] ? " " + "_fe0274b0_inherit _pub-fe0274b0_inherit _pub-a97d53f3_inherit inherit" : "") + (props["inlineBlock"] ? " " + "_fe0274b0_inline-block _pub-fe0274b0_inline-block _pub-a97d53f3_inline-block inline-block" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { TooltipContainer };

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_f04ac679 _fe0274b0 _pub-fe0274b0 _pub-a97d53f3" + " " + "_fe0274b0_tooltip _pub-fe0274b0_tooltip _pub-a97d53f3_tooltip tooltip " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["visible"] ? " " + "_fe0274b0_visible _pub-fe0274b0_visible _pub-a97d53f3_visible visible" : "") + (props["center"] ? " " + "_fe0274b0_center _pub-fe0274b0_center _pub-a97d53f3_center center" : "") + (props["left"] ? " " + "_fe0274b0_left _pub-fe0274b0_left _pub-a97d53f3_left left" : "") + (props["narrow"] ? " " + "_fe0274b0_narrow _pub-fe0274b0_narrow _pub-a97d53f3_narrow narrow" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export default $$Default;

var TooltipPreview = React.memo(React.forwardRef(function TooltipPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_17f2eb7e _fe0274b0 _pub-fe0274b0 _pub-a97d53f3" + " " + "v3",
    "ref": ref,
    "spaced": true,
  }, 
    React.createElement(TooltipContainer, {
      "class": "_c14e00c6",
    }, 
      React.createElement($$Default, {
        "class": "_da8ac93a",
        "visible": true,
      }, 
        "\n      Companies that you have already been introduced to\n    "
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_ad8df9ac _fe0274b0 _pub-fe0274b0 _pub-a97d53f3",
        "primary": true,
      }, 
        "bottom placement"
        
      )
    )
  )
}));
export { TooltipPreview };

var TooltipInlineBlockPreview = React.memo(React.forwardRef(function TooltipInlineBlockPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_fcafcb6f _fe0274b0 _pub-fe0274b0 _pub-a97d53f3" + " " + "v3",
    "ref": ref,
    "spaced": true,
  }, 
    React.createElement("div", {
      "className": "_a9f9e843 _fe0274b0 _pub-fe0274b0 _pub-a97d53f3",
    }, 
      "\n    We use your accounting software to generate advanced analytics. This gives\n    you insights into how Investors analyze your financials.\n  "
      
    ),
    React.createElement(TooltipContainer, {
      "class": "_defed8d5",
      "inlineBlock": true,
    }, 
      React.createElement($$Default, {
        "class": "_336dcdfe",
        "visible": true,
      }, 
        "\n      Companies that you have already been introduced to\n    "
        
      ),
      React.createElement("span", {
        "className": "_446afd68 _fe0274b0 _pub-fe0274b0 _pub-a97d53f3",
      }, 
        "Hover me!"
        
      )
    )
  )
}));
export { TooltipInlineBlockPreview };


