import React from "react";
import _pub63C09B0F from "./__DEPRECATED__typography.pc";
import _pubDa25D269 from "./__DEPRECATED__tailwind.pc";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub14733275 from "./Checkbox.pc";
import _pubDf2A042 from "./Field.pc";
import "./Menu.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {
  "tippy-reset": "_pub-622f8858_tippy-reset"
};

var MenuItem = React.memo(React.forwardRef(function MenuItem(props, ref) {
  return React.createElement("div", {
    "className": "_2468a1dd _622f8858 _pub-622f8858 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["selected"] ? " " + "_622f8858_selected _pub-622f8858_selected _pub-a97d53f3_selected selected" : "") + (props["checked"] ? " " + "_622f8858_checked _pub-622f8858_checked _pub-a97d53f3_checked checked" : "") + (props["danger"] ? " " + "_622f8858_danger _pub-622f8858_danger _pub-a97d53f3_danger danger" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": props["testId"],
    "data-pc-label": "Menu Item",
  }, 
    React.createElement("div", {
      "className": "_a56a446e _622f8858 _pub-622f8858 _pub-a97d53f3",
    }, 
      props["children"]
    ),
    React.createElement("div", {
      "className": "_3c6315d4 _622f8858 _pub-622f8858 _pub-a97d53f3",
    }, 
      props["rightControls"],
      props["checked"]&&React.createElement("div", {
        "className": "_6df3235a _622f8858 _pub-622f8858 _pub-a97d53f3",
      }, null)
    )
  )
}));
export { MenuItem };

var MenuItemGroup = React.memo(React.forwardRef(function MenuItemGroup(props, ref) {
  return React.createElement("div", {
    "className": "_dd5fa2a0 _622f8858 _pub-622f8858 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_a6f2eb58 _622f8858 _pub-622f8858 _pub-a97d53f3" + " " + "_622f8858_color-text-subdued _pub-622f8858_color-text-subdued _pub-a97d53f3_color-text-subdued color-text-subdued _622f8858_text-other-subheading _pub-622f8858_text-other-subheading _pub-a97d53f3_text-other-subheading text-other-subheading",
    }, 
      props["label"]
    ),
    React.createElement("div", {
      "className": "_3ffbbae2 _622f8858 _pub-622f8858 _pub-a97d53f3" + " " + "_622f8858_items _pub-622f8858_items _pub-a97d53f3_items items",
    }, 
      props["children"]
    )
  )
}));
export { MenuItemGroup };

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_3351c38c _622f8858 _pub-622f8858 _pub-a97d53f3" + (props["absolute"] ? " " + "_622f8858__absolute _pub-622f8858__absolute _pub-a97d53f3__absolute _absolute" : "") + (props["secondary"] ? " " + "_622f8858_secondary _pub-622f8858_secondary _pub-a97d53f3_secondary secondary" : "") + (props["w1"] ? " " + "_622f8858_w1 _pub-622f8858_w1 _pub-a97d53f3_w1 w1" : "") + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-pc-label": "Menu",
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export default $$Default;

var MenuPreview = React.memo(React.forwardRef(function MenuPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_da3266b9 _622f8858 _pub-622f8858 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement($$Default, {
      "class": "_a1fb4384",
    }, 
      React.createElement(MenuItem, {
        "class": "_8017a4b4",
      }, 
        React.createElement(_pubDf2A042, {
          "class": "_1638f4e6 _622f8858 _pub-622f8858 _pub-a97d53f3",
          "toggle": true,
          "label": "Item 1",
        }, 
          React.createElement(_pub14733275, {
            "class": "_f5de0ceb _622f8858 _pub-622f8858 _pub-a97d53f3",
            "checked": true,
          }, null)
        )
      ),
      React.createElement(MenuItem, {
        "class": "_191ef50e",
      }, 
        React.createElement(_pubDf2A042, {
          "class": "_147e4abf _622f8858 _pub-622f8858 _pub-a97d53f3",
          "toggle": true,
          "label": "Item 1",
        }, 
          React.createElement(_pub14733275, {
            "class": "_b27e763b _622f8858 _pub-622f8858 _pub-a97d53f3",
            "disabled": true,
          }, null)
        )
      ),
      React.createElement(MenuItem, {
        "class": "_6e19c598",
      }, 
        React.createElement(_pubDf2A042, {
          "class": "_15bc2088 _622f8858 _pub-622f8858 _pub-a97d53f3",
          "toggle": true,
          "label": "Item 1",
        }, 
          React.createElement(_pub14733275, {
            "class": "_8f1e5f8b _622f8858 _pub-622f8858 _pub-a97d53f3",
          }, null)
        )
      ),
      React.createElement(MenuItem, {
        "class": "_f07d503b",
        "rightControls": React.createElement(_pub14733275, {
          "class": "_45204b55 _622f8858 _pub-622f8858 _pub-a97d53f3",
        }, null),
      }, 
        "\n      Item 1\n    "
        
      ),
      React.createElement(MenuItem, {
        "class": "_877a60ad",
        "selected": true,
      }, 
        "Item 1"
        
      ),
      React.createElement(MenuItem, {
        "class": "_1e733117",
      }, 
        "Item 1"
        
      )
    )
  )
}));
export { MenuPreview };

var MenuPreview2 = React.memo(React.forwardRef(function MenuPreview2(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_343c0795 _622f8858 _pub-622f8858 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement($$Default, {
      "class": "_a27f97ea",
      "secondary": true,
    }, 
      React.createElement(MenuItemGroup, {
        "class": "_fad7f7d4",
        "label": "Date",
      }, 
        React.createElement(MenuItem, {
          "class": "_81a7e5cf",
        }, 
          "Item 1"
          
        ),
        React.createElement(MenuItem, {
          "class": "_f6a0d559",
        }, 
          "Item 1"
          
        )
      ),
      React.createElement(MenuItemGroup, {
        "class": "_63dea66e",
        "label": "Group",
      }, 
        React.createElement(MenuItem, {
          "class": "_83e15b96",
          "checked": true,
        }, 
          "Item 1"
          
        ),
        React.createElement(MenuItem, {
          "class": "_f4e66b00",
          "selected": true,
        }, 
          "Item 1"
          
        ),
        React.createElement(MenuItem, {
          "class": "_6def3aba",
        }, 
          "Item 1"
          
        )
      )
    )
  )
}));
export { MenuPreview2 };

var MenuContainer = React.memo(React.forwardRef(function MenuContainer(props, ref) {
  return React.createElement("div", {
    "className": "_f672f163 _622f8858 _pub-622f8858 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-pc-label": "Menu Container",
  }, 
    props["children"]
  )
}));
export { MenuContainer };

var MenuButton = React.memo(React.forwardRef(function MenuButton(props, ref) {
  return React.createElement("div", {
    "className": "_187c904f _622f8858 _pub-622f8858 _pub-a97d53f3" + " " + "_622f8858__menu-button _pub-622f8858__menu-button _pub-a97d53f3__menu-button _menu-button" + (props["secondaryButton"] ? " " + "_622f8858_horizontal-button _pub-622f8858_horizontal-button _pub-a97d53f3_horizontal-button horizontal-button" : "") + (props["disabled"] ? " " + "_622f8858_disabled _pub-622f8858_disabled _pub-a97d53f3_disabled disabled" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": props["testId"],
  }, null)
}));
export { MenuButton };

var MenuLabel = React.memo(React.forwardRef(function MenuLabel(props, ref) {
  return React.createElement("div", {
    "className": "_f11f357a _622f8858 _pub-622f8858 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : ""),
    "ref": ref,
    "data-testid": props["testId"],
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { MenuLabel };


