import { Action } from 'redux';

export type Confirmation = {
  message: string;
  okAction: Action;
};
export type ConfirmationsAppState = {
  confirmations: Confirmation[];
};

export const createConfirm = (
  message: string,
  okAction: Action
): Confirmation => ({
  message,
  okAction,
});

export const getConfirmations = (state: ConfirmationsAppState) => {
  return state.confirmations;
};
