import { useContext, createContext } from 'react';
import { Form } from '../types';

// @ts-ignore we enforce form is available later on.
const FormContext: any = createContext<Form<any>>(undefined);

/**
 * Extracts the first available form context.
 */
function useFormContext<TFormValues>(strict: true): Form<TFormValues>;
function useFormContext<TFormValues>(
  strict: false
): Form<TFormValues> | undefined;
function useFormContext<TFormValues>(
  strict: boolean
): Form<TFormValues> | undefined;
function useFormContext(strict: boolean = true) {
  const form: any = useContext(FormContext);

  if (!form && strict) {
    throw new Error(
      '`useFormContext` must only be used within a FormContext.Provider sub-tree.'
    );
  }

  return form;
}

export { useFormContext, FormContext };
