import React from "react";
import _pub63C09B0F from "./__DEPRECATED__typography.pc";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Table.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_b22f1e2e _a53a8fae _pub-a53a8fae _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + " " + " _a53a8fae__table _pub-a53a8fae__table _pub-a97d53f3__table _table" + (props["companyAnalyses"] ? " " + "_a53a8fae_company-analyses _pub-a53a8fae_company-analyses _pub-a97d53f3_company-analyses company-analyses" : "") + (props["secondary"] ? " " + "_a53a8fae_secondary_table _pub-a53a8fae_secondary_table _pub-a97d53f3_secondary_table secondary_table" : "") + (props["withChevron"] ? " " + "_a53a8fae_with_chevron _pub-a53a8fae_with_chevron _pub-a97d53f3_with_chevron with_chevron" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export default $$Default;

var TableDataRows = React.memo(React.forwardRef(function TableDataRows(props, ref) {
  return React.createElement("div", {
    "className": "_5c217f02 _a53a8fae _pub-a53a8fae _pub-a97d53f3" + " " + "_a53a8fae__data-rows _pub-a53a8fae__data-rows _pub-a97d53f3__data-rows _data-rows",
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export { TableDataRows };

var TableRow = React.memo(React.forwardRef(function TableRow(props, ref) {
  return React.createElement("div", {
    "className": "_bb995205 _a53a8fae _pub-a53a8fae _pub-a97d53f3" + " " + "_a53a8fae__row _pub-a53a8fae__row _pub-a97d53f3__row _row " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["header"] ? " " + "_a53a8fae__header _pub-a53a8fae__header _pub-a97d53f3__header _header" : "") + (props["selected"] ? " " + "_a53a8fae__selected _pub-a53a8fae__selected _pub-a97d53f3__selected _selected" : "") + (props["hover"] ? " " + "_a53a8fae_hover _pub-a53a8fae_hover _pub-a97d53f3_hover hover" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": props["testId"],
  }, 
    props["children"]
  )
}));
export { TableRow };

var TableCell = React.memo(React.forwardRef(function TableCell(props, ref) {
  return React.createElement("div", {
    "className": "_3bd40862 _a53a8fae _pub-a53a8fae _pub-a97d53f3" + " " + "_a53a8fae__cell _pub-a53a8fae__cell _pub-a97d53f3__cell _cell " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["selected"] ? " " + "_a53a8fae__selected _pub-a53a8fae__selected _pub-a97d53f3__selected _selected" : "") + (props["ascending"] ? " " + "_a53a8fae__ascending _pub-a53a8fae__ascending _pub-a97d53f3__ascending _ascending" : "") + (props["descending"] ? " " + "_a53a8fae__descending _pub-a53a8fae__descending _pub-a97d53f3__descending _descending" : "") + (props["wideCells"] ? " " + "_a53a8fae__wide _pub-a53a8fae__wide _pub-a97d53f3__wide _wide" : "") + (props["sortable"] ? " " + "_a53a8fae_sortable _pub-a53a8fae_sortable _pub-a97d53f3_sortable sortable" : "") + (props["nowrap"] ? " " + "_a53a8fae_nowrap _pub-a53a8fae_nowrap _pub-a97d53f3_nowrap nowrap" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export { TableCell };

var TableSubtext = React.memo(React.forwardRef(function TableSubtext(props, ref) {
  return React.createElement("span", {
    "className": "_d5da694e _a53a8fae _pub-a53a8fae _pub-a97d53f3" + " " + "_a53a8fae__subtext _pub-a53a8fae__subtext _pub-a97d53f3__subtext _subtext",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { TableSubtext };

var TablePreview = React.memo(React.forwardRef(function TablePreview(props, ref) {
  return React.createElement("div", {
    "className": "_3cb9cc7b _a53a8fae _pub-a53a8fae _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement($$Default, {
      "class": "_19336aa3",
      "secondary": props["secondary"],
      "withChevron": props["secondary"],
    }, 
      React.createElement(TableRow, {
        "class": "_6f6706e3",
        "header": true,
      }, 
        React.createElement(TableCell, {
          "class": "_db1a12c1",
          "selected": true,
          "descending": true,
        }, 
          "Company"
          
        ),
        React.createElement(TableCell, {
          "class": "_ac1d2257",
        }, 
          "Account Owner"
          
        ),
        React.createElement(TableCell, {
          "class": "_351473ed" + " " + "_cell",
          "style": castStyle("width: 50%;"),
        }, 
          "Status"
          
        )
      ),
      React.createElement(TableDataRows, {
        "class": "_18603675",
      }, 
        React.createElement("Scrollable", {
          "className": "_dad878f6 _a53a8fae _pub-a53a8fae _pub-a97d53f3",
        }, 
          React.createElement(TableRow, {
            "class": "_5b121d51",
            "hover": true,
          }, 
            React.createElement(TableCell, {
              "class": "_878c2362",
            }, 
              "Eat Streat"
              
            ),
            React.createElement(TableCell, {
              "class": "_f08b13f4",
            }, 
              "\n            Elliot Lippner\n            "
              ,
              React.createElement(TableSubtext, {
                "class": "_c443ddc6",
              }, 
                "email@email.com"
                
              )
            ),
            React.createElement(TableCell, {
              "class": "_6982424e",
              "style": castStyle("width: 50%;"),
            }, 
              "Status"
              
            )
          ),
          React.createElement(TableRow, {
            "class": "_2c152dc7",
          }, 
            React.createElement(TableCell, {
              "class": "_864e4955",
            }, 
              "Duck Squad"
              
            ),
            React.createElement(TableCell, {
              "class": "_f14979c3",
            }, 
              "\n            Trevor Noah\n            "
              ,
              React.createElement(TableSubtext, {
                "class": "_f923f476",
              }, 
                "email@email.com"
                
              )
            ),
            React.createElement(TableCell, {
              "class": "_68402879",
              "style": castStyle("width: 50%;"),
              "nowrap": true,
            }, 
              React.createElement("div", {
                "className": "_8c627ab9 _a53a8fae _pub-a53a8fae _pub-a97d53f3",
              }, 
                "Status"
                
              ),
              props["showMenu"]&&React.createElement("MenuContainer", {
                "className": "_5ff18732 _a53a8fae _pub-a53a8fae _pub-a97d53f3",
              }, 
                React.createElement("MenuButton", {
                  "className": "_d98665bf _a53a8fae _pub-a53a8fae _pub-a97d53f3",
                }, null),
                React.createElement("Menu", {
                  "className": "_ae815529 _a53a8fae _pub-a53a8fae _pub-a97d53f3",
                  "absolute": true,
                }, 
                  React.createElement("MenuItem", {
                    "className": "_186decad _a53a8fae _pub-a53a8fae _pub-a97d53f3",
                  }, 
                    "Item"
                    
                  ),
                  React.createElement("MenuItem", {
                    "className": "_6f6adc3b _a53a8fae _pub-a53a8fae _pub-a97d53f3",
                    "selected": true,
                  }, 
                    "Another menu item"
                    
                  ),
                  React.createElement("MenuItem", {
                    "className": "_f6638d81 _a53a8fae _pub-a53a8fae _pub-a97d53f3",
                  }, 
                    "\n                  Some super long menu item that needs chopping\n                "
                    
                  )
                )
              )
            )
          )
        )
      )
    )
  )
}));
export { TablePreview };


