import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Breadcrumbs.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var BreadcrumbItem = React.memo(React.forwardRef(function BreadcrumbItem(props, ref) {
  return React.createElement("button", {
    "className": "_e85562b6 _a8875cb4 _pub-a8875cb4 _pub-a97d53f3",
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { BreadcrumbItem };

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_65b039a _a8875cb4 _pub-a8875cb4 _pub-a97d53f3" + " " + "_a8875cb4_text-body-medium _pub-a8875cb4_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["padded"] ? " " + "_a8875cb4_padded _pub-a8875cb4_padded _pub-a97d53f3_padded padded" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export default $$Default;

var BreadcrumbsPreviewInner = React.memo(React.forwardRef(function BreadcrumbsPreviewInner(props, ref) {
  return React.createElement($$Default, {
    "class": "_e1e32e9d",
    "ref": ref,
  }, 
    React.createElement(BreadcrumbItem, {
      "class": "_5f1360c7",
    }, 
      "Bready"
      
    ),
    React.createElement(BreadcrumbItem, {
      "class": "_28145051",
    }, 
      "Mc"
      
    ),
    React.createElement(BreadcrumbItem, {
      "class": "_b11d01eb",
    }, 
      "Bread"
      
    )
  )
}));
export { BreadcrumbsPreviewInner };

var BreadcrumbsPreview = React.memo(React.forwardRef(function BreadcrumbsPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_ab4668bf _a8875cb4 _pub-a8875cb4 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(BreadcrumbsPreviewInner, {
      "class": "_671e88e7",
    }, null)
  )
}));
export { BreadcrumbsPreview };


