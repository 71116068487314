import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import "./CheckCircleIcon.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_a20e09eb _d91d8869 _pub-d91d8869 _pub-a97d53f3" + (props["small"] ? " " + "_d91d8869_small _pub-d91d8869_small _pub-a97d53f3_small small" : "") + (props["transparent"] ? " " + "_d91d8869_transparent _pub-d91d8869_transparent _pub-a97d53f3_transparent transparent" : "") + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    React.createElement("i", {
      "className": "_f47b78a7 _d91d8869 _pub-d91d8869 _pub-a97d53f3",
    }, null)
  )
}));
export default $$Default;


