export type Result<TData> = {
  data?: TData;
  loaded: boolean;
  error?: Error;

  // used for TTL caching
  createdAt?: number;
};

export const createErrorResult = (error: Error): Result<any> => ({
  error,
  data: undefined,
  loaded: true,
  createdAt: Date.now(),
});
export const createDataResult = <TData>(data: TData): Result<TData> => ({
  data,
  loaded: true,
  createdAt: Date.now(),
});

export const createLoadingResult = (): Result<any> => ({
  error: undefined,
  loaded: false,
  createdAt: Date.now(),
});

export const LOADING_RESULT = {
  loaded: false,
};
