import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Alert.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_fab96785 _dac6f880 _pub-dac6f880 _pub-a97d53f3" + " " + "_dac6f880_text-body-small _pub-dac6f880_text-body-small _pub-a97d53f3_text-body-small text-body-small _dac6f880_alert _pub-dac6f880_alert _pub-a97d53f3_alert alert " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["positive"] ? " " + "_dac6f880_positive _pub-dac6f880_positive _pub-a97d53f3_positive positive" : "") + (props["negative"] ? " " + "_dac6f880_negative _pub-dac6f880_negative _pub-a97d53f3_negative negative" : "") + (props["notice"] ? " " + "_dac6f880_notice _pub-dac6f880_notice _pub-a97d53f3_notice notice" : "") + (props["info"] ? " " + "_dac6f880_info _pub-dac6f880_info _pub-a97d53f3_info info" : "") + (props["breakText"] ? " " + "_dac6f880_break-text _pub-dac6f880_break-text _pub-a97d53f3_break-text break-text" : "") + (props["fullwidth"] ? " " + "_dac6f880_fullwidth _pub-dac6f880_fullwidth _pub-a97d53f3_fullwidth fullwidth" : ""),
    "ref": ref,
    "data-testid": props["testId"],
  }, 
    React.createElement("div", {
      "className": "_a50f2a66 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_d831e8d2 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
      }, null),
      React.createElement("div", {
        "className": "_4138b968 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
      }, 
        React.createElement("strong", {
          "className": "_2fb4e395 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
        }, 
          props["title"]
        ),
        props["description"]&&React.createElement("span", {
          "className": "_5fbc5fca _dac6f880 _pub-dac6f880 _pub-a97d53f3",
        }, 
          !props["breakText"]&&props["title"]&&React.createElement(React.Fragment, {
          }, 
            " – "
            
          ),
          props["description"]
        )
      )
    ),
    props["closable"]&&React.createElement("button", {
      "className": "_7b8247a5 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
      "onClick": props["onCloseClick"],
      "data-testid": props["buttonTestId"],
    }, null)
  )
}));
export default $$Default;

var AlertPreview = React.memo(React.forwardRef(function AlertPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_13dac2b0 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement($$Default, {
      "class": "_a18256d4",
      "title": "Your message",
      "description": "make it short & clear.",
    }, null),
    React.createElement($$Default, {
      "class": "_388b076e",
      "info": true,
      "title": "Your message",
      "closable": true,
      "description": "make it short & clear.",
    }, null),
    React.createElement($$Default, {
      "class": "_4f8c37f8",
      "positive": true,
      "title": "Your message",
      "description": "make it short & clear.",
    }, null),
    React.createElement($$Default, {
      "class": "_d1e8a25b",
      "negative": true,
      "title": "Your message",
      "description": "Nam placerat, lectus sed efficitur semper, justo diam scelerisque sapien, nec tristique turpis lacus et nulla. Sed varius lobortis augue, ac consequat dui tristique nec. Ut pretium, mi ac tempor tincidunt, mi nulla vehicula nulla, non semper leo nulla sit amet lacus. Sed ante nulla, finibus id mauris laoreet, egesta",
    }, null),
    React.createElement($$Default, {
      "class": "_a6ef92cd",
      "notice": true,
      "title": "Your message",
    }, null),
    React.createElement($$Default, {
      "class": "_3fe6c377",
      "notice": true,
      "title": "Title",
      "description": "I'm some text that has break text",
      "breakText": true,
    }, null),
    React.createElement("div", {
      "className": "_48e1f3e1 _dac6f880 _pub-dac6f880 _pub-a97d53f3",
    }, 
      React.createElement($$Default, {
        "class": "_53fc61c0",
        "notice": true,
        "title": "Title",
        "fullwidth": true,
        "description": "I'm a full width banner",
      }, null)
    )
  )
}));
export { AlertPreview };


