import React from "react";
import "./DownloadIcon.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var DownloadIcon = React.memo(React.forwardRef(function DownloadIcon(props, ref) {
  return React.createElement("svg", {
    "className": "_6bc139ff _d3a4dbf5 _pub-d3a4dbf5",
    "ref": ref,
    "width": "25",
    "height": "24",
    "viewBox": "0 0 25 24",
    "fill": "none",
    "xmlns": "http://www.w3.org/2000/svg",
  }, 
    React.createElement("path", {
      "className": "_8b0b6607 _d3a4dbf5 _pub-d3a4dbf5",
      "d": "M21.3751 15V19C21.3751 19.5304 21.1644 20.0391 20.7893 20.4142C20.4143 20.7893 19.9056 21 19.3751 21H5.37512C4.84469 21 4.33598 20.7893 3.96091 20.4142C3.58584 20.0391 3.37512 19.5304 3.37512 19V15",
      "stroke": "#7E899A",
      "strokeWidth": "2",
      "strokeLinecap": "round",
      "strokeLinejoin": "round",
    }, null),
    React.createElement("path", {
      "className": "_fc0c5691 _d3a4dbf5 _pub-d3a4dbf5",
      "d": "M7.37512 10L12.3751 15L17.3751 10",
      "stroke": "#7E899A",
      "strokeWidth": "2",
      "strokeLinecap": "round",
      "strokeLinejoin": "round",
    }, null),
    React.createElement("path", {
      "className": "_6505072b _d3a4dbf5 _pub-d3a4dbf5",
      "d": "M12.3751 15V3",
      "stroke": "#7E899A",
      "strokeWidth": "2",
      "strokeLinecap": "round",
      "strokeLinejoin": "round",
    }, null)
  )
}));
export { DownloadIcon };


