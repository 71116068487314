import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./layout.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Box = React.memo(React.forwardRef(function Box(props, ref) {
  return React.createElement("div", {
    "className": "_dcfc16f7 _824b8933 _pub-824b8933 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["vertical"] ? " " + "_824b8933_vertical _pub-824b8933_vertical _pub-a97d53f3_vertical vertical" : "") + (props["justifySpaceBetween"] ? " " + "_824b8933_justify-space-between _pub-824b8933_justify-space-between _pub-a97d53f3_justify-space-between justify-space-between" : "") + (props["alignEnd"] ? " " + "_824b8933_align-end _pub-824b8933_align-end _pub-a97d53f3_align-end align-end" : "") + (props["alignCenter"] ? " " + "_824b8933_align-center _pub-824b8933_align-center _pub-a97d53f3_align-center align-center" : "") + (props["alignItemsCenter"] ? " " + "_824b8933_align-items-center _pub-824b8933_align-items-center _pub-a97d53f3_align-items-center align-items-center" : "") + (props["fill"] ? " " + "_824b8933_fill _pub-824b8933_fill _pub-a97d53f3_fill fill" : "") + (props["halfGap"] ? " " + "_824b8933_half-gap _pub-824b8933_half-gap _pub-a97d53f3_half-gap half-gap" : ""),
    "ref": ref,
    "style": castStyle("--gap:" + props["gap"]),
  }, 
    props["children"]
  )
}));
export { Box };

var Grid = React.memo(React.forwardRef(function Grid(props, ref) {
  return React.createElement("div", {
    "className": "_359fb3c2 _824b8933 _pub-824b8933 _pub-a97d53f3",
    "ref": ref,
    "style": castStyle("--grid-template-columns: " + props["templateColumns"]),
  }, 
    props["children"]
  )
}));
export { Grid };

var BoxPreviewItem = React.memo(React.forwardRef(function BoxPreviewItem(props, ref) {
  return React.createElement("div", {
    "className": "_db91d2ee _824b8933 _pub-824b8933 _pub-a97d53f3",
    "ref": ref,
  }, null)
}));

var BoxPreview = React.memo(React.forwardRef(function BoxPreview(props, ref) {
  return React.createElement("div", {
    "className": "_3c29ffe9 _824b8933 _pub-824b8933 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("h2", {
      "className": "_e594e3bd _824b8933 _pub-824b8933 _pub-a97d53f3",
    }, 
      props["title"]
    ),
    React.createElement(Box, {
      "class": "_9293d32b",
      "vertical": props["vertical"],
      "fill": props["fill"],
      "halfGap": props["halfGap"],
      "gap": props["gap"],
      "justifySpaceBetween": props["justifySpaceBetween"],
      "alignEnd": props["alignEnd"],
      "alignCenter": props["alignCenter"],
    }, 
      React.createElement(BoxPreviewItem, {
        "class": "_2e809428",
      }, null),
      React.createElement(BoxPreviewItem, {
        "class": "_5987a4be",
      }, null),
      React.createElement(BoxPreviewItem, {
        "class": "_c08ef504",
      }, null),
      React.createElement(BoxPreviewItem, {
        "class": "_b789c592",
      }, null)
    )
  )
}));

var FormLayout = React.memo(React.forwardRef(function FormLayout(props, ref) {
  return React.createElement("form", {
    "className": "_7ce177b0 _824b8933 _pub-824b8933 _pub-a97d53f3" + " " + "_824b8933_v3 _pub-824b8933_v3 _pub-a97d53f3_v3 v3 _824b8933_form-layout _pub-824b8933_form-layout _pub-a97d53f3_form-layout form-layout " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-testid": props["testId"],
    "onSubmit": props["onSubmit"],
  }, 
    props["children"]
  )
}));
export { FormLayout };


