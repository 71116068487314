import { Client } from '../api/client2';
import { isEqual } from 'lodash';

type Request = {
  method: string;
  pathname: string;
  body?: any;
};

export type MockResponse = {
  data: any;
  statusCode?: number;
  expectedRequest: Request;
};

export type MockClient = {
  get(pathname: string, body?: any): Promise<any>;
  del(pathname: string, body?: any): Promise<any>;
  post(pathname: string, body?: any): Promise<any>;
  put(pathname: string, body?: any): Promise<any>;
  patch(pathname: string, body?: any): Promise<any>;
  getPendingRequests(): Request[];
  clearRequests(): void;
  mockResponse(response: MockResponse): Promise<void>;
  mockResponses(responses: MockResponse[]): Promise<void>;
  assertNoRequest(): Promise<void>;
};

/**
 * Creates a mock API client that is used for sagas
 */

export const createMockClient = (): MockClient => {
  let responseResolvers: Array<[Request, (data: any) => void]> = [];

  const mockReq = (method: string, useBody?: boolean) => (
    pathname: string,
    body?: any
  ) => {
    return new Promise((resolve, reject) => {
      const request: any = { method, pathname, body: undefined };
      if (useBody !== false) {
        request.body = body;
      }

      responseResolvers.push([
        request,
        ({ data, statusCode = 200, expectedRequest }) => {
          if (statusCode === 200) {
            // We assert that the request matches the mock response as a sanity check
            expect(request).toEqual(expectedRequest);
            resolve(data);
          } else {
            reject(data);
          }
        },
      ]);
    });
  };

  const mockClient: Client = {
    get: mockReq('get', false),
    del: mockReq('del', false),

    // For backwards compatibility
    delete: mockReq('del'),
    post: mockReq('post'),
    put: mockReq('put'),
    patch: mockReq('patch'),
  } as Client;

  const clearRequests = () => {
    responseResolvers = [];
  };

  const getPendingRequests = () => {
    return responseResolvers.map(([request]) => request);
  };

  const mockResponse = async (response: MockResponse) => {
    const nextResolverIndex = responseResolvers.findIndex(([request]) =>
      // clone with JSON to remove all undefined props
      isEqual(
        JSON.parse(JSON.stringify(request)),
        JSON.parse(
          JSON.stringify({
            ...response.expectedRequest,

            // need to trigger hasOwnProp
            body: response.expectedRequest.body,
          })
        )
      )
    );
    if (nextResolverIndex === -1) {
      throw new Error(
        `There are no pending requests that match the expected request: ${JSON.stringify(
          response.expectedRequest,
          null,
          2
        )}. Options are: ${JSON.stringify(getPendingRequests(), null, 2)}`
      );
    }

    const [[_request, nextResolver]] = responseResolvers.splice(
      nextResolverIndex,
      1
    );
    nextResolver(response);

    // sagas are async, so need to wait for a sec
    await new Promise((resolve) => setTimeout(resolve, 10));
  };

  const mockResponses = async (responses: MockResponse[]) => {
    for (const response of responses) {
      await mockResponse(response);
    }
    await assertNoRequest();
  };

  const assertNoRequest = async () => {
    // API requests are async, so we await for a sec so that
    // assertion is part of promise runloop
    await 1;
    if (responseResolvers.length > 0) {
      throw new Error(`There's another request`);
    }
  };

  return {
    ...mockClient,
    getPendingRequests,
    clearRequests,
    mockResponse,
    mockResponses,
    assertNoRequest,
  };
};

export type TestClient = {};

/**
 * @deprecated we should use createMockClient instead
 */

export const createMockBackend = () => {
  const client = createMockClient();

  return {
    ...client,
    get(uri: any, _config: any) {
      return client.get(uri);
    },
    delete(uri: any, _config: any) {
      return client.del(uri);
    },
    put(uri: any, data: any) {
      return client.put(uri, data);
    },
    post(uri: any, data: any) {
      return client.post(uri, data);
    },
    patch(uri: any, data: any) {
      return client.patch(uri, data);
    },
  };
};
