import { BaseAction, actionCreator } from './base';
import { Result } from '@hum/common/src/ducks/state';

export enum RequestActionType {
  REQUEST_LOADING = 'REQUEST_LOADING',
  REQUEST_FAILED = 'REQUEST_FAILED',
  REQUEST_SUCCEEDED = 'REQUEST_SUCCEEDED',
  REQUEST_STATE_CHANGED = 'REQUEST_STATE_CHANGED',
}

type BaseRequestAction<TType, TPayload = {}> = BaseAction<
  TType,
  {
    requestId: string;
  } & TPayload
>;

export type RequestLoading = BaseRequestAction<
  RequestActionType.REQUEST_LOADING,
  {
    startedAt: number;
  }
>;
export type RequestFailed = BaseRequestAction<
  RequestActionType.REQUEST_FAILED,
  {
    error: Error;
  }
>;
export type RequestSucceeded<TAction> = BaseRequestAction<
  RequestActionType.REQUEST_SUCCEEDED,
  {
    triggerAction: TAction;
    data: unknown;
  }
>;

export type BaseRequestStateChanged<TType, TData, TPayload = {}> = BaseAction<
  TType,
  TPayload & { result: Result<TData> }
>;

export const requestLoading = actionCreator<RequestLoading>(
  RequestActionType.REQUEST_LOADING
);
export const requestFailed = actionCreator<RequestFailed>(
  RequestActionType.REQUEST_FAILED
);
export const requestSucceeded = actionCreator<RequestSucceeded<any>>(
  RequestActionType.REQUEST_SUCCEEDED
);

export type RequestAction<TAction> =
  | RequestLoading
  | RequestSucceeded<TAction>
  | RequestFailed;
