import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { Scrollable } from './Scrollable';
import {
  default as StyledModal,
  _ModalContent,
  _ModalContentChildren,
} from './Modal.pc';

export const ModalContext = createContext<HTMLDivElement | null>(null);

type ModalContextProviderProps = {
  children: any;
};

export const ModalContextProvider = ({
  children,
}: ModalContextProviderProps) => {
  const [mount, setMount] = useState<HTMLDivElement | null>();
  return (
    <>
      <ModalContext.Provider value={mount as HTMLDivElement}>
        {children}
      </ModalContext.Provider>
      <div ref={setMount} />
    </>
  );
};

export type ModalProps = {
  visible?: boolean;
  closable?: boolean;
  fillMobile?: boolean;
  header?: any;
  narrow?: boolean;
  padded?: boolean;
  className?: string;
  fullHeight?: boolean;
  wide?: boolean;
  veryWide?: boolean;
  ultraWide?: boolean;
  sixtyfivePercentScreenWide?: boolean;
  testId?: string;
  children?: any;
  scrollable?: boolean;
  side?: boolean;
  footer?: any;
  onClose?: any;
  mustReachBottom?: boolean;
  onSubmit?: () => void;
  onReachBottom?: () => void;
  v5?: boolean;
  v5connected?: boolean;
  v5files?: boolean;
  isError?: boolean;
  serviceLogo?: React.ReactNode;
  serviceLogoNode?: React.ReactNode;
  serviceLogoLeft?: boolean;
};

export const Modal = ({
  header,
  side,
  closable,
  narrow,
  fillMobile,
  fullHeight,
  wide,
  scrollable,
  veryWide,
  ultraWide,
  sixtyfivePercentScreenWide,
  children,
  padded = true,
  visible = true,
  onClose,
  className,
  testId,
  footer,
  onSubmit,
  onReachBottom,
  v5,
  v5connected,
  v5files,
  isError,
  serviceLogo,
  serviceLogoNode,
  serviceLogoLeft,
}: ModalProps) => {
  const {
    isOpen,
    transitioning,
    onBackgroundClick,
    modalMount,
    modalContentRef,
  } = useModal({
    closable,
    onClose,
    visible,
    onReachBottom,
  });

  // if closed & done transitioning, then do not render modal.
  if (!isOpen && !transitioning) {
    return null;
  }

  let content = (
    <_ModalContentChildren header={!!header} padded={padded}>
      {visible && children}
    </_ModalContentChildren>
  );

  if (scrollable) {
    content = <Scrollable ref={modalContentRef}>{content}</Scrollable>;
  }

  if (!modalMount) {
    return null;
  }

  return createPortal(
    <StyledModal
      side={side}
      className={className}
      visible={isOpen}
      fillMobile={fillMobile}
      onBackgroundClick={onBackgroundClick}
      tagName={!!onSubmit ? 'form' : 'div'}
      onSubmit={onSubmit}
      testId={testId}
      v5={v5}
      v5connected={v5connected}
      v5files={v5files}
      isError={isError}
    >
      <_ModalContent
        fullHeight={fullHeight}
        halfHeight={!fullHeight && scrollable}
        padded={padded}
        wide={wide}
        veryWide={veryWide}
        ultraWide={ultraWide}
        sixtyfivePercentScreenWide={sixtyfivePercentScreenWide}
        narrow={narrow}
        footer={footer}
        header={header}
        v5={v5}
        v5connected={v5connected}
        v5files={v5files}
        serviceLogo={serviceLogo}
        serviceLogoNode={serviceLogoNode}
        serviceLogoLeft={serviceLogoLeft}
      >
        {content}
      </_ModalContent>
    </StyledModal>,
    modalMount
  );
};

type UseModalProps = {
  closable?: boolean;
  visible?: boolean;
  onClose?: any;
  onReachBottom?: () => void;
};

const SCROLL_BOTTOM_MARGIN = 20;
const IN_JSDOM = navigator.userAgent.includes('jsdom');

const useModal = ({
  closable,
  visible,
  onClose,
  onReachBottom,
}: UseModalProps) => {
  const [isOpen, setIsOpen] = useState(visible || IN_JSDOM);
  const [transitioning, setTransitioning] = useState(IN_JSDOM);
  const modalMount = useContext(ModalContext);
  const modalContentRef = useRef<any>(null);

  // bool flag for transition in / out state - used
  // to render modal
  const transition = useCallback((visible) => {
    // do not run in testing environment
    if (IN_JSDOM) {
      return;
    }

    setTransitioning(true);
    const timer = setTimeout(() => {
      setTransitioning(false);
      if (!visible && onClose) {
        onClose();
      }
    }, 70);

    return () => clearTimeout(timer);
  }, []);

  const handleScroll = (event: Event) => {
    const node = event.target as HTMLDivElement;
    const closeToBottom =
      node.scrollHeight - node.scrollTop <
      node.clientHeight + SCROLL_BOTTOM_MARGIN;
    //only set it once.
    if (closeToBottom && onReachBottom) {
      onReachBottom();
    }
  };

  useEffect(() => {
    if (IN_JSDOM) {
      return;
    }

    // slight delay so that we can trigger CSS animation
    setTimeout(() => {
      setIsOpen(Boolean(visible));
    }, 10);
    transition(Boolean(visible));
  }, [visible]);

  useEffect(() => {
    if (modalContentRef.current && onReachBottom) {
      modalContentRef.current.scrollerElement.addEventListener(
        'scroll',
        handleScroll
      );
    }
    return () => {
      if (modalContentRef.current && onReachBottom) {
        modalContentRef.current.scrollerElement.removeEventListener(
          'scroll',
          handleScroll
        );
      }
    };
  }, [modalContentRef.current]);

  const onBackgroundClick = useCallback(() => {
    if (closable === false) {
      return;
    }
    setIsOpen(false);
    transition(false);
  }, [closable]);

  return {
    onBackgroundClick,
    isOpen,
    transitioning,
    modalMount,
    modalContentRef,
  };
};
