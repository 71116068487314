import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub5Ab99334 from "./TextInput.pc";
import _pubA97D53F3 from "./atoms.pc";
import _pub824B8933, {Grid as _pub824B8933_Grid} from "./layout.pc";
import _pubDf2A042 from "./Field.pc";
import "./Fieldset.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Fieldset = React.memo(React.forwardRef(function Fieldset(props, ref) {
  return React.createElement("div", {
    "className": "_cc50571e _6d62e16b _pub-6d62e16b _pub-a97d53f3" + " " + "_6d62e16b_compact _pub-6d62e16b_compact _pub-a97d53f3_compact compact " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["squished"] ? " " + "_6d62e16b_squished _pub-6d62e16b_squished _pub-a97d53f3_squished squished" : ""),
    "ref": ref,
    "data-pc-label": "Fieldset",
  }, 
    React.createElement("div", {
      "className": "_11637ff2 _6d62e16b _pub-6d62e16b _pub-a97d53f3" + " " + "_6d62e16b_text-heading-small _pub-6d62e16b_text-heading-small _pub-a97d53f3_text-heading-small text-heading-small",
    }, 
      props["label"]
    ),
    React.createElement("div", {
      "className": "_886a2e48 _6d62e16b _pub-6d62e16b _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { Fieldset };


