import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirmation, getConfirmations, confirmationActions } from '../ducks';
import { ConfirmationModal } from './ConfirmationModal';

export type ConfirmationsContainer2Props = {
  children: any;
};

export const ConfirmationsContainer2 = ({
  children,
}: ConfirmationsContainer2Props) => {
  const confirmatons = useSelector(getConfirmations);
  return (
    <>
      {confirmatons.map((confirmation, i) => {
        return <ConfirmationModal2 key={i} confirmation={confirmation} />;
      })}
      {children}
    </>
  );
};

type ConfirmationModal2Props = {
  confirmation: Confirmation;
};

const ConfirmationModal2 = (props: ConfirmationModal2Props) => {
  const { confirmation } = props;
  const { onCancel, onConfirm } = useConfirmationModal(props);
  return (
    <ConfirmationModal
      title={confirmation.message}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

const useConfirmationModal = ({ confirmation }: ConfirmationModal2Props) => {
  const dispatch = useDispatch();
  const onCancel = () => {
    dispatch(confirmationActions.cancel(confirmation));
  };
  const onConfirm = () => {
    dispatch(confirmation.okAction);
  };

  return { onConfirm, onCancel };
};
