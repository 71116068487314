import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F from "./utils.pc";
import "./Separator.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_97c87392 _61e4905a _pub-61e4905a _pub-a97d53f3" + (props["light"] ? " " + "_61e4905a_light _pub-61e4905a_light _pub-a97d53f3_light light" : "") + (props["vertical"] ? " " + "_61e4905a_vertical _pub-61e4905a_vertical _pub-a97d53f3_vertical vertical" : ""),
    "ref": ref,
  }, null)
}));
export default $$Default;


