import React, {
  memo,
  ReactNode,
  cloneElement,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import * as styles from './index.pc';
import noop from 'lodash/noop';

const MENU_BACKGROUND_STYLE = {
  position: 'fixed',
  zIndex: 999,
  top: 0,
  left: 0,
  background: 'transparent',
  width: `100vw`,
  height: `100vh`,
};

type MenuRenderer = () => ReactNode;

export type ContextMenuProps = {
  onOpen?: () => void;
  renderMenu: () => ReactNode;
  children: React.ReactElement;
};

type ContextValue = {
  setMenuRenderer: (info: {
    renderer: MenuRenderer;
    onClose: () => void;
  }) => void;
};

export const Context = React.createContext<ContextValue>({
  setMenuRenderer: noop,
});

type ContextMenuContainerProps = {
  children: any;
};

export const ContextMenuContainer = ({
  children,
}: ContextMenuContainerProps) => {
  let [menuStyle, setMenuStyle] = useState<Record<string, any>>({
    display: 'none',
    userSelect: 'none',
    zIndex: 9999,
  });
  let [currentMenuInfo, setMenuRenderer] = useState<{
    renderer: MenuRenderer;
    onClose: () => void;
  } | null>(null);

  useEffect(() => {
    // ignore testing environment
    if (
      navigator.userAgent.includes('Node.js') ||
      navigator.userAgent.includes('jsdom')
    ) {
      return;
    }

    const onContextMenu = (event: MouseEvent) => {
      const openContextMenuEvent = new Event('OPEN_CONTEXT_MENU', {
        bubbles: true,
        cancelable: true,
      });

      // if prevent defaulted, then we've right clicked something that
      // has ContextMenu wrapped around it. In that case, show the menu. Otherwise
      // show the native menu.
      if (!event.target?.dispatchEvent(openContextMenuEvent)) {
        event.preventDefault();
        setMenuStyle({
          ...menuStyle,
          display: 'block',
          position: 'fixed',
          left: event.clientX,
          top: event.clientY,
        });
      }
    };

    const onDocumentMovement = () => {
      if (currentMenuInfo) {
        currentMenuInfo.onClose();
      }
      setMenuRenderer(null);
      setMenuStyle({
        ...menuStyle,
        display: 'none',
      });
    };

    //const preventEvent = (event: any) => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //};

    document.addEventListener('contextmenu', onContextMenu);
    document.addEventListener('click', onDocumentMovement);
    //document.addEventListener('scroll', preventEvent, { capture: true });

    return () => {
      document.removeEventListener('contextmenu', onContextMenu);
      document.removeEventListener('click', onDocumentMovement);
      //document.removeEventListener('scroll', preventEvent, {
      //  capture: true,
      //});
    };
  });

  return (
    <>
      <Context.Provider value={{ setMenuRenderer }}>
        {children}
      </Context.Provider>
      {currentMenuInfo && (
        <div>
          <div
            id="context-menu-background"
            style={MENU_BACKGROUND_STYLE as any}
          ></div>
          <div id="context-menu" style={menuStyle}>
            <styles.Menu>{currentMenuInfo.renderer()}</styles.Menu>
          </div>
        </div>
      )}
    </>
  );
};

/**
 * Actual context menu that's attached to each item
 */

export const ContextMenu = memo(
  ({ children, onOpen = noop, renderMenu }: ContextMenuProps) => {
    const ref = useRef<Element>();
    const { setMenuRenderer } = useContext(Context);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      const current = ref.current;
      if (!current) {
        return;
      }

      const onContextMenu = (event: CustomEvent) => {
        event.preventDefault();
        onOpen();
        setIsOpen(true);
      };
      current.addEventListener('OPEN_CONTEXT_MENU', onContextMenu as any);
      return () => {
        current.removeEventListener('OPEN_CONTEXT_MENU', onContextMenu as any);
      };
    }, [ref]);

    useEffect(() => {
      if (isOpen) {
        setMenuRenderer({
          renderer: renderMenu,
          onClose: () => {
            setIsOpen(false);
          },
        });
      }
    }, [isOpen]);

    return cloneElement(children, {
      ref: (v) => {
        ref.current = v;
        const { ref: ref2 } = children as any;
        if (typeof ref2 === 'function') {
          ref2(v);
        } else if (ref2) {
          ref2.current = v;
        }
      },
    });
  }
);

const Item = styles.Item;
const Group = styles.Group;

export { Item, Group };
