import { useMediaQuery } from './useMediaQuery';

// from breakpoints - TODO - need to pull this information in from JSON file that's
// compiled to PC
export const useBreakpoints = () => {
  return {
    medium: useMediaQuery(`(max-width: 800px)`),
    small: useMediaQuery(`(max-width: 550px)`),
  };
};
