import { Action } from 'redux';
import { produce } from 'immer';
import { ConfirmationsAppState } from './state';
import { confirmationActions } from './actions';

export const confirmationReducer = (
  state: ConfirmationsAppState,
  action: Action
) => {
  if (state.confirmations.length) {
    const index = state.confirmations.findIndex(
      (confirmation) => confirmation.okAction.type === action.type
    );
    if (index !== -1) {
      state = produce(state, (newState) => {
        newState.confirmations.splice(index, 1);
      });
    }
  }
  if (action.type === confirmationActions.cancel.type) {
    state = produce(state, (newState) => {
      newState.confirmations = newState.confirmations.filter((confirmation) => {
        return (
          confirmation ===
          (action as ReturnType<typeof confirmationActions.cancel>).payload
        );
      });
    });
  }
  return state;
};
