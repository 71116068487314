import React from "react";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Menu = React.memo(React.forwardRef(function Menu(props, ref) {
  return React.createElement("div", {
    "className": "_9bd0d404 _ffb4a5fb _pub-ffb4a5fb _pub-a97d53f3" + " " + "_ffb4a5fb_Menu _pub-ffb4a5fb_Menu _pub-a97d53f3_Menu Menu",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Menu };

var Group = React.memo(React.forwardRef(function Group(props, ref) {
  return React.createElement("div", {
    "className": "_72b37131 _ffb4a5fb _pub-ffb4a5fb _pub-a97d53f3" + " " + "_ffb4a5fb_Group _pub-ffb4a5fb_Group _pub-a97d53f3_Group Group",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Group };

var Shortcut = React.memo(React.forwardRef(function Shortcut(props, ref) {
  return React.createElement("div", {
    "className": "_9cbd101d _ffb4a5fb _pub-ffb4a5fb _pub-a97d53f3" + " " + "_ffb4a5fb_Shortcut _pub-ffb4a5fb_Shortcut _pub-a97d53f3_Shortcut Shortcut",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Shortcut };

var MoreButton = React.memo(React.forwardRef(function MoreButton(props, ref) {
  return React.createElement("div", {
    "className": "_7b053d1a _ffb4a5fb _pub-ffb4a5fb _pub-a97d53f3" + " " + "_ffb4a5fb_MoreButton _pub-ffb4a5fb_MoreButton _pub-a97d53f3_MoreButton MoreButton",
    "ref": ref,
  }, null)
}));
export { MoreButton };

var Item = React.memo(React.forwardRef(function Item(props, ref) {
  return React.createElement("div", {
    "className": "_b61c99f8 _ffb4a5fb _pub-ffb4a5fb _pub-a97d53f3" + " " + "_ffb4a5fb_Item _pub-ffb4a5fb_Item _pub-a97d53f3_Item Item" + (props["disabled"] ? " " + "_ffb4a5fb_Item--disabled _pub-ffb4a5fb_Item--disabled _pub-a97d53f3_Item--disabled Item--disabled" : "") + (props["selected"] ? " " + "_ffb4a5fb_Item--selected _pub-ffb4a5fb_Item--selected _pub-a97d53f3_Item--selected Item--selected" : "") + (props["hover"] ? " " + "_ffb4a5fb_Item--hover _pub-ffb4a5fb_Item--hover _pub-a97d53f3_Item--hover Item--hover" : "") + (props["more"] ? " " + "_ffb4a5fb_Item--more _pub-ffb4a5fb_Item--more _pub-a97d53f3_Item--more Item--more" : ""),
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    React.createElement("span", {
      "className": "_129c8ef5 _ffb4a5fb _pub-ffb4a5fb _pub-a97d53f3" + " " + "_ffb4a5fb_item-label _pub-ffb4a5fb_item-label _pub-a97d53f3_item-label item-label",
    }, 
      props["children"]
    ),
    props["info"]
  )
}));
export { Item };


