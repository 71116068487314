import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./icons.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var XeroIcon = React.memo(React.forwardRef(function XeroIcon(props, ref) {
  return React.createElement("i", {
    "className": "_c41d2f9b _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { XeroIcon };

var HumPrimaryIcon = React.memo(React.forwardRef(function HumPrimaryIcon(props, ref) {
  return React.createElement("i", {
    "className": "_2d7e8aae _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { HumPrimaryIcon };

var EyeIcon = React.memo(React.forwardRef(function EyeIcon(props, ref) {
  return React.createElement("i", {
    "className": "_c370eb82 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { EyeIcon };

var QBIcon = React.memo(React.forwardRef(function QBIcon(props, ref) {
  return React.createElement("i", {
    "className": "_24c8c685 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { QBIcon };

var NetsuiteIcon = React.memo(React.forwardRef(function NetsuiteIcon(props, ref) {
  return React.createElement("i", {
    "className": "_d6f3dad6 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { NetsuiteIcon };

var ArrowRightIcon = React.memo(React.forwardRef(function ArrowRightIcon(props, ref) {
  return React.createElement("i", {
    "className": "_38fdbbfa _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { ArrowRightIcon };

var DescriptionIcon = React.memo(React.forwardRef(function DescriptionIcon(props, ref) {
  return React.createElement("i", {
    "className": "_d19e1ecf _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { DescriptionIcon };

var RevenueIcon = React.memo(React.forwardRef(function RevenueIcon(props, ref) {
  return React.createElement("i", {
    "className": "_3f907fe3 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { RevenueIcon };

var RevenueGrowthIcon = React.memo(React.forwardRef(function RevenueGrowthIcon(props, ref) {
  return React.createElement("i", {
    "className": "_d82852e4 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { RevenueGrowthIcon };

var GrossMarginsIcon = React.memo(React.forwardRef(function GrossMarginsIcon(props, ref) {
  return React.createElement("i", {
    "className": "_fdde8915 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { GrossMarginsIcon };

var LtvCacIcon = React.memo(React.forwardRef(function LtvCacIcon(props, ref) {
  return React.createElement("i", {
    "className": "_13d0e839 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { LtvCacIcon };

var ValuationIcon = React.memo(React.forwardRef(function ValuationIcon(props, ref) {
  return React.createElement("i", {
    "className": "_fab34d0c _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { ValuationIcon };

var EquityIcon = React.memo(React.forwardRef(function EquityIcon(props, ref) {
  return React.createElement("i", {
    "className": "_14bd2c20 _9f4190d6 _pub-9f4190d6" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { EquityIcon };

var IconsPreview = React.memo(React.forwardRef(function IconsPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_f3050127 _9f4190d6 _pub-9f4190d6" + " " + "text-body-medium",
    "ref": ref,
    "flex": true,
    "padded": true,
  }, 
    React.createElement("div", {
      "className": "_eae8094a _9f4190d6 _pub-9f4190d6",
    }, 
      React.createElement(XeroIcon, {
        "class": "_4367bde9",
      }, null),
      React.createElement(HumPrimaryIcon, {
        "class": "_da6eec53",
      }, null),
      React.createElement(EyeIcon, {
        "class": "_ad69dcc5",
      }, null),
      React.createElement(QBIcon, {
        "class": "_330d4966",
      }, null),
      React.createElement(NetsuiteIcon, {
        "class": "_440a79f0",
      }, null),
      React.createElement(ArrowRightIcon, {
        "class": "_dd03284a",
      }, null),
      React.createElement(RevenueIcon, {
        "class": "_aa0418dc",
      }, null),
      React.createElement(RevenueGrowthIcon, {
        "class": "_3abb054d",
      }, null),
      React.createElement(GrossMarginsIcon, {
        "class": "_4dbc35db",
      }, null),
      React.createElement(LtvCacIcon, {
        "class": "_2d4ee240",
      }, null),
      React.createElement(ValuationIcon, {
        "class": "_5a49d2d6",
      }, null),
      React.createElement(EquityIcon, {
        "class": "_c340836c",
      }, null)
    )
  )
}));
export { IconsPreview };


