/**
 * Composes multiple data handlers into a single, ordered handling mechanism.
 *
 * @param handlers A list of data handlers.
 */
const composeHandlers = (...handlers: Function[]) => async (
  ...args: unknown[]
) => {
  let resolved;

  for (const handler of handlers) {
    // eslint-disable-next-line no-await-in-loop
    if ((resolved = await handler(...args)) !== undefined) {
      // if handler resolved, shortcut exit.
      return resolved;
    }
  }

  // if no handler resolved/threw, resolve to parent root.
  return args[0];
};

export { composeHandlers };
