import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Scrollable.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Track = React.memo(React.forwardRef(function Track(props, ref) {
  return React.createElement("div", {
    "className": "_a27a5819 _c68937f5 _pub-c68937f5 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Track };

var Thumb = React.memo(React.forwardRef(function Thumb(props, ref) {
  return React.createElement("div", {
    "className": "_4b19fd2c _c68937f5 _pub-c68937f5 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "onMouseDown": props["onMouseDown"],
  }, 
    props["children"]
  )
}));
export { Thumb };

var Content = React.memo(React.forwardRef(function Content(props, ref) {
  return React.createElement("div", {
    "className": "_a5179c00 _c68937f5 _pub-c68937f5 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Content };

var ScrollablePreview = React.memo(React.forwardRef(function ScrollablePreview(props, ref) {
  return React.createElement("div", {
    "className": "_42afb107 _c68937f5 _pub-c68937f5 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-pc-label": "Scrollable Preview",
  }, 
    React.createElement(Content, {
      "class": "_5a034ee2",
    }, 
      props["children"]
    ),
    React.createElement(Track, {
      "class": "_b360ebd7",
    }, 
      React.createElement(Thumb, {
        "class": "_c98c9362",
      }, null)
    )
  )
}));
export { ScrollablePreview };


