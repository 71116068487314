import React, { CSSProperties } from 'react';
import Scrollbars from 'react-scrollbars-custom';
import { useBreakpoints } from '@hum/common/src/hooks/useBreakpoints';
import * as styles from './Scrollable.pc';

type Props = {
  className?: string;
  style?: CSSProperties;
  scrollerStyle?: CSSProperties;
  children: React.ReactNode;
  permanentTracks?: boolean;
  ref?: any;
};

const Scrollable = React.forwardRef<Scrollbars, Props>(
  (
    { scrollerStyle, children, style, className, permanentTracks = false },
    ref
  ) => {
    const { small: isSmall } = useBreakpoints();

    return (
      <Scrollbars
        // @ts-ignore
        ref={ref}
        //  need to keep this on to prevent content from jumping
        disableTracksWidthCompensation={true}
        removeTrackYWhenNotUsed={false}
        // keep this on to prevent content from jumping
        permanentTracks={permanentTracks}
        native={isSmall}
        children={children}
        className={className}
        style={style}
        contentProps={{
          renderer: ({ elementRef, style: _style, as: _as, ...props }) => (
            <styles.Content {...(props as any)} ref={elementRef} />
          ),
        }}
        scrollerProps={{
          renderer: ({ elementRef, style: defaultScrollerStyle, ...props }) =>
            (
              <span
                {...(props as any)}
                style={{ ...defaultScrollerStyle, ...scrollerStyle }}
                ref={elementRef}
              />
            ) as any,
        }}
        thumbXProps={{
          renderer: ({ elementRef, style: _style, as: _as, ...props }) => (
            <styles.Thumb {...(props as any)} ref={elementRef} />
          ),
        }}
        trackXProps={{
          renderer: ({ elementRef, style: _style, as: _as, ...props }) => (
            <styles.Track {...(props as any)} ref={elementRef} />
          ),
        }}
        thumbYProps={{
          renderer: ({ elementRef, style: _style, as: _as, ...props }) => (
            <styles.Thumb {...(props as any)} ref={elementRef} />
          ),
        }}
        trackYProps={{
          renderer: ({ elementRef, style: _style, as: _as, ...props }) => (
            <styles.Track {...(props as any)} ref={elementRef} />
          ),
        }}
      />
    );
  }
);

export { Scrollable };
