import { useMemo } from 'react';
import { FieldType, Form } from '../types';
import { useEnsuredForm } from './useEnsuredForm';

/**
 * Performatic custom version of useField.
 *
 * This WILL re-render every time a value change on the form, but
 * the returned reference only changes if there was changes to the
 * hooked field. Simple equality checks can be done on the
 * returned object (such as use on useMemo 2nd argument).
 *
 * @see https://github.com/facebook/react/issues/14476#issuecomment-471199055
 * @see https://github.com/facebook/react/issues/15156#issuecomment-474590693
 */

export function useField<TValues, PassedForm = Form<TValues>>(
  name: string,
  type: FieldType = 'text',
  currentForm?: PassedForm
) {
  const form = useEnsuredForm(currentForm);
  const [input, meta] = form.getFieldProps({ name, type });

  return useMemo(() => {
    const onValueChange = (eventOrValue: unknown) => {
      form.setFieldValue(name, eventOrValue);
    };

    return {
      meta,
      input: {
        ...input,

        onValueChange,

        // extra
        disabled: form.isSubmitting,
        error: meta.touched ? meta.error : undefined,
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(meta), form.isSubmitting, form.submitCount]);
}
