import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pubD91D8869 from "./CheckCircleIcon.pc";
import "./Checklist.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_fb23785d _74092d51 _pub-74092d51 _pub-a97d53f3" + (props["subdued"] ? " " + "_74092d51_subdued _pub-74092d51_subdued _pub-a97d53f3_subdued subdued" : "") + (props["slim"] ? " " + "_74092d51_slim _pub-74092d51_slim _pub-a97d53f3_slim slim" : "") + (props["padded"] ? " " + "_74092d51_padded _pub-74092d51_padded _pub-a97d53f3_padded padded" : ""),
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    React.createElement("div", {
      "className": "_61e6691e _74092d51 _pub-74092d51 _pub-a97d53f3",
    }, 
      props["icon"]&&React.createElement("div", {
        "className": "_c4be2207 _74092d51 _pub-74092d51 _pub-a97d53f3",
      }, 
        props["icon"]
      ),
      React.createElement("span", {
        "className": "_490b9e55 _74092d51 _pub-74092d51 _pub-a97d53f3",
      }, 
        props["description"]
      )
    ),
    React.createElement("div", {
      "className": "_f8ef38a4 _74092d51 _pub-74092d51 _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export default $$Default;

var ChecklistItem = React.memo(React.forwardRef(function ChecklistItem(props, ref) {
  return React.createElement("div", {
    "className": "_152d1971 _74092d51 _pub-74092d51 _pub-a97d53f3" + (props["complete"] ? " " + "_74092d51_complete _pub-74092d51_complete _pub-a97d53f3_complete complete" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_fb6becca _74092d51 _pub-74092d51 _pub-a97d53f3",
    }, null),
    React.createElement("div", {
      "className": "_120849ff _74092d51 _pub-74092d51 _pub-a97d53f3",
    }, 
      React.createElement(_pubD91D8869, {
        "class": "_ac095e64 _74092d51 _pub-74092d51 _pub-a97d53f3",
        "small": true,
      }, null),
      React.createElement("div", {
        "className": "_35000fde _74092d51 _pub-74092d51 _pub-a97d53f3" + " " + "_74092d51_stick _pub-74092d51_stick _pub-a97d53f3_stick stick",
      }, null)
    ),
    React.createElement("div", {
      "className": "_650f7969 _74092d51 _pub-74092d51 _pub-a97d53f3" + " " + "_74092d51_content _pub-74092d51_content _pub-a97d53f3_content content",
    }, 
      props["children"]
    )
  )
}));
export { ChecklistItem };

var ChecklistPreview = React.memo(React.forwardRef(function ChecklistPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_f2953476 _74092d51 _pub-74092d51 _pub-a97d53f3",
    "ref": ref,
    "padded": true,
  }, 
    React.createElement($$Default, {
      "class": "_1ffba0ec",
      "description": "Something descriptive",
    }, 
      React.createElement(ChecklistItem, {
        "class": "_6337784f",
        "complete": true,
      }, 
        "I'm an item"
        
      ),
      React.createElement(ChecklistItem, {
        "class": "_143048d9",
        "complete": true,
      }, 
        "I'm an item"
        
      ),
      React.createElement(ChecklistItem, {
        "class": "_8d391963",
      }, 
        "I'm an item"
        
      )
    )
  )
}));
export { ChecklistPreview };

var ChecklistPreview2 = React.memo(React.forwardRef(function ChecklistPreview2(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_f2912482 _74092d51 _pub-74092d51 _pub-a97d53f3",
    "ref": ref,
    "padded": true,
  }, 
    React.createElement($$Default, {
      "class": "_729c9435",
      "slim": true,
      "description": "Some description about the checklist",
    }, 
      React.createElement(ChecklistItem, {
        "class": "_12799fcb",
      }, 
        "I'm an item"
        
      ),
      React.createElement(ChecklistItem, {
        "class": "_657eaf5d",
      }, 
        "I'm an item"
        
      ),
      React.createElement(ChecklistItem, {
        "class": "_fc77fee7",
      }, 
        "I'm an item"
        
      )
    )
  )
}));
export { ChecklistPreview2 };


