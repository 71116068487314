import React, { memo, useCallback, useState } from 'react';
import Tippy from '@tippy.js/react';
import * as styles from './Menu.pc';
import { useHistory } from 'react-router';

export type MoreMenuProps = {
  children: any;
  disabled?: boolean;
  w1?: boolean;
  secondaryButton?: boolean;
  secondary?: boolean;
  testId?: string;
  label?: any;
};

export const MoreMenu = ({
  children,
  disabled,
  secondary,
  secondaryButton,
  w1,
  testId,
  label,
}: MoreMenuProps) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const onLabelClick = useCallback(
    () => !disabled && setMenuVisible(!menuVisible),
    [menuVisible]
  );

  return (
    <Tippy
      interactive
      hideOnClick
      className={styles.classNames['tippy-reset']}
      trigger="click"
      onHide={() => setMenuVisible(false)}
      aria={null}
      role="menu"
      appendTo="parent"
      visible={menuVisible}
      distance={0}
      animateFill={false}
      animation="shift-toward"
      placement="bottom-end"
      content={
        <styles.default
          w1={Boolean(w1)}
          secondary={secondary}
          absolute
          onClick={() => setMenuVisible(false)}
        >
          {children}
        </styles.default>
      }
    >
      {label ? (
        <styles.MenuLabel onClick={onLabelClick} testId={testId}>
          {label}
        </styles.MenuLabel>
      ) : (
        <styles.MenuButton
          testId="open:menu"
          disabled={disabled}
          secondaryButton={secondaryButton}
          onClick={onLabelClick}
        />
      )}
    </Tippy>
  );
};

type MoreMenuItemProps = {
  href?: string;
  checked?: boolean;
  onClick?: () => void;
  children: any;
  testId?: string;
  danger?: boolean;
};

export const MoreMenuItem = memo(
  ({ href, onClick, checked, children, testId, danger }: MoreMenuItemProps) => {
    const history = useHistory();

    const onClick2 = useCallback(() => {
      if (href) {
        history.push(href);
      } else if (onClick) {
        onClick();
      }
    }, [onClick]);

    return (
      <styles.MenuItem
        checked={checked}
        testId={testId}
        onClick={onClick2}
        danger={danger}
      >
        {children}
      </styles.MenuItem>
    );
  }
);

export const MoreMenuGroup = styles.MenuItemGroup;
