import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pub9F4190D6 from "./icons.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Divider.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_fa466da3 _f755ae64 _pub-f755ae64 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("p", {
      "className": "_860440a3 _f755ae64 _pub-f755ae64 _pub-a97d53f3" + " " + "_f755ae64_text-body-small _pub-f755ae64_text-body-small _pub-a97d53f3_text-body-small text-body-small",
    }, 
      props["children"]
    )
  )
}));
export default $$Default;

var UtilsPreview = React.memo(React.forwardRef(function UtilsPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_14480c8f _f755ae64 _pub-f755ae64 _pub-a97d53f3" + " " + "_f755ae64_text-body-medium _pub-f755ae64_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    "ref": ref,
    "flex": true,
  }, 
    React.createElement($$Default, {
      "class": "_858094cd",
    }, 
      "OR"
      
    )
  )
}));
export { UtilsPreview };


