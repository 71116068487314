import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useCallback, useMemo } from 'react';

/**
 * @deprecated
 */
export const useQuery = (): Record<string, string> => {
  const location = useHistory().location;
  return useMemo(() => {
    return qs.parse(location.search) as Record<string, string>;
  }, [location.search]);
};

type UpdateQuery = (query: any, replace?: boolean) => void;

export const useUrlQuery = <TQuery = any>(): [TQuery, UpdateQuery] => {
  const history = useHistory();

  const query = (useMemo(() => {
    return qs.parse(history.location.search);
  }, [history.location.search]) as any) as TQuery;

  const updateQuery = useCallback(
    (properties: Record<string, string | number>, replace = false) => {
      const newQuery: any = replace ? properties : { ...query, ...properties };
      history.push(history.location.pathname + '?' + qs.stringify(newQuery));
    },
    [query, history.location]
  );

  return [query, updateQuery];
};
