import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import "./Pill.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_576e1bcf _4435fe58 _pub-4435fe58 _pub-a97d53f3" + (props["bold"] ? " " + "_4435fe58_bold _pub-4435fe58_bold _pub-a97d53f3_bold bold" : "") + (props["transparent"] ? " " + "_4435fe58_transparent _pub-4435fe58_transparent _pub-a97d53f3_transparent transparent" : "") + (props["positive"] ? " " + "_4435fe58_positive _pub-4435fe58_positive _pub-a97d53f3_positive positive" : "") + (props["negative"] ? " " + "_4435fe58_negative _pub-4435fe58_negative _pub-a97d53f3_negative negative" : "") + (props["notice"] ? " " + "_4435fe58_notice _pub-4435fe58_notice _pub-a97d53f3_notice notice" : "") + (props["info"] ? " " + "_4435fe58_info _pub-4435fe58_info _pub-a97d53f3_info info" : "") + (props["secondary"] ? " " + "_4435fe58_secondary _pub-4435fe58_secondary _pub-a97d53f3_secondary secondary" : "") + " " + "_4435fe58_ds-pill _pub-4435fe58_ds-pill _pub-a97d53f3_ds-pill ds-pill " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-pc-label": "Pill",
    "title": props["title"],
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export default $$Default;

var PillsPreview = React.memo(React.forwardRef(function PillsPreview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_b9607ae3 _4435fe58 _pub-4435fe58 _pub-a97d53f3",
    "ref": ref,
    "spaced": true,
  }, 
    React.createElement($$Default, {
      "class": "_82a9f9d1",
    }, 
      "Base"
      
    ),
    React.createElement($$Default, {
      "class": "_f5aec947",
      "positive": true,
    }, 
      "Positive"
      
    ),
    React.createElement($$Default, {
      "class": "_6ca798fd",
      "negative": true,
    }, 
      "Negative"
      
    ),
    React.createElement($$Default, {
      "class": "_1ba0a86b",
      "notice": true,
    }, 
      "Notice"
      
    ),
    React.createElement($$Default, {
      "class": "_85c43dc8",
      "info": true,
    }, 
      "Informative"
      
    ),
    React.createElement($$Default, {
      "class": "_f2c30d5e",
      "secondary": true,
    }, 
      "Secondary"
      
    ),
    React.createElement($$Default, {
      "class": "_6bca5ce4",
      "bold": true,
      "positive": true,
    }, 
      "Bold positive"
      
    ),
    React.createElement($$Default, {
      "class": "_1ccd6c72",
      "bold": true,
      "negative": true,
    }, 
      "bold negative"
      
    ),
    React.createElement($$Default, {
      "class": "_8c7271e3",
      "bold": true,
      "notice": true,
    }, 
      "bold notice"
      
    ),
    React.createElement($$Default, {
      "class": "_fb754175",
      "bold": true,
      "info": true,
    }, 
      "bold informative"
      
    ),
    React.createElement($$Default, {
      "class": "_1c96a5db",
      "transparent": true,
      "positive": true,
    }, 
      "Transparent positive"
      
    ),
    React.createElement($$Default, {
      "class": "_6b91954d",
      "transparent": true,
      "negative": true,
    }, 
      "Transparent negative"
      
    ),
    React.createElement($$Default, {
      "class": "_f298c4f7",
      "transparent": true,
      "notice": true,
    }, 
      "Transparent notice"
      
    ),
    React.createElement($$Default, {
      "class": "_859ff461",
      "transparent": true,
      "info": true,
    }, 
      "Transparent informative"
      
    )
  )
}));
export { PillsPreview };


