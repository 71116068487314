import React from "react";
import _pubA97D53F3 from "./atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pub824B8933, {Box as _pub824B8933_Box} from "./layout.pc";
import _pub2F51C399 from "./breakpoints.pc";
import "./Detail.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_7f9e3eba _5448a231 _pub-5448a231 _pub-a97d53f3" + " " + "_5448a231_ds-detail _pub-5448a231_ds-detail _pub-a97d53f3_ds-detail ds-detail " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["header"] ? " " + "_5448a231_header _pub-5448a231_header _pub-a97d53f3_header header" : "") + (props["centered"] ? " " + "_5448a231_centered _pub-5448a231_centered _pub-a97d53f3_centered centered" : "") + (props["horizontal"] ? " " + "_5448a231_horizontal _pub-5448a231_horizontal _pub-a97d53f3_horizontal horizontal" : "") + (props["helper"] ? " " + "_5448a231_text-helper _pub-5448a231_text-helper _pub-a97d53f3_text-helper text-helper" : "") + (props["subtitle"] ? " " + "_5448a231_subtitle _pub-5448a231_subtitle _pub-a97d53f3_subtitle subtitle" : "") + (props["mobile"] ? " " + "_5448a231_mobile _pub-5448a231_mobile _pub-a97d53f3_mobile mobile" : "") + (props["medium"] ? " " + "_5448a231_text-body-medium _pub-5448a231_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium" : "") + (props["padded"] ? " " + "_5448a231_padded _pub-5448a231_padded _pub-a97d53f3_padded padded" : "") + (props["marginBottom"] ? " " + "_5448a231_margin-bottom _pub-5448a231_margin-bottom _pub-a97d53f3_margin-bottom margin-bottom" : "") + (props["marginBottomLarge"] ? " " + "_5448a231_margin-bottom-large _pub-5448a231_margin-bottom-large _pub-a97d53f3_margin-bottom-large margin-bottom-large" : "") + (props["wideDescription"] ? " " + "_5448a231_wide-description _pub-5448a231_wide-description _pub-a97d53f3_wide-description wide-description" : "") + (props["fullwidth"] ? " " + "_5448a231_fullwidth _pub-5448a231_fullwidth _pub-a97d53f3_fullwidth fullwidth" : "") + (props["soft"] ? " " + "_5448a231_soft _pub-5448a231_soft _pub-a97d53f3_soft soft" : ""),
    "ref": ref,
  }, 
    props["title"]&&React.createElement("div", {
      "className": "_b1ebe538 _5448a231 _pub-5448a231 _pub-a97d53f3" + " " + "_5448a231_text-body-medium _pub-5448a231_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium" + (props["helper"] ? " " + "_5448a231_text-helper _pub-5448a231_text-helper _pub-a97d53f3_text-helper text-helper" : "") + (props["header"] ? " " + "_5448a231_text-heading-small _pub-5448a231_text-heading-small _pub-a97d53f3_text-heading-small text-heading-small" : "") + (props["padded"] ? " " + "_5448a231_padded _pub-5448a231_padded _pub-a97d53f3_padded padded" : "") + (props["soft"] ? " " + "_5448a231_soft _pub-5448a231_soft _pub-a97d53f3_soft soft" : ""),
    }, 
      props["title"]
    ),
    props["description"]&&React.createElement("div", {
      "className": "_9ac6b6fb _5448a231 _pub-5448a231 _pub-a97d53f3",
      "data-testid": props["testId"],
      "data-pc-label": "Description",
    }, 
      props["description"]
    )
  )
}));
export default $$Default;


