/* eslint-disable no-shadow, no-sequences, prefer-promise-reject-errors */
import React, { useState, useContext, useMemo } from 'react';
import { ConfirmationModal } from '@hum/icm-app/src/components/ConfirmationModal';
import omit from 'lodash/omit';

type Store = { [key: string]: any };

let addConfirmation = (..._args: any[]) => {};
let removeConfirmation = (..._args: any[]) => {};

const store: Store = {};

type ConfirmationInnerProps = {
  confirmations: Store;
};

const ConfirmationInner = ({ confirmations }: ConfirmationInnerProps) => {
  return (
    <div>
      {Object.keys(confirmations).map((key) => (
        <ConfirmationModal {...(confirmations[key] as any)} key={key} />
      ))}
    </div>
  );
};

export type ConfirmationsContainerProps = {
  children: any;
};

export const ConfirmationsContainer = ({
  children,
}: ConfirmationsContainerProps) => {
  const [confirmations, setConfirmations] = useState<Store>({});

  const addConfirmation = (name: string, el: React.ReactElement) => {
    setConfirmations({
      ...confirmations,
      [name]: el,
    });
  };

  const removeConfirmation = (name: string) => {
    setConfirmations(omit(confirmations, name));
  };

  const state = useMemo(() => {
    return {
      addConfirmation,
      removeConfirmation,
    };
  }, [addConfirmation, removeConfirmation]);

  return (
    <ConfirmationsContext.Provider value={state}>
      <ConfirmationInner confirmations={confirmations} />
      {children}
    </ConfirmationsContext.Provider>
  );
};

/**
 * @deprecated
 */

export const GlobalConfirmations = () => {
  const [confirmations, setConfirmations] = useState<typeof store>(store);

  // simple refresh
  addConfirmation = (name, el) => setConfirmations({ ...store, [name]: el });
  removeConfirmation = (name: string) => setConfirmations(omit(store, name));

  return <ConfirmationInner confirmations={confirmations} />;
};
const ConfirmationsContext = React.createContext<{
  addConfirmation: any;
  removeConfirmation: any;
}>({ addConfirmation: () => {}, removeConfirmation: () => {} });

type Config = {
  danger?: boolean;
  noScroll?: boolean;
  style?: any;
  cancel?: string | false;
  confirm?: string;
  message: string;
  onConfirm?: (close: (resolution: boolean) => void) => Promise<void> | void;
  onCancel?: (close: (resolution: boolean) => void) => Promise<void> | void;
};

/**
 * @deprecated
 */
const makeConfirm = (addConfirmation, removeConfirmation) => (
  message: string | Config,
  title?: string | null
) =>
  new Promise((resolve) => {
    const current = String(Math.random());

    const close = (resolution: boolean = true) => {
      removeConfirmation(current);
      resolve(resolution);
    };

    const config = typeof message === 'string' ? { message } : message;

    const defaults = {
      title: title === null ? undefined : title,
      style: { zIndex: 1000 },
      onConfirm: (close: any) => close(true),
      onCancel: (close: any) => close(false),
    };

    const options = { ...defaults, ...config };

    addConfirmation(current, {
      ...{
        ...options,
        isOpen: true,
        onConfirm: () => options.onConfirm!(close),
        onCancel: () => options.onCancel!(close),
        onRequestClose: () => options.onCancel!(close),
      },
    });
  });

const makeAlert = (addConfirmation, removeConfirmation) => (
  message: string | Omit<Config, 'cancel'>,
  title?: string | null
) =>
  makeConfirm(
    addConfirmation,
    removeConfirmation
  )({
    cancel: false,
    confirm: 'Ok',
    ...{ title: title === null ? undefined : 'Alert' },
    ...(typeof message === 'string' ? { message } : message),
  });

export const useConfirmations = () => {
  const { addConfirmation, removeConfirmation } = useContext(
    ConfirmationsContext
  );

  return {
    alert: useMemo(() => makeAlert(addConfirmation, removeConfirmation), [
      addConfirmation,
      removeConfirmation,
    ]),
    confirm: useMemo(() => makeConfirm(addConfirmation, removeConfirmation), [
      addConfirmation,
      removeConfirmation,
    ]),
  };
};

export const Confirmations = GlobalConfirmations;
export const confirm = makeConfirm(
  (...args) => addConfirmation(...args),
  (...args) => removeConfirmation(...args)
);
export const alert = makeAlert(
  (...args) => addConfirmation(...args),
  (...args) => removeConfirmation(...args)
);
