import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Wrapper as SelectWrapper } from './Select.pc';

export type SelectOption = {
  label: string;
  value: string;
};
export type GroupedOption = {
  label: string;
  options: SelectOption[];
};

type MenuPlacement = 'top' | 'bottom';

type KeyValueOptions = {
  [identifier: string]: string;
};

export const selectOptionsFromMap = (
  options: KeyValueOptions
): SelectOption[] =>
  Object.entries(options).map(([value, label]) => ({
    label,
    value,
  }));

export const setToSelectOptions = (values: string[]): SelectOption[] =>
  values.map((value) => ({
    label: value,
    value,
  }));

// Use CreatableSelect instead of Select if isCreatable prop is true
// this will allow creation of new options
const StyledSelect = (props: any) => {
  const DynamicSelect: React.ElementType = props.isCreatable
    ? CreatableSelect
    : Select;

  return (
    <SelectWrapper testId={props['data-testid']}>
      <DynamicSelect classNamePrefix="select" {...props} />
    </SelectWrapper>
  );
};

const getSelectedValuesFromSelectOption = (options: SelectOption[]) => {
  return options ? options.map((option) => option.value) : [];
};

// Get multiselect string values, useful when uploading form that has select with isMulti
export const getMultiSelectValues = (data: any, toValues: string[]) => {
  const formattedValues = toValues
    .map((key) => ({
      [key]: getSelectedValuesFromSelectOption(data[key]),
    }))
    .reduce((acc, current) => ({ ...acc, ...current }), {});
  return {
    ...data,
    ...formattedValues,
  };
};

// Get entire select option object given available options and a value
export const getOptionForValue = (options: SelectOption[], value: string) =>
  options.find((option) => option.value === value);

// Get label for value, this is mostly a hack, my suggestion is for us to stop wrapping constants inside setToSelect and
// do that instead on the fly inside this Select component, that will free the constant options to be able to be accessed directly – Julio
export const getLabelForSelectValue = (
  options: SelectOption[],
  value: string
) => options.find((option) => option.value === value)?.label || '';

const getDefaultValueForSelect = (
  options: SelectOption[] | null | undefined,
  {
    value,
    selectOneMessage,
    isCreatable,
  }: {
    value?: SelectOption[] | SelectOption | string;
    selectOneMessage?: string;
    isCreatable?: boolean;
  }
) => {
  if (Array.isArray(value) && typeof value !== 'string') {
    return value;
  }

  if (isCreatable) {
    return undefined;
  }

  return (
    options?.find(({ value: optionValue }) => optionValue === value) ||
    (selectOneMessage && {
      value: '',
      label: selectOneMessage,
    })
  );
};

const getDefaultLabel = ({ isCreatable }: { isCreatable?: boolean }) => {
  if (isCreatable) {
    return 'Select or add one';
  }

  // Undefined is useful to the inner component because we need an empty placeholder for the moving label
  return undefined;
};

export type SelectInputProps = {
  'aria-label'?: string;
  'data-testid'?: string;
  component?: any;
  ref?: any;
  menuPlacement?: MenuPlacement;
  disabled?: boolean;
  loading?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
  isMultiClearable?: boolean;
  placeholder?: string;
  value?: SelectOption | SelectOption[] | string;
  defaultValue?: string;
  inputId?: string;
  options?: SelectOption[] | null;
  autoFocus?: boolean;
  secondary?: boolean;
  negative?: boolean;
  onValueChange?: (
    value: SelectOption | SelectOption[] | string | null
  ) => void;
  formatCreateLabel?: (value: string) => string;
  noOptionsMessage?: () => string;
  onBlur?: () => void;
};

export const SelectInput = ({
  value,
  options,
  'aria-label': ariaLabel,
  'data-testid': testId,
  disabled,
  loading,
  isClearable,
  isCreatable,
  isMulti,
  isMultiClearable,
  inputId,
  autoFocus,
  secondary,
  negative,
  formatCreateLabel,
  menuPlacement,
  noOptionsMessage = () => 'No options',
  onValueChange,
  ref,
  placeholder,
  onBlur,
}: SelectInputProps) => {
  const selectOneMessage = getDefaultLabel({ isCreatable });

  // Helpers for grouped options
  const flattenGroupedOptions = (array: any[]) =>
    array.reduce((acc, current) => [...acc, ...(current.options || [])], []);

  const isGroupedOptions = (array: any[] | null | undefined) =>
    Array.isArray(array) && array.length > 0 && !!array[0].options;

  // Map formik "initialValue" to styled select "defaultValue" + "value"
  // actual value is called "internalValue" here to avoid shadowing "value"
  const defaultValueOrEmpty = getDefaultValueForSelect(options, {
    value,
    selectOneMessage,
    isCreatable,
  });

  let internalValue: any;

  if (isMulti && Array.isArray(value)) {
    internalValue = options?.filter((option) =>
      value?.some((v) => v.value === option.value)
    );
  } else if (value) {
    if (options && isGroupedOptions(options)) {
      internalValue = flattenGroupedOptions(options).find(
        ({ value: optionValue }: { value: SelectOption }) =>
          optionValue === value
      );
    } else {
      internalValue = options?.find(
        ({ value: optionValue }) => optionValue === value
      );
    }
  } else {
    internalValue = value;
  }

  return (
    <StyledSelect
      ref={ref}
      aria-label={ariaLabel}
      data-testid={testId}
      options={options}
      menuPlacement={menuPlacement}
      autoFocus={autoFocus}
      placeholder={placeholder}
      defaultValue={defaultValueOrEmpty}
      value={internalValue}
      onChange={(change: string | SelectOption | SelectOption[]) => {
        // onChange may or may not exist, don't trigger react-select onChange if it doesn't
        if (!onValueChange) return;

        if (change) {
          // when option is creatable (i.e. not an actual option change will be string)
          if (typeof change === 'string' || isMulti) {
            onValueChange(change);
          } else {
            // else it is an actual SelectOption, we need it's value to continue
            onValueChange((change as SelectOption).value);
          }
        } else {
          // when react-select is clearable (prop isClearable) change will be null
          onValueChange(isMultiClearable ? [] : null);
        }
      }}
      isDisabled={disabled}
      isClearable={isClearable}
      isCreatable={isCreatable}
      isMulti={isMulti}
      isLoading={loading}
      formatCreateLabel={formatCreateLabel}
      noOptionsMessage={noOptionsMessage}
      inputId={inputId}
      secondary={secondary}
      negative={negative}
      onBlur={onBlur}
      v3
    />
  );
};
