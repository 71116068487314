import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "./utils.pc";
import _pubA97D53F3 from "./atoms.pc";
import "./Icon.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Icon = React.memo(React.forwardRef(function Icon(props, ref) {
  return React.createElement("div", {
    "className": "_f9e2b444 _fa41b678 _pub-fa41b678" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["masked"] ? " " + "_fa41b678_masked _pub-fa41b678_masked masked" : ""),
    "ref": ref,
    "style": castStyle("--src:url(" + props["src"] + "); --size:" + props["size"]),
  }, null)
}));
export { Icon };


