import { Form } from '../types';
import { useFormContext } from './useFormContext';

/**
 * Helper hook to ensure we have either a passed form or
 * we can extract a from from the context.
 */

function useEnsuredForm<TValues>(passedForm?: Form<TValues>) {
  const formContext = useFormContext(false);
  const form = passedForm || formContext;

  if (!form) {
    throw new Error(
      'You must either provide a form to `useField`, or use it inside a FormContext.Provider sub-tree'
    );
  }
  return form as Form<TValues>;
}

export { useEnsuredForm };
